<div class="payment-component payment-message-component">
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <h2 class="header">
        {{ 'tapp-order.payment.payment-message.title' | translate }}
        <span class="light">({{ 'tapp-order.payment.payment-message.optional' | translate }})</span>
      </h2>
      <div class="message-parent-box" [formGroup]="messageForm">
        <textarea
          pInputTextarea
          formControlName="message"
          [autoResize]="true"
          [rows]="1"
          [maxLength]="250"
          [(ngModel)]="message"
          (ngModelChange)="setMessage($event)"
        ></textarea>
      </div>
    </div>
  </div>
</div>
