import { Component, Input, OnInit } from '@angular/core';
import { OrderService } from '@core/services/order.service';
import { PlaceViewModel } from '@core/models/tapp-order/view-model/place/place.view.model';

@Component({
  selector: 'app-payment-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() set place(place: PlaceViewModel) {
    this._place = place;

    this.placeString = `${place.city}, ${place.street} ${place.buildingNo}`;
    place.localNo ? (this.placeString += `/${place.localNo}`) : null;
  }

  private _place: PlaceViewModel;
  public placeString: string;
  public displayDialog: boolean = false;

  constructor(public orderService: OrderService) {}

  ngOnInit(): void {}

  get place(): PlaceViewModel {
    return this._place;
  }

  public changeLocal() {
    this.displayDialog = true;
  }

  public closeDialog() {
    this.displayDialog = false;
  }
}
