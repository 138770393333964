<div class="content">
  <div class="content__left-side">
    <img *ngIf="image" class="image" [class.image--shifted-left]="isImageShiftedLeft" src="{{ image }}" />
  </div>
  <div class="content__right-side">
    <div class="title-wrapper">
      <h2 class="title">
        {{ title }}
      </h2>
      <div class="attributes" *ngIf="attributes && attributes.length > 0">
        <ng-container *ngFor="let attribute of attributes">
          <div
            class="title-attribute-icon"
            [pTooltip]="attribute.name"
            tooltipPosition="top"
            tooltipStyleClass="small-tooltip"
            *ngIf="attribute.icon?.url || attribute.iconName"
          >
            <img class="icon" *ngIf="attribute.icon?.url" src="{{ attribute.icon?.url }}" alt="" />
            <img
              class="icon"
              *ngIf="!attribute.icon?.url && attribute.iconName"
              src="/assets/icons/attr-icon-{{ attribute.iconName }}.svg"
              alt=""
            /> </div
        ></ng-container>
      </div>
    </div>
    <span class="description description--{{ descriptionType }}">{{ description }}</span>
    <footer class="footer">
      <span class="price">
        <ng-container *ngIf="price && priceVisible">
          <span *ngIf="priceTextFromVisible" class="price__text">{{ 'price-from' | translate }}</span>
          <span class="price__value">{{ price | currency: 'PLN':'zł':'1.2-2':'pl-PL' }}</span>
        </ng-container>
      </span>
      <button
        *ngIf="buttonVisible"
        pButton
        pRipple
        type="button"
        class="button button--{{ buttonType }} btn-primary"
        icon="ic ic-plus"
        iconPos="right"
        [label]="
          buttonLabel && buttonLabel.length > 0 ? buttonLabel : ('tapp-order.product-item-component.add' | translate)
        "
        (click)="onButtonClick.emit()"
      ></button>
    </footer>
  </div>
</div>
