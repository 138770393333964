<div class="company-tab-container">
  <div class="company-tab-title">
    <span>{{
      company ? company.companyName : ('tapp-order.pages.logged-in-user.components.company-tab.new-company' | translate)
    }}</span>
  </div>
  <div class="company-tab-options">
    <span>
      <div class="d-flex align-items-center flex-row options">
        <span *ngIf="company" (click)="confirmDeleteCompany()" ngDefaultControl class="ui-clickable delete">{{
          'tapp-order.pages.logged-in-user.components.company-tab.delete' | translate
        }}</span>
        <span *ngIf="company" (click)="displayInvoiceForm = true" ngDefaultControl class="ui-clickable change">{{
          'tapp-order.pages.logged-in-user.components.company-tab.change' | translate
        }}</span>
      </div>
    </span>

    <app-input-switch
      (click)="!company ? openNewCompanyModal() : null"
      [(checked)]="isActive"
      [disabled]="company && isActive"
      switchStyle="secondary"
      (checkedChange)="onDefaultCompanyChangeEvent()"
    ></app-input-switch>
  </div>
</div>
<p-dialog [(visible)]="displayInvoiceForm" [draggable]="false" [modal]="true" [showHeader]="false" [resizable]="false">
  <app-invoice-form
    (closeDialogEmmiter)="displayInvoiceForm = false"
    (invoiceFormEmitter)="companyFormEventHandler($event)"
    [userForm]="true"
    [companyForm]="company ? company : null"
  ></app-invoice-form>
</p-dialog>
