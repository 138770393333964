<ng-container>
  <app-mobile-navbar
    class="discount-mobile-navbar"
    [customBackAction]="true"
    (onBackEvent)="closeDialog()"
  ></app-mobile-navbar>

  <div class="discount-product-modify-container">
    <div class="product-modify-container">
      <div
        class="d-flex product-modify-container-inner-wrapper flex-column w-100 align-items-center"
        *ngIf="productService.product"
      >
        <div class="top-title">
          {{ 'tapp-order.pages.discount.common.discount' | translate | uppercase }}
        </div>

        <div class="button-back-wrapper">
          <div (click)="closeDialog()" class="button-back ui-clickable">
            <i class="ic ic-back mr-3"></i>
            <span>{{ 'tapp-order.pages.discount.common.back' | translate }}</span>
          </div>
        </div>

        <img
          class="product-image"
          *ngIf="productService.product.image?.url"
          [src]="productService.product?.image?.url"
          [alt]="productService.product?.name"
        />

        <div class="product-modify-wrapper">
          <div class="product-modify-content">
            <img
              class="product-image-mobile"
              *ngIf="productService.product.image?.url"
              [src]="productService.product?.image?.url"
              [alt]="productService.product?.name"
            />

            <div class="product-modify-content-name">
              <span>{{ productService.product?.name }}</span>
            </div>

            <div class="product-modify-content-price" *ngIf="!productService.isPizza">
              <span>{{ productService.product?.price | currency: 'PLN':'zł':'1.2-2':'pl-PL' }}</span>
            </div>

            <div class="product-modify-content-description" *ngIf="!productService.isPizza">
              <span>{{ productService.product?.description }}</span>
            </div>

            <div
              class="product-modify-content-pizza-sizes"
              *ngIf="
                productService.isPizza &&
                (!place.autohidePizzaOptions || (conditionSizeIds ? pizzaSizes : productService.pizzaSizes).length > 1)
              "
            >
              <ng-container *ngFor="let pizzaSize of conditionSizeIds ? pizzaSizes : productService.pizzaSizes">
                <app-product-size-button
                  (click)="productService.onPizzaSizeChange(pizzaSize)"
                  [pizzaPrice]="productService.getPizzaSizePrice(pizzaSize)"
                  [pizzaType]="pizzaSize"
                  [showPrice]="false"
                  [selected]="pizzaSize.publicId === productService.selectedPizzaSize.publicId"
                ></app-product-size-button>
              </ng-container>
            </div>

            <div
              class="product-modify-content-pizza-dough"
              *ngIf="
                productService.isPizza &&
                productService.selectedPizzaSize &&
                productService.pizzaDoughList &&
                productService.pizzaDoughList.length > 0 &&
                (!place.autohidePizzaOptions || productService.pizzaDoughList.length > 1)
              "
            >
              <div class="pizza-dough-label">
                <span>
                  {{ 'tapp-order.pages.product.common.select-dough' | translate }}
                </span>
              </div>
              <mat-radio-group [(ngModel)]="productService.selectedPizzaDough" class="radio-group">
                <ng-container *ngFor="let pizzaDough of productService.pizzaDoughList; last as isLast">
                  <div class="pizza-dough-button-container">
                    <div class="d-flex w-100 justify-content-between align-items-center">
                      <mat-radio-button [value]="pizzaDough" class="example-radio-button">
                        {{ pizzaDough.name }}
                      </mat-radio-button>
                      <span class="pizza-dough-additional-price">{{
                        pizzaDough.price > 0 ? '+' + (pizzaDough.price | currency: 'PLN':'zł':'1.2-2':'pl-PL') : null
                      }}</span>
                    </div>
                  </div>
                  <ng-container *ngIf="!isLast">
                    <mat-divider></mat-divider>
                  </ng-container>
                </ng-container>
              </mat-radio-group>
            </div>

            <div *ngIf="productService.isPizza" class="product-modify-content-ingredients">
              <div class="title d-flex">
                <span class="w-50">
                  {{ 'tapp-order.pages.product.common.ingredients' | translate }}
                </span>
                <span (click)="openDialog()" class="w-50 ingredient-edit ui-clickable">
                  {{ 'tapp-order.pages.product.common.change-ingredients' | translate }}
                </span>
              </div>
              <div class="list">
                <span style="word-break: break-all">
                  {{ productService.pizzaIngredientsString | removeDots }}
                </span>
              </div>
            </div>

            <mat-divider
              *ngIf="productService.modifiers.length > 0"
              class="mb-4"
              [class]="!productService.isPizza ? 'mt-4' : ''"
            ></mat-divider>

            <div class="product-modify-content-modifiers">
              <app-product-modifiers
                #modifiersComponent
                [modifierGroups]="productService.modifiers"
                [isToggleMode]="true"
              >
              </app-product-modifiers>
            </div>
          </div>

          <div class="product-modify-content-save">
            <div (click)="onSave()" class="button ui-clickable">
              <span>
                {{ 'tapp-order.pages.product.common.select' | translate }}
              </span>
            </div>
            <div pRipple class="btn-cancel ui-clickable" (click)="closeDialog()">
              {{ 'tapp-order.pages.discount.discount-configurator.cancel' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
