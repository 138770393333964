import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkingHoursApiModel } from '@core/models/tapp-order/api-model/working-hours/working-hours.api.model';
import { HolidayViewModel } from '@core/models/tapp-order/view-model/holiday/holiday.view.model';
import { PlaceViewModel } from '@core/models/tapp-order/view-model/place/place.view.model';
import { ProductViewModel } from '@core/models/tapp-order/view-model/product/product.view.model';
import { ButtonBackService } from '@core/services/button-back.service';
import { OrderService } from '@core/services/order.service';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { MenuTypeEnum } from '@shared/enum/menu-type.enum';
import { ParamKeys } from '@shared/enum/param-kyes.enum';
import { SidebarComponent } from '@ui/sidebar/sidebar.component';
import * as _ from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoadingService } from 'src/app/shared/loading/loading.service';
import { BasketService } from '../../../services/basket.service';
import { PlaceService } from '../../../services/place.service';
import { ThemeService } from '../../../services/theme.service';
import { BottomNotificationComponent } from '../../components/bottom-notification/bottom-notification.component';
import { BaseDataProvider } from '../../data-providers/base.data-provider';
import { MenuDataProvider } from '../../data-providers/menu.data-provider';
import { CateringAggregationArticleViewModel } from '../../models/catering-aggregation.view.model';
import { MenuViewModel } from '../../models/tapp-order/view-model/menu/menu.view.model';
import { GroupViewModel } from '@core/models/tapp-order/view-model/menu/group.view.model';
import { AttributeViewModel } from '@core/models/tapp-order/view-model/product/attribute.view.model';
import { AllergenViewModel } from '@core/models/tapp-order/view-model/product/allergen.view.model';
import { NutritionValueViewModel } from '@core/models/tapp-order/view-model/product/nutrition-value.view.model';
import { NutritionViewModel } from '@core/models/tapp-order/view-model/product/nutrition.view.model';
import { MatDialog } from '@angular/material/dialog';
import { FiltersDialogComponent } from '@core/pages/products/component/filters-dialog/filters-dialog.component';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-products-page',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductsPageComponent implements OnInit, AfterViewChecked, OnDestroy {
  private isOrderPage: boolean = false;

  private destroyed$ = new Subject<void>();

  private filtersDialogSubscription: Subscription;

  @ViewChild(SidebarComponent) public sidebarComponent;

  modalRef?: BsModalRef;

  hide = false;

  orderItems: CateringAggregationArticleViewModel[] = [];

  totalValue = 0;
  totalItems = 0;

  public env = environment;

  public menu: MenuViewModel = null;
  public filteredMenu: MenuViewModel = null;
  public searchSuggestions: string[] = [];
  public bottomButtonCenterText: string = '';
  public bottomButtonRightText: string = '';
  public hasMenu: boolean = null;
  public placeIsPreviewOnly: boolean;
  public placeString: string;
  public nextWorkingHours: WorkingHoursApiModel = null;
  public displayDialog: boolean = false;
  public productsCrossSell: ProductViewModel[] = [];
  public displayCrossSellDialog: boolean = false;

  public isOpen: boolean = true;

  public isLoaded: boolean = false;
  public place: PlaceViewModel;

  public qrMenuMode: boolean = false;

  public attributes: AttributeViewModel[] = [];
  public allergens: AllergenViewModel[] = [];
  public nutritions: NutritionViewModel[] = [];
  public searchQuery: string = null;

  private _iconPrimaryColor = '#2c60a2';

  constructor(
    private modalService: BsModalService,
    private loadingService: LoadingService,
    private router: Router,
    private route: ActivatedRoute,
    private menuDataProvider: MenuDataProvider,
    private baseDataProvider: BaseDataProvider,
    private translateService: TranslateService,
    private placeService: PlaceService,
    private themeService: ThemeService,
    private buttonBackService: ButtonBackService,
    private changeDetector: ChangeDetectorRef,
    public bottomNotificationComponent: BottomNotificationComponent,
    public basketService: BasketService,
    public orderService: OrderService,
    private filtersDialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.themeService.desktopHeaderAdditionalClass = 'sidebar-margin-1';

    setTimeout(() => {
      let bodyStyle = window.getComputedStyle(document.body);
      this._iconPrimaryColor = bodyStyle.getPropertyValue('--primary');
    });

    this.themeService.mobileNavbarShowBackButton = false;
    this.buttonBackService.redirectUrl = undefined;

    this.route.data.pipe(takeUntil(this.destroyed$)).subscribe((data) => {
      if (data?.qrmenu === true) {
        this.qrMenuMode = true;
      } else {
        this.qrMenuMode = false;
      }

      sessionStorage.setItem('qrMenuMode', this.qrMenuMode.toString());

      this.setLocalPublicId();
    });
  }

  private init(placeId: string): void {
    this.loadingService.showLoader();

    this.placeService
      .useAsObservable(placeId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (place: PlaceViewModel) => {
          this.place = place;

          this.baseDataProvider
            .getHolidaysList()
            .pipe(takeUntil(this.destroyed$))
            .subscribe(
              (holidays: HolidayViewModel[]) => {
                this.placeString = `${place.city}, ${place.street} ${place.buildingNo}`;
                place.localNo ? (this.placeString += `/${place.localNo}`) : null;

                this.isOpen = this.orderService.isPlaceOpen(place, holidays, this.qrMenuMode);
                this.nextWorkingHours = this.orderService.getNextWorkingHoursData(place, holidays, this.qrMenuMode);
                this.managePreviewOnly();

                if (place.menus.length > 0 && place.getMenuByType(this.orderService.getMenuType())) {
                  const menuId = place.getMenuByType(
                    this.qrMenuMode ? MenuTypeEnum.ONSITE : this.orderService.getMenuType(),
                  ).publicId;
                  this.hasMenu = true;
                  this.placeIsPreviewOnly = place.isPreviewOnly;
                  this.managePreviewOnly();
                  this.menuDataProvider
                    .getMenu(menuId)
                    .pipe(takeUntil(this.destroyed$))
                    .subscribe((menu) => {
                      this.menu = _.cloneDeep(menu);
                      this.filteredMenu = _.cloneDeep(menu);
                      this.prepareFilters(menu);

                      this.isLoaded = true;

                      if (this.menu) {
                        this.getProductsCrossSell(this.menu.publicId);
                      }
                    });
                } else {
                  this.hasMenu = false;
                }

                this.loadingService.hideLoader();
              },
              () => {
                this.loadingService.hideLoader();
              },
            );
        },
        error: () => {
          this.router.navigate(['/']);
        },
      });
  }

  private getProductsCrossSell(menuPublicId: string) {
    this.baseDataProvider
      .getProductsCrossSell(menuPublicId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((products) => {
        this.productsCrossSell = products;
      });
  }

  public showCrossSellProducts(isOrderPage: boolean = false) {
    this.isOrderPage = isOrderPage;
    this.displayCrossSellDialog = true;
  }

  public closeCrossSellProducts() {
    this.isOrderPage ? this.router.navigate(['/order']) : this.sidebarComponent.createOrder();
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy() {
    this.themeService.desktopHeaderAdditionalClass = '';
    this.destroyed$.next();
    this.destroyed$.complete();
    this.filtersDialogSubscription?.unsubscribe();
  }

  public handleAddProductToBasket(product: ProductViewModel) {
    if (product.hasModifiers) {
      this.redirectToNewProduct(product);
    } else {
      this.addProductToBasket(product);
    }
  }

  public managePreviewOnly() {
    if (!this.isOpen) {
      this.placeIsPreviewOnly = true;
    }
  }

  public changeLocal() {
    this.displayDialog = true;
  }

  public closeDialog() {
    this.displayDialog = false;
  }

  private setLocalPublicId() {
    this.route.params.pipe(takeUntil(this.destroyed$)).subscribe((params) => {
      const placeId = params[ParamKeys.PlaceId];

      if (sessionStorage.getItem('placeId') && placeId != sessionStorage.getItem('placeId')) {
        this.basketService.removeBasket();

        this.orderService.removeDeliveryZoneId();
        this.orderService.removeDeliveryAddressCity();
        this.orderService.removeDeliveryAddressZipCode();
        this.orderService.removeDeliveryAddressStreet();
        this.orderService.removeDeliveryAddressBuildingNo();
        this.orderService.removeDeliveryAddressLocalNo();
      }

      this.init(placeId);
    });
  }

  onSearch(event: { query: string }) {
    if (event.query.length > 0) {
      this.searchQuery = event.query;
    } else {
      this.searchQuery = null;
    }

    this.filterProducts();
  }

  private searchInString(query, string): boolean {
    return string.trim().toLowerCase().includes(query.trim().toLowerCase());
  }

  onSearchClear() {
    this.searchQuery = null;
    this.filterProducts();
  }

  public generateButtonText(): string[] {
    this.bottomButtonCenterText =
      this.translateService.instant('tapp-order.order-button-text') +
      ' ' +
      this.basketService.basketItems.length +
      ' ' +
      this.translateService.instant('tapp-order.order-button-text-items');
    this.bottomButtonRightText = this.basketService.calculatePrice().toFixed(2);
    this.bottomButtonCenterText = this.translateService.instant('tapp-order.order-button-constant-text');
    return [this.bottomButtonCenterText, this.bottomButtonRightText];
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      class: 'full-screen-modal',
    });
  }

  openArticleCardModal(template: TemplateRef<any>) {
    if (!this.placeIsPreviewOnly) {
      this.modalRef = this.modalService.show(template, {
        class: 'article-card-modal',
      });
    }
  }

  public navigateToPayment() {
    if (this.productsCrossSell.length > 0) {
      this.showCrossSellProducts(true);
    } else {
      this.router.navigate(['/order']);
    }
  }

  hideModal() {
    this.modalRef.hide();
  }

  private redirectToNewProduct(product: ProductViewModel) {
    const placeId = this.route.snapshot.params[ParamKeys.PlaceId];

    if (!placeId) {
      console.error('#NSDFOJ82FHJHD: placeId is not defined');
      this.router.navigate(['/']);
      return;
    }

    this.router.navigate([placeId, 'products', product.publicId]);
  }

  private addProductToBasket(product: ProductViewModel) {
    product.CurrentQuantity = 1;
    this.basketService.add(product, []);

    this.bottomNotificationComponent.show(
      this.translateService.instant('tapp-order.article-card-success-notification-text-constant'),
      1500,
    );
  }

  private prepareFilters(menu: MenuViewModel) {
    this.attributes = [];
    this.allergens = [];
    this.nutritions = [];

    let nutritionValues: NutritionValueViewModel[] = [];

    menu.groups.forEach((group) => {
      group.products.forEach((product) => {
        this.attributes = [...this.attributes, ...product.attributes];
        this.allergens = [...this.allergens, ...product.allergens];
        nutritionValues = [...nutritionValues, ...product.nutritionValues];
      });

      group.subgroups.forEach((subgroup) => {
        subgroup.products.forEach((product) => {
          this.attributes = [...this.attributes, ...product.attributes];
          this.allergens = [...this.allergens, ...product.allergens];
          nutritionValues = [...nutritionValues, ...product.nutritionValues];
        });
      });
    });

    const nutritionMap = new Map<string, NutritionViewModel>();
    nutritionValues.forEach(({ nutrition, value }) => {
      const key = nutrition.publicId;
      const numValue = parseFloat(value);

      if (nutritionMap.has(key)) {
        const existing = nutritionMap.get(key)!;
        existing.minValue = existing.minValue === null ? numValue : Math.min(existing.minValue, numValue);
        existing.maxValue = existing.maxValue === null ? numValue : Math.max(existing.maxValue, numValue);
        existing.valuesRange = [existing.minValue, existing.maxValue];
      } else {
        nutrition.minValue = numValue;
        nutrition.maxValue = numValue;
        nutrition.valuesRange = [numValue, numValue];
        nutritionMap.set(key, nutrition);
      }
    });

    this.attributes = this.attributes
      .filter((obj1, i, arr) => arr.findIndex((obj2) => obj2.publicId === obj1.publicId) === i)
      .sort((a, b) => a.name.localeCompare(b.name));

    this.allergens = this.allergens
      .filter((obj1, i, arr) => arr.findIndex((obj2) => obj2.publicId === obj1.publicId) === i)
      .sort((a, b) => a.name.localeCompare(b.name));

    this.nutritions = [...nutritionMap.values()];
  }

  public openFiltersDialog(): void {
    this.filtersDialogSubscription?.unsubscribe();

    const config = {
      panelClass: 'filters-overlay',
      data: {
        attributes: this.attributes,
        allergens: this.allergens,
        nutritions: this.nutritions,
      },
    };

    const filtersDialogRef = this.filtersDialog.open(FiltersDialogComponent, config);

    this.filtersDialogSubscription = filtersDialogRef.afterClosed().subscribe({
      next: (filtersData) => {
        if (filtersData) {
          if (filtersData.allergens) {
            this.allergens = filtersData.allergens;
          }

          if (filtersData.attributes) {
            this.attributes = filtersData.attributes;
          }

          if (filtersData.nutritions) {
            this.nutritions = filtersData.nutritions;
          }

          this.filterProducts();
        }
      },
    });
  }

  private filterProducts() {
    this.filteredMenu.groups = _.cloneDeep(this.menu).groups.map((group: GroupViewModel) => {
      group.products = group.products.filter((product) => this.checkProductFilters(product));

      group.subgroups = group.subgroups.map((subgroup) => {
        subgroup.products = subgroup.products.filter((product) => this.checkProductFilters(product));
        return subgroup;
      });

      return group;
    });
  }

  private checkProductFilters(product: ProductViewModel): boolean {
    let searchQueryResult = true;
    if (this.searchQuery) {
      searchQueryResult = this.searchInString(this.searchQuery, product.name);
    }

    let attributesResult = true;
    let attributesToFilter = this.attributes.filter((item) => item.checked).map((item) => item.publicId);
    if (attributesToFilter && attributesToFilter.length > 0) {
      attributesResult = attributesToFilter.every((id) => product.attributes.some((item) => item.publicId === id));
    }

    let allergensResult = true;
    let allergensToFilter = this.allergens.filter((item) => item.checked).map((item) => item.publicId);
    if (allergensToFilter && allergensToFilter.length > 0) {
      allergensResult = allergensToFilter.every((id) => !product.allergens.some((item) => item.publicId === id));
    }

    let nutritionsResult = true;
    let nutritionsToFilter = this.nutritions.filter(
      (item) =>
        item.valuesRange.length > 1 && (item.valuesRange[0] != item.minValue || item.valuesRange[1] != item.maxValue),
    );
    if (nutritionsToFilter && nutritionsToFilter.length > 0) {
      nutritionsResult = nutritionsToFilter.every((nutrition) =>
        product.nutritionValues.some(
          (item) =>
            item.nutrition.publicId === nutrition.publicId &&
            parseFloat(item.value) >= nutrition.valuesRange[0] &&
            parseFloat(item.value) <= nutrition.valuesRange[1],
        ),
      );
    }

    return searchQueryResult && attributesResult && allergensResult && nutritionsResult;
  }

  getGroupIcon(url: string) {
    if (url.endsWith('.svg')) {
      let filename = url.split('/').pop();
      let subdomain = sessionStorage.getItem('subdomain');
      if (subdomain) {
        return (
          environment.apiUrl +
          `/core/svg/${subdomain}/image/${filename}?fill=` +
          encodeURIComponent(this._iconPrimaryColor)
        );
      }
    }

    return url;
  }
}
