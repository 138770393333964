<div class="places-container" *ngIf="isLoaded">
  <div class="places-container__content">
    <app-button-back *ngIf="isDesktop" class="button-back"></app-button-back>
    <div #productListDesktop id="product-list-desktop" class="places-container__content__list">
      <div class="row justify-content-end">
        <div class="left-side col-lg-6 col-md-6 col-12">
          <p class="main-title">
            {{ 'tapp-order.places-component.select-local' | translate }}
          </p>
          <p class="bg-title">
            {{ 'tapp-order.places-component.bg-title' | translate }}
          </p>
          <app-place-list [cities]="cities" (cityEvent)="setCity($event)"></app-place-list>
        </div>
        <div #placesWrapper class="right-side col-lg-6 col-md-6 col-12" *ngIf="city">
          <p class="main-title">
            {{ 'tapp-order.places-component.main-title' | translate }}
          </p>
          <p class="subtitle">{{ city }}</p>
          <app-place [placesAndHolidays]="[places, holidays]" [deliveryZoneList]="deliveryZoneList"></app-place>
        </div>
      </div>
    </div>
  </div>
</div>
