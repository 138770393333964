import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CanComponentDeactivate } from '@core/interfaces/interfaces';
import { ProductViewModel } from '@core/models/tapp-order/view-model/product/product.view.model';
import { TranslateService } from '@ngx-translate/core';
import { LoadingStatus } from '@shared/loading/model/loading-status.enum';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { Dialog } from 'primeng/dialog';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Steps } from 'primeng/steps';
import { BehaviorSubject } from 'rxjs';
import { DiscountConfiguratorComponent } from '../discount-configurator/discount-configurator.component';
import { DiscountDataProvider } from '../../data-provider/discount.data-provider';
import { ConditionTypeEnum } from '../../enum/condition/condition-type.enum';
import { DiscountViewModel } from '../../model/discount.view.model';
import { MenuTypeEnum } from '@shared/enum/menu-type.enum';
import { OrderService } from '@core/services/order.service';
import { DeviceDetectorService } from '../../../../../services/device-detector.service';

class Step {
  index: number;
  products: ProductViewModel[];
  value: number;
}

interface IIngredientState {
  index: number;
  eventType: 'mouseenter' | 'mouseleave';
  event: MouseEvent;
}

@Component({
  selector: 'app-discount-dialog-configurator',
  templateUrl: './discount-dialog-configurator.component.html',
  styleUrls: ['./discount-dialog-configurator.component.scss'],
})
export class DiscountDialogConfiguratorComponent implements OnInit, CanComponentDeactivate {
  public display: boolean = false;
  public discount: DiscountViewModel;
  public isActive: boolean = true;
  public stepperItems: MenuItem[] = [];
  public selectedProduct: ProductViewModel | null = null;
  public selectedModifiers: any[] = [];
  public removedModifiers: any[] = [];
  public loadingStatus: LoadingStatus = LoadingStatus.pending;
  public dialogPosition: 'center' | 'bottom' = 'center';
  public dialogTransitionOptions: '150ms' | '300ms' = '150ms';
  public isMobile: boolean = false;

  private steps: Step[] = [];
  private stepperActiveIndex: number = 0;

  public hintVisible: boolean = false;
  private hintVisibleTimeout?: ReturnType<typeof setTimeout>;

  private currentIngredientState: IIngredientState = {
    index: 0,
    eventType: 'mouseleave',
    event: new MouseEvent('movuseenter'),
  };

  private ingredients$: BehaviorSubject<IIngredientState> = new BehaviorSubject(this.currentIngredientState);

  private dialogIngredientsStateTimeout?: ReturnType<typeof setTimeout>;

  private listenedElements = new Set<any>();

  @ViewChild('appDiscountConfigurator')
  public readonly appDiscountConfigurator?: DiscountConfiguratorComponent;

  @ViewChild('overplayPanel')
  public readonly overplayPanel?: OverlayPanel;

  @ViewChild('pDialog')
  public readonly pDialog?: Dialog;

  @ViewChild('stepperRef')
  public readonly stepperRef?: Steps;

  constructor(
    private el: ElementRef,
    private router: Router,
    private renderer: Renderer2,
    private translateService: TranslateService,
    private confirmationService: ConfirmationService,
    private discountDataProvider: DiscountDataProvider,
    public orderService: OrderService,
    public deviceService: DeviceDetectorService,
  ) {
    this.deviceService.isMobile.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });

    this.ingredients$.subscribe({
      next: (value) => {
        clearTimeout(this.dialogIngredientsStateTimeout);

        this.currentIngredientState = value;

        if (value.eventType === 'mouseenter') {
          const tryShowInNextTick = () => {
            this.dialogIngredientsStateTimeout = setTimeout(() => {
              if (this.currentIngredientState.eventType === 'mouseenter') {
                if (!this.overplayPanel?.render) {
                  this.mouseEnter(this.currentIngredientState.event);
                } else {
                  tryShowInNextTick();
                }
              }
            });
          };

          const selectedProduct = this.appDiscountConfigurator?.selectedProducts[this.currentIngredientState.index];

          if (selectedProduct) {
            this.selectedProduct = selectedProduct;
            this.selectedModifiers = this.getSelectedModifiers(selectedProduct);
            this.removedModifiers = this.getRemovedModifiers(selectedProduct);
            tryShowInNextTick();
          } else {
            this.selectedProduct = null;
          }
        }

        if (value.eventType === 'mouseleave') {
          if (this.overplayPanel?.render) {
            this.mouseLeave(value.event);
          }
        }
      },
    });
  }

  ngOnInit(): void {}

  ngAfterViewInit() {}

  canDeactivate(): true | Promise<boolean> {
    if (this.display) {
      return this.confirmLeavePage();
    } else {
      return true;
    }
  }

  public handleHintShow(): void {
    clearInterval(this.hintVisibleTimeout);

    this.hintVisible = true;
  }

  public handleHintHide(): void {
    clearInterval(this.hintVisibleTimeout);

    this.hintVisibleTimeout = setTimeout(() => {
      this.hintVisible = false;
    }, 1500);
  }

  public openDialog(discount: DiscountViewModel): void {
    this.discount = discount;
    this.stepperActiveIndex = 0;
    this.stepperItems = [];
    const orderType =
      sessionStorage.getItem('qrMenuMode') == 'true' ? MenuTypeEnum.ONSITE : this.orderService.getMenuType();

    this.checkConditions();
    this.discountDataProvider.findById(this.discount.id.toString(), orderType).subscribe(
      (result) => {
        this.discount = result;
        this.prepareConditions();
        this.stepperActiveIndex = 0;
        this.prepareMenuItems();
        this.addSummaryStep();

        const trySetHanldersInNextTick = () => {
          setTimeout(() => {
            const stepItems = Array.from(this.el.nativeElement.querySelectorAll('.p-steps-item'));

            if (stepItems) {
              stepItems
                .filter((item, index) => !this.listenedElements.has(item))
                .filter((item, index) => {
                  const selectedProduct = this.appDiscountConfigurator?.selectedProducts[index];
                  return selectedProduct ? true : false;
                })
                .forEach((item, index) => {
                  this.renderer.removeClass(item, 'p-disabled');
                  this.renderer.setStyle(item, 'cursor', 'help');
                  this.renderer.listen(item, 'mouseenter', (event) => {
                    this.ingredients$.next({
                      index: index,
                      eventType: 'mouseenter',
                      event: event,
                    });
                  });
                  this.renderer.listen(item, 'mouseleave', (event) => {
                    this.ingredients$.next({
                      index: index,
                      eventType: 'mouseleave',
                      event: event,
                    });
                  });

                  this.listenedElements.add(item);
                });

              this.loadingStatus = LoadingStatus.success;
            } else {
              trySetHanldersInNextTick();
            }
          }, 100);
        };
        trySetHanldersInNextTick();
      },
      (error) => {
        this.loadingStatus = LoadingStatus.failed;
      },
    );

    this.display = true;
  }

  public handleNextStep(): void {
    const trySetHanldersInNextTick = () => {
      setTimeout(() => {
        this.listenedElements.clear();

        const stepItems = Array.from(this.el.nativeElement.querySelectorAll('.p-steps-item'));

        if (stepItems) {
          stepItems
            .filter((item) => !this.listenedElements.has(item))
            .filter((item, index) => {
              const selectedProduct = this.appDiscountConfigurator?.selectedProducts[index];

              return selectedProduct ? true : false;
            })
            .forEach((item, index) => {
              this.renderer.removeClass(item, 'p-disabled');
              this.renderer.setStyle(item, 'cursor', 'help');
              this.renderer.listen(item, 'mouseenter', (event) => {
                this.ingredients$.next({
                  index: index,
                  eventType: 'mouseenter',
                  event: event,
                });
              });
              this.renderer.listen(item, 'mouseleave', (event) => {
                this.ingredients$.next({
                  index: index,
                  eventType: 'mouseleave',
                  event: event,
                });
              });

              this.listenedElements.add(item);
            });
        } else {
          trySetHanldersInNextTick();
        }
      });
    };

    trySetHanldersInNextTick();
  }

  public handleAddToBasket(): void {
    this.closeDialog();

    if (sessionStorage.getItem('placeId')) {
      this.router.navigate([sessionStorage.getItem('placeId')]);
    } else {
      this.router.navigate(['/']);
    }
  }

  public tryToCloseDialog() {
    this.confirmationService.confirm({
      header: this.translateService.instant('app.components.confirm-dialog-live-order.leave-order.header'),
      message: this.translateService.instant('app.components.confirm-dialog-live-order.leave-order.message'),
      acceptLabel: this.translateService.instant('app.components.confirm-dialog-live-order.leave-order.accept-label'),
      rejectLabel: this.translateService.instant('app.components.confirm-dialog-live-order.leave-order.reject-label'),
      accept: () => {
        this.closeDialog();
      },
      reject: () => {},
      key: 'confirm-dialog-live-order',
    });
  }

  public mouseEnter(event: MouseEvent): void {
    this.pDialog.style = { filter: 'brightness(85%)' };
    this.overplayPanel?.show(event);
    this.hintHideImmediate();
  }

  public mouseLeave(event: MouseEvent): void {
    this.pDialog.style = { filter: 'brightness(100%)' };
    this.overplayPanel?.hide();
  }

  public getPizzaSize(item: any): string {
    let toReturn = null;
    item.SelectedModifiers.forEach((selectedModifier) => {
      if (selectedModifier.modifierType === 'PIZZA_SIZE') {
        toReturn = selectedModifier.name;
      }
    });
    return toReturn;
  }

  public getPizzaDough(item: any): string {
    let toReturn = null;
    item.SelectedModifiers.forEach((selectedModifier) => {
      if (selectedModifier.modifierType === 'PIZZA_DOUGH') {
        toReturn = selectedModifier.name;
      }
    });
    return toReturn;
  }

  private closeDialog(): void {
    this.display = false;
    this.listenedElements.clear();
  }

  private hintHideImmediate(): void {
    clearInterval(this.hintVisibleTimeout);

    this.hintVisible = false;
  }

  private getSelectedModifiers(item: any): any[] {
    const filteredTypesArray = ['PIZZA_DOUGH', 'PIZZA_SIZE', 'PIZZA_FREE_INGREDIENT'];
    const toReturn = item.SelectedModifiers.filter((selecteModifier) => {
      return filteredTypesArray.indexOf(selecteModifier.modifierType) === -1 ? true : false;
    });
    return toReturn;
  }

  private getRemovedModifiers(item: any): any[] {
    return item.unselectedModifiers;
  }

  private checkConditions() {
    let daysInWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

    var today = new Date();
    let currentDayNumber = today.getDay();
    let currentDayName = daysInWeek[currentDayNumber];

    var hour = today.getHours();
    var minutes = today.getMinutes();

    this.discount.conditions.forEach((condition) => {
      if (condition.type === ConditionTypeEnum.happyHours) {
        if (condition.selectAll) {
          let hourFrom = condition.hourFrom.split(':');
          let hourTo = condition.hourTo.split(':');

          let currentTime = new Date(null, null, null, hour, minutes);
          let hourFromTime = new Date(null, null, null, Number(hourFrom[0]), Number(hourFrom[1]));
          let hourToTime = new Date(null, null, null, Number(hourTo[0]), Number(hourTo[1]));

          if (!(currentTime >= hourFromTime) || !(currentTime <= hourToTime)) {
            this.isActive = false;
            return;
          }
        }
        if (condition[currentDayName]) {
          let hoursRange = condition[currentDayName].split('-');
          let hourFrom = hoursRange[0].split(':');
          let hourTo = hoursRange[1].split(':');

          let currentTime = new Date(null, null, null, hour, minutes);
          let hourFromTime = new Date(null, null, null, hourFrom[0], hourFrom[1]);
          let hourToTime = new Date(null, null, null, hourTo[0], hourTo[1]);

          if (!(currentTime >= hourFromTime) || !(currentTime <= hourToTime)) {
            this.isActive = false;
            return;
          }
        }
      }
    });
  }

  private prepareConditions(): void {
    this.steps = [];
    let index = 0;
    this.discount.conditions.forEach((condition) => {
      switch (condition.type) {
        case ConditionTypeEnum.productGroup:
          condition.groups.forEach((group) => {
            for (let i = 0; i < group.value; i++) {
              const tempStep = new Step();
              tempStep.index = index;
              tempStep.value = 1;
              tempStep.products = [];

              group.products.forEach((product) => {
                product.originalExternalGroupIdFromDiscount = group.originalExternalGroupId;
                tempStep.products.push(product);
              });

              this.steps.push({ ...tempStep });
              index++;
            }
          });
          break;
        case ConditionTypeEnum.product:
          condition.products.forEach((product) => {
            for (let i = 0; i < product.value; i++) {
              const tempStep = new Step();
              tempStep.index = index;
              tempStep.value = 1;
              tempStep.products = [];
              tempStep.products.push(product.product);

              this.steps.push({ ...tempStep });
              index++;
            }
          });
          break;
        case ConditionTypeEnum.pizzaSize:
          break;
        case ConditionTypeEnum.pizzaDough:
          break;
        default:
          break;
      }
    });
  }

  private prepareMenuItems() {
    this.steps.forEach((step) => {
      this.stepperItems.push({
        command: () => {
          this.stepperActiveIndex = step.index;
        },
      });
    });
  }

  private addSummaryStep() {
    this.stepperItems.push({
      command: () => {
        this.stepperActiveIndex = this.stepperActiveIndex + 1;
      },
    });
  }

  private confirmLeavePage(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.confirmationService.confirm({
        header: this.translateService.instant('app.components.confirm-dialog-live-order.leave-page.header'),
        message: this.translateService.instant('app.components.confirm-dialog-live-order.leave-page.message'),
        acceptLabel: this.translateService.instant('app.components.confirm-dialog-live-order.leave-page.accept-label'),
        rejectLabel: this.translateService.instant('app.components.confirm-dialog-live-order.leave-page.reject-label'),
        accept: () => {
          this.display = false;
          resolve(true);
        },
        reject: () => {
          resolve(false);
        },
        key: 'confirm-dialog-live-order',
      });
    });
  }
}
