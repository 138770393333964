<h1 class="main-title">{{ 'tapp-order.payment.title' | translate }}</h1>

<div class="location-wrapper">
  <div
    class="col-12"
    *ngIf="orderService.tableId"
    style="
      margin-top: 1rem;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      color: var(--font-placeholder);
    "
  >
    {{ 'tapp-order.order-summary-room' | translate }}: {{ orderService.roomName }} <br />
    {{ 'tapp-order.order-summary-table' | translate }}:
    {{ orderService.tableName }}
  </div>

  <p
    *ngIf="!orderService.tableId && place && place.onlineOrderActive"
    style="
      margin-top: 1rem;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      color: var(--font-placeholder);
    "
  >
    {{ 'products.local-realizing-order-label' | translate }}:
    <span
      style="
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        color: var(--primary);
        cursor: pointer;
      "
      (click)="changeLocal()"
      >{{ placeString ? placeString : null }}</span
    >
  </p>
</div>

<app-dialog-address-finder
  [displayDialog]="displayDialog"
  [redirectToPlace]="false"
  [message1]="'tapp-order.select-adress-component.dialog.adress-change-warning-2' | translate"
  [message2]="'tapp-order.select-adress-component.dialog.adress-change-warning-3' | translate"
  (onCloseDialog)="closeDialog()"
></app-dialog-address-finder>
