<div class="h-100 pt-5 pb-5 d-flex justify-content-center align-items-center text-center">
  <div class="col">
    <div class="row pb-5">
      <div class="col">
        <div class="lds-spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="row pb-2">
          <div class="col">
            <div class="main-title">{{
              'tapp-order.pages.order.order-processing.payment-pending.pending' | translate
            }}</div>
          </div>
        </div>
        <div class="row outer-wrapper mt-3">
          <div class="col d-flex justify-content-center align-items-center">
            <div class="row info-container d-flex text-center flex-wrap align-items-center justify-content-stretch">
              <div class="box col align-self-stretch">
                <div class="title">
                  {{
                    'tapp-order.pages.order.order-processing.order-accepted.info-container.payment-timer' | translate
                  }}:
                </div>
                <div class="delivery-time">
                  {{ counter | formatTime }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3" *ngIf="showGoBackBtn">
          <div class="col d-flex justify-content-center align-items-center ui-clickable">
            <div
              (click)="goBack()"
              style="
                background: var(--primary);
                border-radius: var(--border-radius-input);
                max-width: 278px;
                padding: 7px 66px;
              "
            >
              <span
                style="
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 24px;
                  text-transform: uppercase;
                  color: var(--font-white);
                "
                >{{ 'tapp-order.pages.order.order-processing.payment-pending.go-back' | translate }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
