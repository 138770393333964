import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DiscountService } from '@core/pages/discounts/service/discount.service';
import { OrderService } from '@core/services/order.service';
import { TranslateService } from '@ngx-translate/core';
import { ModifierTypeEnum } from '@shared/enum/modifier-type.enum';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { BasketService } from '../../../services/basket.service';
import { BasketItemViewModel } from '../../models/tapp-order/view-model/basketItem/basket-item.view.model';
import { ModifierViewModel } from '../../models/tapp-order/view-model/product/modifier.view.model';
import { ProductViewModel } from '../../models/tapp-order/view-model/product/product.view.model';
import { BottomNotificationComponent } from '../bottom-notification/bottom-notification.component';
import { ProductTypeEnum } from '@shared/enum/product-type.enum';

@Component({
  selector: 'app-article-card-expandable',
  templateUrl: './article-card-expandable.component.html',
  styleUrls: ['./article-card-expandable.component.scss'],
})
export class ArticleCardExpandableComponent implements OnInit, OnDestroy {
  public isExpanded = false;
  public orderQuantity = 1;
  public accordionExpanded: signatureIndexBoolean = {};
  public selectedSingleChoiceModifiers: IDictionary = {};
  public basketItemCopy: BasketItemViewModel;
  public displayConfirmDialog: boolean = false;

  _item: ProductViewModel;
  public selectedModifiers: ProductViewModel[] = [];
  public selectedNestedModifier: ProductViewModel;
  private itemCopy: ProductViewModel;
  private selectedModifiersCopy: ProductViewModel[] = [];

  public basketItemPriceBackendCalucation: number | null = null;

  private discountSubscription$: Subscription;

  get item(): ProductViewModel {
    return this._item;
  }

  @Input() set item(value: ProductViewModel) {
    this._item = value;
  }

  @Input() renderedInOrderSummary: boolean;
  @Input() quantity: number;
  @Input() showDescription = true;
  @Input() basketItem: BasketItemViewModel;
  @Input() placeIsPreviewOnly: boolean;
  @Input() isExpandable: boolean;
  @Input() expanded = false;
  @Input() showModifyButton = false;
  @Input() showModifyRedirect = [];
  @Input() isInModal = false;
  @Input() basketMode = false;
  @Input() mobileMode = false;
  @Input() showReturnButton: boolean = false;
  @Input() isSummaryMode: boolean = false;
  @Input() isPriceInDescription: boolean = false;
  @Input() isOpen: boolean = true;
  @Input() isDiscountMode: boolean = false;
  @Input() showPrice: boolean = true;
  @Input() basketItemIndex: number = null;
  @Input() discountNames: string[] = [];
  @Input() public paymentMode: boolean = false;
  @Input() public editable: boolean = true;
  @Input() public isDiscountTurnOn: boolean = true;

  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSave: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectProductEvent: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('cardScrollable') cardScrollable: ElementRef;

  constructor(
    private basketService: BasketService,
    private bottomNotificationComponent: BottomNotificationComponent,
    private translateService: TranslateService,
    private discountService: DiscountService,
    private router: Router,
    private orderService: OrderService,
  ) {}

  ngOnInit(): void {
    this.isExpanded = this.expanded;

    if (this.basketItem && this.basketItem.BasketItemID) {
      this.selectedModifiers = this.basketItem.SelectedModifiers;
    }

    if (this.renderedInOrderSummary) {
      this.orderQuantity = this.basketItem.CurrentQuantity;
    }

    /* Zapisywanie wybranych modyfikatorów jednokrotnego wyboru dla produktu z koszyka*/
    if (this.basketItem && this.basketItem.BasketItemID) {
      this.basketItem.modifiers?.forEach((thisModifier) => {
        if (thisModifier.maxQuantity == 1) {
          thisModifier.products.forEach((thisModifierProduct) => {
            this.basketService.basketItems.forEach((thisBasketItem) => {
              if (thisBasketItem.BasketItemID == this.basketItem.BasketItemID) {
                thisBasketItem.SelectedModifiers.forEach((thisBasketItemSelectedModifier) => {
                  if (thisModifierProduct.publicId == thisBasketItemSelectedModifier.publicId) {
                    this.selectedSingleChoiceModifiers[thisModifier.publicId] = thisBasketItemSelectedModifier.publicId;
                  }

                  /* Zapisywanie również zagnieżdżonych modyfikatorów jednokrotnego wyboru */
                  if (thisModifierProduct.hasModifiers) {
                    thisModifierProduct.modifiers.forEach((thisNestedModifier) => {
                      thisNestedModifier.products.forEach((thisNestedModifierProduct) => {
                        if (thisNestedModifierProduct.publicId == thisBasketItemSelectedModifier.publicId) {
                          this.selectedSingleChoiceModifiers[thisNestedModifier.publicId] =
                            thisBasketItemSelectedModifier.publicId;
                        }
                      });
                    });
                  }
                });
              }
            });
          });
        }
      });
    }

    /* Jeżeli produkt jest pizzą i produkt jest w koszyku to rozwijanie obecnie wybranego rozmiaru */
    if (this.isPizza() && this.basketItem && this.basketItem.BasketItemID) {
      this.basketItem.modifiers[0].products.forEach((thisBasketItemModifierProduct) => {
        if (thisBasketItemModifierProduct.CurrentQuantity > 0) {
          this.selectedNestedModifier = thisBasketItemModifierProduct;
        }
      });
    }

    this.discountSubscription$ = this.discountService.data$.subscribe((data) => {
      if (data) {
        this.refreshItemPrice(this._item);
      } else {
        this.refreshItemPrice(null);
      }
    });
  }

  ngOnDestroy(): void {
    this.discountSubscription$.unsubscribe();
  }

  expand() {
    if (this.orderQuantity == 0) {
      this.isExpanded = true;
    }
  }

  shrink() {
    this.isExpanded = false;
  }

  toggle() {
    /*if (this.orderQuantity == 0) { this.isExpanded = !this.isExpanded; }*/
    if (this.isExpandable) {
      this.isExpanded = !this.isExpanded;
    }

    if (this.basketItem) {
      console.log('otworzono w koszyku, wykonywanie kopii zapasowej edytowanego itemka');
      this.basketItemCopy = cloneDeep(this.basketItem);
      this.itemCopy = cloneDeep(this.item);
      this.selectedModifiersCopy = cloneDeep(this.selectedModifiers);
      console.log(this.basketItemCopy);
    }
  }

  cancelChanges() {
    console.log('anulowanie zmian');
    this.basketItem = cloneDeep(this.basketItemCopy);
    this._item = cloneDeep(this.itemCopy);
    this.selectedModifiers = cloneDeep(this.selectedModifiersCopy);
    this.closeModal.emit();
    this.basketService.update(this.basketItem, this.selectedModifiers);
    this.basketService.saveBasket();
    this.basketService.refreshBasket();
  }

  saveChanges() {
    console.log('zapisywanie zmian');
    /* Jeżeli jest to produkt z koszyka to aktualizujemy go odrazu po zmianie*/
    if (this.basketItem && this.basketItem.BasketItemID) {
      /* Dla przedmiotu w koszyku sprawdzanie czy wybrano wszystkie wymagane modyfikatory */
      if (this.verifyItemBeforeAdd()) {
        this.basketService.update(this.basketItem, this.selectedModifiers);
      }
    }
    this.closeModal.emit();
    this.onSave.emit();
  }

  orderAdd() {
    // this.isExpanded = false;
    // this.orderQuantity = 1;
    if (this.verifyItemBeforeAdd()) {
      this.item.CurrentQuantity = this.orderQuantity;
      /* Sprawdzanie czy w koszyku nie istnieje identyczny produkt, jeżeli tak to zwiększanie jego ilości zamiast dodawania nowego */
      let basketItem = this.basketService.itemPresentInBasket(this.item, this.selectedModifiers);

      if (this.isDiscountMode === true) {
        let _product = this.item;
        _product.CurrentQuantity = 1;
        let _basketItem = Object.assign(new BasketItemViewModel(), _product);

        this.selectProductEvent.emit(_basketItem);
        return;
      }

      if (basketItem.discountId == null) {
        if (basketItem) {
          let tempBasketItem = cloneDeep(this.item);
          tempBasketItem.internalId = uuidv4();
          this.basketService.add(tempBasketItem, tempBasketItem.SelectedModifiers);
          console.log('item present in basket already');
          // this.basketService.updateQuantity(
          //   basketItem,
          //   basketItem.CurrentQuantity + this.item.CurrentQuantity
          // );
        } else {
          console.log('adding new item to basket');
          this.basketService.add(this.item, this.selectedModifiers);
        }
      } else {
        console.log('adding new item to basket');
        this.basketService.add(this.item, this.selectedModifiers);
      }

      /* Czyszczenie pola opis po dodaniu produktu do koszyka*/
      this.item.notes = '';

      /*this.bottomNotificationComponent.show(
        this.translateService.instant(
          'tapp-order.article-card-success-notification-text-1'
        ) +
          ' ' +
          this.item.CurrentQuantity +
          ' ' +
          this.translateService.instant(
            'tapp-order.article-card-success-notification-text-2'
          ),
        1500
      );*/

      this.bottomNotificationComponent.show(
        this.translateService.instant('tapp-order.article-card-success-notification-text-constant'),
        1500,
      );

      /* Zwijanie po kliknięcu addtoorder */
      if (this.isExpandable) {
        this.isExpanded = false;
      }

      /* Chowanie modal jezeli jest inmodal */
      if (this.isInModal) {
        this.closeModal.emit();
      }

      setTimeout(() => {
        this.selectedModifiers = [];
        this.resetCheckboxes();
        this.resetModifiersQuantity();
        this.orderQuantity = this.item.CurrentQuantity = 1;
        if (this.isPizza()) {
          this.resetNestedModifiersQuantity();
        }
      }, 500);
    }
  }

  verifyItemBeforeAdd(): boolean {
    let scrolledToRequired = false;
    /* Funkcja sprawdza czy wszystkie wymagania aby dodać przedmiot do koszyka zostały spełnione */
    /* Sprawdzanie czy dla każdej wymaganej grupy został wybrany conajmniej jeden modyfikator */
    let requiredModifiersSelected = true;
    /* Dla kazdej grupy modyfikatorów */
    this.item.modifiers.forEach((thisItemModifier) => {
      /* Sprawdzanie czy jest wymagane wybranie chociaż jednego */
      if (thisItemModifier.minQuantity > 0) {
        let thisItemModifierRequiredModifiersSelected = false;
        /* Dla każdego modyfikatora z grupy */
        thisItemModifier.products.forEach((thisItemModifierProduct) => {
          /* Sprawdzanie czy jest obecny na liście wybranych */
          if (
            this.selectedModifiers.filter((thisSelectedModifierProduct) => {
              return thisSelectedModifierProduct.publicId == thisItemModifierProduct.publicId;
            }).length > 0
          ) {
            /* Modyfikator dla tej grupy jest wybrany */
            thisItemModifierRequiredModifiersSelected = true;
          }

          /* Sprawdzanie czy nie jest to modyfikator zagniezdzony */
          /*if(thisItemModifierProduct.hasModifiers) {
            /!* Pętla po grupach modyfikatorów zagnieżdżonych *!/
            thisItemModifierProduct.modifiers.forEach(thisNestedModifier => {
              /!* Srawdzanie czy trzeba wybrać chociaż jeden*!/
              if (thisNestedModifier.minQuantity > 0) {
                let thisNestedModifierRequiredModifiersSelected: boolean = true;
                if(thisNestedModifier.products.filter(thisNestedModifierProduct => {
                  return thisNestedModifierProduct.CurrentQuantity == 1;
                }).length == 0) {
                  thisNestedModifierRequiredModifiersSelected = false
                }
                if(!thisNestedModifierRequiredModifiersSelected) {
                  document.getElementById(this.item.publicId + "-" + thisNestedModifier.publicId).classList.add("modifier-accordion-error");
                  thisItemModifierRequiredModifiersSelected = false;
                }
              }
            })
          }*/
        });
        if (!thisItemModifierRequiredModifiersSelected) {
          /* Dla tej grupy nie zostal wybrany zaden modyfikator */
          requiredModifiersSelected = false;
          console.log("Nie wybrano zadnego modyfikatora z grupy '" + thisItemModifier.name + "'");

          thisItemModifier.hasError = true;

          if (!scrolledToRequired) {
            scrolledToRequired = true;
            const element = document.getElementById(this.item.publicId + '-' + thisItemModifier.publicId);
            if (element) {
              let topPosition = element.offsetTop;

              this.cardScrollable.nativeElement.scrollTo({
                top: topPosition,
                behavior: 'smooth',
              });
            }
          }
        } else {
          thisItemModifier.hasError = false;
        }
      }
    });

    /* Sprawdzanie wybranej grupy modyfikatorow zagniezdzonych */
    if (this.isPizza() && this.selectedNestedModifier && this.selectedNestedModifier.modifiers) {
      this.selectedNestedModifier.modifiers.forEach((thisNestedModifier) => {
        if (thisNestedModifier.minQuantity > 0) {
          if (
            thisNestedModifier.products.filter((thisNestedModifierProduct) => {
              return thisNestedModifierProduct.CurrentQuantity > 0;
            }).length == 0
          ) {
            thisNestedModifier.hasError = true;

            if (!scrolledToRequired) {
              scrolledToRequired = true;
              const element = document.getElementById(this.item.publicId + '-' + thisNestedModifier.publicId);
              if (element) {
                let topPosition = element.offsetTop;

                this.cardScrollable.nativeElement.scrollTo({
                  top: topPosition,
                  behavior: 'smooth',
                });
              }
            }

            requiredModifiersSelected = false;
          } else {
            thisNestedModifier.hasError = false;
          }
        }
      });
    }

    if (!requiredModifiersSelected) {
      return false;
    }
    return true;
  }

  resetCheckboxes() {
    /*Unchecking and enable all checkboxes*/
    this.item.modifiers.forEach((modifier, modifierIndex) => {
      modifier.products.forEach((modifierProduct, modifierProductIndex) => {
        /*this.item.modifiers[modifierIndex].products[modifierProductIndex].isChecked = false;*/
        modifierProduct.isChecked = false;
        modifierProduct.checkboxDisabled = false;
      });
    });
  }

  resetModifiersQuantity() {
    this.item.modifiers.forEach((thisModifier) => {
      thisModifier.products.forEach((thisModifierProduct) => {
        thisModifierProduct.CurrentQuantity = 0;
      });
    });
  }

  orderQuantityIncrease() {
    const basketItem = cloneDeep(this.basketItem);

    basketItem.internalId = uuidv4();

    this.basketService.add(basketItem, basketItem.SelectedModifiers);

    if (this.mobileMode) {
      this.discountService.calculate();
    }

    // Another way to increase quantity without duplicating item in basket (possible not working)
    // if (this.basketItem.discountId) {
    //   return;
    // }

    // this.orderQuantity = this.basketItem.CurrentQuantity;

    // this.orderQuantity++;
    // this.basketService.updateQuantity(this.basketItem, this.orderQuantity);
  }

  orderQuantityDecrease() {
    this.orderQuantity = this.basketItem.CurrentQuantity;

    if (this.orderQuantity > 1 || this.basketItem) {
      this.orderQuantity--;
      this.basketService.updateQuantity(this.basketItem, this.orderQuantity);
    }
    /* Zamykanie popup jezeli obecna wartosc < 1 */
    if (this.orderQuantity < 1) {
      this.closeModal.emit();
    }
  }

  orderQuantityDecreaseConfirm() {
    this.orderQuantityDecrease();

    if (this.mobileMode) {
      this.discountService.calculate();
    }
  }

  addModifier(modifier: ProductViewModel) {
    const group = this.groupFromModifierProduct(modifier);
    modifier.groupId = group.publicId;
    modifier.CurrentQuantity = 1;
    if (
      this.selectedModifiers.filter((thisSelectedModifier) => {
        return thisSelectedModifier.publicId == modifier.publicId;
      }).length == 0
    ) {
      this.selectedModifiers.push(modifier);
    }
  }

  increaseModifierQuantity(modifier: ProductViewModel) {
    const group = this.groupFromModifierProduct(modifier);
    this.unlockGroupModifiers(group);
    if (this.canAddModifier(modifier, group)) {
      if (!modifier.CurrentQuantity || modifier.CurrentQuantity == 0) {
        this.addModifier(modifier);
      } else {
        modifier.CurrentQuantity += 1;
      }
    }
    this.lockGroupModifiers(modifier, group);

    /* Modyfikator który jest w modifier, po odświeżeniu nie jest dokładnie tym samym obiektem przekazanym przez referencje co obiekt w selectedModifiers, dlatego dla pewności trzeba nadpisać wartość w selectedModifiers*/
    this.selectedModifiers.forEach((thisSelectedModifier) => {
      if (thisSelectedModifier.publicId == modifier.publicId) {
        thisSelectedModifier.CurrentQuantity = modifier.CurrentQuantity;
      }
    });

    /* Jeżeli jest to produkt z koszyka to aktualizujemy go odrazu po zmianie*/
    /*if (this.basketItem && this.basketItem.BasketItemID) {
      /!* Dla przedmiotu w koszyku sprawdzanie czy wybrano wszystkie wymagane modyfikatory *!/
      if (this.verifyItemBeforeAdd()) {
        this.basketService.update(this.basketItem, this.selectedModifiers);
      }
    }*/
  }

  decreaseModifierQuantity(modifier: ProductViewModel) {
    const group = this.groupFromModifierProduct(modifier);
    if (modifier.CurrentQuantity > 0) {
      modifier.CurrentQuantity -= 1;
    }

    if (modifier.CurrentQuantity == 0) {
      this.removeModifier(modifier);
    }

    /* Modyfikator który jest w modifier, po odświeżeniu nie jest dokładnie tym samym obiektem przekazanym przez referencje co obiekt w selectedModifiers, dlatego dla pewności trzeba nadpisać wartość w selectedModifiers*/
    this.selectedModifiers.forEach((thisSelectedModifier) => {
      if (thisSelectedModifier.publicId == modifier.publicId) {
        thisSelectedModifier.CurrentQuantity = modifier.CurrentQuantity;
      }
    });

    /* Jeżeli jest to produkt z koszyka to aktualizujemy go odrazu po zmianie*/
    /*if (this.basketItem && this.basketItem.BasketItemID) {
      /!* Dla przedmiotu w koszyku sprawdzanie czy wybrano wszystkie wymagane modyfikatory *!/
      if (this.verifyItemBeforeAdd()) {
        this.basketService.update(this.basketItem, this.selectedModifiers);
      }
    }*/

    /* Jeżeli ilość obecnie wybranych modyfikatorów dla grupy jest mniejsza niż maksymalna ilość dla tej grupy, to odblokowywanie wszystkich modyfikatorów dla tej grupy */
    if (this.countSelectedModifiersFromGroup(group) < group.maxQuantity) {
      this.unlockGroupModifiers(group);
    }
  }

  private removeModifier(modifier: ProductViewModel) {
    modifier.CurrentQuantity = 0;
    this.selectedModifiers = this.selectedModifiers.filter((thisSelectedModifier) => {
      return thisSelectedModifier.publicId != modifier.publicId;
    });

    /* Modyfikator który jest w modifier, po odświeżeniu nie jest dokładnie tym samym obiektem przekazanym przez referencje co obiekt w selectedModifiers, dlatego dla pewności trzeba nadpisać wartość w selectedModifiers*/
    this.selectedModifiers.forEach((thisSelectedModifier) => {
      if (thisSelectedModifier.publicId == modifier.publicId) {
        thisSelectedModifier.CurrentQuantity = modifier.CurrentQuantity;
      }
    });

    /* Jeżeli jest to produkt z koszyka to aktualizujemy go odrazu po zmianie*/
    /*if (this.basketItem && this.basketItem.BasketItemID) {
      /!* Dla przedmiotu w koszyku sprawdzanie czy wybrano wszystkie wymagane modyfikatory *!/
      if (this.verifyItemBeforeAdd()) {
        this.basketService.update(this.basketItem, this.selectedModifiers);
      }
    }*/
  }

  changeCheckbox(modifierProduct: ProductViewModel, modifier: ModifierViewModel) {
    if (modifierProduct.isChecked) {
      this.addModifier(modifierProduct);
      if (modifier.maxQuantity == 1) {
        this.otherCheckboxes(modifierProduct, modifier, true);
      }
    } else {
      this.removeModifier(modifierProduct);
      if (modifier.maxQuantity == 1) {
        this.otherCheckboxes(modifierProduct, modifier, false);
      }
    }

    /*Sprawdzanie czy maxQuantity wieksze od 1, jezeli tak to najpierw odblokowujemy wszystkie zablokowane checkboxy, nastepnie sprawdzamy czy liczba obecnie zaznaczonych nie jest rowna maxQuantity, jezeli tak to blokujemy pozostale niezaznaczone*/
    if (modifier.maxQuantity > 1) {
      this.enableDisabledCheckboxes(modifier);
      if (this.countCheckedCheckboxes(modifier) == modifier.maxQuantity) {
        this.disableUncheckedCheckboxes(modifier);
      }
    }

    /*Jeżeli jest to produkt z koszyka, to aktualizujemy go w koszyku przy każdej zmianie modyfikatora*/
    /*if (this.basketItem && this.basketItem.BasketItemID) {
      /!* Dla przedmiotu w koszyku sprawdzanie czy wybrano wszystkie wymagane modyfikatory *!/
      if (this.verifyItemBeforeAdd()) {
        this.basketService.update(this.basketItem, this.selectedModifiers);
      }
    }*/
  }

  countCheckedCheckboxes(modifier: ModifierViewModel): number {
    return modifier.products.filter((thisModifierProduct) => {
      return thisModifierProduct.isChecked;
    }).length;
  }

  otherCheckboxes(modifierProduct: ProductViewModel, modifier: ModifierViewModel, disabled: boolean) {
    this.item.modifiers.forEach((itemModifier, itemModifierIndex) => {
      if (itemModifier.publicId == modifier.publicId) {
        itemModifier.products.forEach((itemModifierProduct, itemModifierProductIndex) => {
          if (modifierProduct.publicId != itemModifierProduct.publicId) {
            /*this.item.modifiers[itemModifierIndex].products[itemModifierProductIndex].checkboxDisabled = disabled;*/
            itemModifierProduct.checkboxDisabled = disabled;
          }
        });
      }
    });
  }

  disableUncheckedCheckboxes(modifier: ModifierViewModel) {
    this.item.modifiers.forEach((thisModifier) => {
      if (thisModifier.publicId == modifier.publicId) {
        thisModifier.products.forEach((thisModifierProduct) => {
          if (!thisModifierProduct.isChecked) {
            thisModifierProduct.checkboxDisabled = true;
          }
        });
      }
    });
  }

  enableDisabledCheckboxes(modifier: ModifierViewModel) {
    this.item.modifiers.forEach((thisModifier) => {
      if (thisModifier.publicId == modifier.publicId) {
        thisModifier.products.forEach((thisModifierProduct) => {
          if (thisModifierProduct.checkboxDisabled) {
            thisModifierProduct.checkboxDisabled = false;
          }
        });
      }
    });
  }

  /*Accordion modifiers functions*/

  /*toggleAccordionBody(
    publicId: string,
    modifierProduct: any = null,
    modifier: any = null
  ) {
    const bodyElement = document.getElementById(publicId);
    /!*Jeżeli checkbox jest zaznaczony, a accordion zwinięty, to kliknięcie nie powoduje odznaczenia*!/
    if (
      modifierProduct != null &&
      modifierProduct.isChecked &&
      !bodyElement.classList.contains('modifier-accordion-body-expanded')
    ) {
      /!*Tylko rozwiń*!/
      bodyElement.classList.toggle('modifier-accordion-body-expanded');
    } else {
      if (modifierProduct == null || !modifierProduct.checkboxDisabled) {
        bodyElement.classList.toggle('modifier-accordion-body-expanded');
      }
      /!*Jeżeli jest to modyfikator zagnieżdzony to zaznaczamy jego checkbox i blokujemy rozwijanie*!/
      if (
        modifier != null &&
        modifierProduct != null &&
        modifierProduct.checkboxDisabled == false
      ) {
        this.changeCheckbox(modifierProduct, modifier);
        modifierProduct.isChecked = !modifierProduct.isChecked;
        if (modifierProduct.isChecked) {
          this.otherCheckboxes(modifierProduct, modifier, true);
        } else {
          this.otherCheckboxes(modifierProduct, modifier, false);
        }
      }
    }
    this.accordionExpanded[publicId] = bodyElement.classList.contains(
      'modifier-accordion-body-expanded'
    );
  }*/

  accordionExpandedF(publicId: string): boolean {
    return this.accordionExpanded[publicId];
  }

  toggleAccordionBody(modifier: any = null) {
    modifier.isExpanded = !modifier.isExpanded;
  }

  changeRadio(modifierProduct: ProductViewModel, modifier: ModifierViewModel) {
    /* Sprawdzanie czy w wybranych modyfikatorach nie ma jakiegoś z tej grupy, jeżeli tak to usuwanie*/
    this.selectedModifiers.forEach((thisSelectedModifier) => {
      modifier.products.forEach((thisModifierProduct) => {
        /* Resetowanie CurrentQuantity innych modyfikatorów z tej grupy */
        if (thisModifierProduct.publicId != modifierProduct.publicId) {
          thisModifierProduct.CurrentQuantity = 0;
        }
        if (thisSelectedModifier.publicId == thisModifierProduct.publicId) {
          this.removeModifier(thisModifierProduct);
        }
      });
    });
    /* Dodawanie wybranego modyfikatora */
    this.addModifier(modifierProduct);

    /*Jeżeli jest to produkt z koszyka, to aktualizujemy go w koszyku przy każdej zmianie modyfikatora*/
    /*if (this.basketItem && this.basketItem.BasketItemID) {
      /!* Dla przedmiotu w koszyku sprawdzanie czy wybrano wszystkie wymagane modyfikatory *!/
      if (this.verifyItemBeforeAdd()) {
        this.basketService.update(this.basketItem, this.selectedModifiers);
      }
    }*/
  }

  /* Zmiana wybranej grupy modyfikatorów zagniezdzonych */
  changeRadioNestedModifiersGroup(modifierProduct: ProductViewModel, modifier: ModifierViewModel) {
    this.resetNestedModifiersQuantity();
    /* Czyszczenie selectedModifiers */
    this.selectedModifiers = [];
    /* Czyszczenie wybranych modyfikatorów jednokrotnego wyboru */
    this.selectedSingleChoiceModifiers = {};
    this.changeRadio(modifierProduct, modifier);
    this.selectedNestedModifier = modifierProduct;
    this.checkStandardModifiers();
  }

  /* Zaznaczanie dodatków z grupy Dodatki Standardowe */
  checkStandardModifiers() {
    console.log(this.selectedNestedModifier);
    this.selectedNestedModifier.modifiers.forEach((thisSelectedNestedModifierModifier) => {
      if (thisSelectedNestedModifierModifier.name == 'Dodatki standardowe') {
        console.log('wybieranie dodatkow standardowych');
        thisSelectedNestedModifierModifier.products.forEach((thisSelectedNestedModifierModifierProduct) => {
          if (thisSelectedNestedModifierModifierProduct.defaultQuantity == 1) {
            thisSelectedNestedModifierModifierProduct.isChecked = true;
            if (thisSelectedNestedModifierModifierProduct.minQuantity == 1) {
              thisSelectedNestedModifierModifierProduct.checkboxDisabled = true;
            }
          }
        });
      }
    });
  }

  /* Ustawianie wszystkich modyfikatorów zagnieżdżonych CurrentQuantity na 0 */
  resetNestedModifiersQuantity() {
    this.item.modifiers[0].products.forEach((thisModifierProduct) => {
      thisModifierProduct.CurrentQuantity = 0;
      thisModifierProduct.isChecked = false;
      thisModifierProduct.checkboxDisabled = false;

      thisModifierProduct.modifiers.forEach((thisNestedModifier) => {
        thisNestedModifier.products.forEach((thisNestedModifierProduct) => {
          thisNestedModifierProduct.CurrentQuantity = 0;
          thisNestedModifierProduct.isChecked = false;
          thisNestedModifierProduct.checkboxDisabled = false;
        });
      });
    });
  }

  groupFromModifierProduct(modifierProduct: ProductViewModel): ModifierViewModel {
    let toReturn: ModifierViewModel = {} as ModifierViewModel;
    this.item.modifiers.forEach((thisModifier) => {
      thisModifier.products.forEach((thisModifierProduct) => {
        /* Jeżeli ta grupa posiada modyfkatory to znaczy ze są to modyfikatory zagniezdzone, szukanie tez w srod nich */
        if (thisModifierProduct.hasModifiers) {
          thisModifierProduct.modifiers.forEach((thisNestedModifier) => {
            thisNestedModifier.products.forEach((thisNestedModifierProduct) => {
              if (thisNestedModifierProduct.publicId == modifierProduct.publicId) {
                toReturn = thisNestedModifier;
              }
            });
          });
        }
        if (thisModifierProduct.publicId == modifierProduct.publicId) {
          toReturn = thisModifier;
        }
      });
    });
    return toReturn;
  }

  modifiersSelectedFromGroup(group: ModifierViewModel): ProductViewModel[] {
    this.item.modifiers.forEach((thisModifier) => {
      if (thisModifier.publicId == group.publicId) {
        this.selectedModifiers.filter((thisSelectedModifier) => {
          return thisSelectedModifier.publicId == thisModifier.publicId;
        });
      }
    });
    return [];
  }

  canAddModifier(modifierProduct: ProductViewModel, modifier: ModifierViewModel): boolean {
    if (this.modifiersSelectedFromGroup(modifier).length >= modifier.maxQuantity) {
      return false;
    }
    if (modifierProduct.CurrentQuantity >= modifierProduct.maxQuantity) {
      return false;
    }
    const countedModifiersFromThisGroup = this.countSelectedModifiersFromGroup(modifier);
    if (countedModifiersFromThisGroup >= modifier.maxQuantity) {
      return false;
    }
    return true;
  }

  lockedModifiersInGroup(group: ModifierViewModel): boolean {
    /* Funkcja sprawdza czy dla danej grupy są jakieś nieaktywne modyfikatory */
    let toReturn = false;
    group.products.forEach((thisProduct) => {
      if (
        document
          .getElementById(group.publicId + '-' + thisProduct.publicId)
          .classList.contains('modifier-accordion-body-item-inactive')
      ) {
        toReturn = true;
      }
    });
    return toReturn;
  }

  unlockGroupModifiers(modifier: ModifierViewModel) {
    /* Usuwanie wszystkich klas nieaktywnosci dla przedmiotow z tej grupy */
    modifier.products.forEach((thisModifierProduct) => {
      document
        .getElementById(modifier.publicId + '-' + thisModifierProduct.publicId)
        .classList.remove('modifier-accordion-body-item-inactive');
    });
  }

  lockGroupModifiers(modifierProduct: ProductViewModel, modifier: ModifierViewModel) {
    const countedModifiersFromThisGroup = this.countSelectedModifiersFromGroup(modifier);
    if (countedModifiersFromThisGroup >= modifier.maxQuantity) {
      /* Wyszarzanie i blokowanie reszty modyfikatorów z tej grupy
       * Wyszarzone powinny zostać tylko te modyfikatory które maj currentQuantity <= 0 */
      modifier.products.forEach((thisModifierProduct) => {
        if (thisModifierProduct.CurrentQuantity <= 0) {
          document
            .getElementById(modifier.publicId + '-' + thisModifierProduct.publicId)
            .classList.add('modifier-accordion-body-item-inactive');
        }
      });
    }
  }

  resetErrorClasees() {
    Array.from(document.getElementsByClassName('modifier-accordion-error')).forEach(function (element, index, array) {
      element.classList.remove('modifier-accordion-error');
    });
  }

  public getAllConfiguredModifiers(
    modifiers: ModifierViewModel[],
    selectedModifiers: ProductViewModel[] = [],
    unselectedModifiers: ProductViewModel[] = [],
  ): ProductViewModel[][] {
    if (modifiers) {
      modifiers.forEach((modifier) => {
        modifier.products.forEach((modifierProduct) => {
          if (modifierProduct.CurrentQuantity == 0 && modifierProduct.defaultQuantity > 0) {
            modifierProduct.groupName = modifier.name;
            modifierProduct.groupId = modifier.publicId;
            unselectedModifiers.push(modifierProduct);
          }

          if (modifierProduct.CurrentQuantity > 0) {
            modifierProduct.groupName = modifier.name;
            modifierProduct.groupId = modifier.publicId;
            selectedModifiers.push(modifierProduct);

            if (modifierProduct.hasModifiers) {
              return this.getAllConfiguredModifiers(modifierProduct.modifiers, selectedModifiers, unselectedModifiers);
            }
          }
        });
      });
    }

    return [selectedModifiers, unselectedModifiers];
  }

  private compensationIngredient(): void {
    if (this.item.baseAdditionSwitchAllowed) {
      let configuredModifiers = this.getAllConfiguredModifiers(this.item.modifiers);
      let unselectedModifiers: ProductViewModel[] = configuredModifiers[1];
      let bufferPrice = 0;
      let copySelectedModifier = cloneDeep(this.basketItem.SelectedModifiers);
      let selectedPizzaSize: string;
      let index = 0;

      copySelectedModifier.forEach((ingredient) => {
        if (ingredient.modifierType === ModifierTypeEnum.PIZZA_PAID_INGREDIENT) {
          ingredient.price = ingredient.originalInternalPrice;
          ingredient.totalPrice = ingredient.originalInternalPrice * ingredient.CurrentQuantity;
        }
      });

      copySelectedModifier.forEach((ingredient) => {
        if (ingredient.modifierType === ModifierTypeEnum.PIZZA_SIZE) {
          selectedPizzaSize = ingredient.originalExternalSizeId;
        }
      });

      if (unselectedModifiers.length > 0) {
        unselectedModifiers.forEach((deletedModifier) => {
          if (deletedModifier.originalExternalPrice) {
            deletedModifier.originalExternalPriceEncoded.forEach((originalSize) => {
              if (selectedPizzaSize == originalSize.sizeId.toString()) {
                bufferPrice += originalSize.price;
              }
            });
          }
        });
      }

      this.basketItem.SelectedModifiers.forEach((ingredient) => {
        if (ingredient.modifierType === ModifierTypeEnum.PIZZA_PAID_INGREDIENT) {
          if (bufferPrice > 0) {
            if (bufferPrice >= copySelectedModifier[index].totalPrice) {
              ingredient.totalPrice = 0;
              bufferPrice = bufferPrice - copySelectedModifier[index].totalPrice;
            } else {
              ingredient.totalPrice = copySelectedModifier[index].totalPrice - bufferPrice;
              bufferPrice = 0;
            }
          }
        }
        index++;
      });
    }
  }

  calculatePrice(): number {
    if (this.isDiscountTurnOn) {
      let price: number = 0;

      if (this.discountService.calculateDiscountResponse) {
        this.discountService.calculateDiscountResponse.items.find((item) => {
          if (this.basketItem.internalId === item.internalId) {
            price += item.totalPrice - item.totalDiscount; // item price of first half of pizza
          }
          if (this.basketItem.product2) {
            // second half of pizza
            if (this.basketItem.product2.internalId === item.internalId) {
              price += item.totalPrice - item.totalDiscount; // item price of second half of pizza
            }
          }
        });
      }

      if (
        this.basketItem &&
        price === 0 &&
        (this.orderService.getOrderId() ||
          window.location.pathname.includes('/order/') ||
          window.location.pathname.includes('/order-success/') ||
          window.location.pathname.includes('/order-failed/'))
      ) {
        let totlaPrice = this.basketItem.totalPrice - this.basketItem.totalDiscount;

        const isDividedPizza: boolean = !!this.basketItem.product2;

        if (isDividedPizza) {
          totlaPrice += this.basketItem.product2.totalPrice - this.basketItem.product2.totalDiscount; // total item price of second half of pizza
        }

        return totlaPrice;
      }

      return price;
    } else {
      return this.basketItem.totalPrice + (this.basketItem.product2 ? this.basketItem.product2.totalPrice : 0);
    }
  }

  isEditable(): boolean {
    if (
      this.orderService.getOrderId() ||
      window.location.pathname.includes('/order/') ||
      window.location.pathname.includes('/order-success/') ||
      window.location.pathname.includes('/order-failed/') ||
      !this.editable ||
      (this.basketItem.productType != ProductTypeEnum.PRODUCT_COMPOSED &&
        this.basketItem.productType != ProductTypeEnum.PRODUCT_PIZZA)
    ) {
      return false;
    }

    // TODO: temporary solution
    if (this.basketItem.product2) {
      return false;
    }

    return true;
  }

  displayPrice() {
    if (
      this.basketItem &&
      this.basketItem.totalPriceWithDiscounts != null &&
      this.basketItem.totalPriceWithDiscounts != undefined
    ) {
      return this.basketItem.totalPriceWithDiscounts;
    }
    if (this.basketItem && this.basketItem.BasketItemID) {
      return this.calculatePrice();
    } else {
      return this.item.price;
    }
  }

  public checkPrice() {
    let price = this.item.totalPrice ?? 0;

    this.selectedModifiers.forEach((modifier) => {
      if (modifier.modifierType == ModifierTypeEnum.PIZZA_SIZE) {
        price += modifier.totalPrice;
      }
    });

    return price;
  }

  /* Sprawdzanie czy dany modyfikator to modyfikator zagnieżdżony jednokrotnego wyboru */
  isSingleChoiceNestedModifier(modifier: ModifierViewModel): boolean {
    let toReturn = true;
    modifier.products.forEach((thisModifier) => {
      if (!thisModifier.hasModifiers) {
        toReturn = false;
      }
    });
    return toReturn;
  }

  /* Sprawdzanie czy nadżędny modyfikator tego produktu jest tylko jeden i posiada w sobie modyfikatory zagnieżdżone - w ten sposób można zweryfikować czy to pizza */
  isPizza() {
    let toReturn = true;

    switch (this.item.modifiers?.length) {
      case 0:
        {
          /* Ten produkt wogóle nie posiada modyfikatorów */
          toReturn = false;
        }
        break;

      case 1:
        {
          /* Ten produkt posiada tylko jeden modyfikator */
          if (this.item.modifiers[0].products[0].hasModifiers) {
            /* To napewno pizza ponieważ posiada modyfikator zagnieżdżony */
            toReturn = true;
          } else {
            /* Ten produkt poprostu posiada tylko jeden modyfikator ale nie jest pizzą */
            toReturn = false;
          }
        }
        break;

      default: {
        /* Posiada więcej niż 1 modyfikator */
        toReturn = false;
      }
    }

    return toReturn;
  }

  countSelectedModifiersFromGroup(group: ModifierViewModel): number {
    let toReturn = 0;
    group.products.forEach((thisProduct) => {
      toReturn += thisProduct.CurrentQuantity;
    });
    return toReturn;
  }

  get pizzaSizeAndDough() {
    let pizzaParams: string[] = this.selectedModifiers
      .filter((modifier) => {
        return (
          modifier.productType == 'pizza' &&
          ((modifier.modifierType == ModifierTypeEnum.PIZZA_SIZE && !this.basketItem.hidePizzaSize) ||
            (modifier.modifierType == ModifierTypeEnum.PIZZA_DOUGH && !this.basketItem.hidePizzaDough))
        );
      })
      .map((modifier) => modifier.name);
    return pizzaParams.join(' | ');
  }

  public showConfirmDialog() {}

  public closeConfirmDialog() {
    this.displayConfirmDialog = false;
  }

  public deleteDiscountProducts(discountGroupUid: string) {
    this.basketService.basketItems.forEach((basketItem) => {
      if (basketItem.discountGroupUid === discountGroupUid) {
        this.basketService.updateQuantity(basketItem, 0);
      }
    });
  }

  public editProduct(ev: Event): void {
    ev.stopPropagation();
    const placeId = sessionStorage.getItem('placeId');

    if (!placeId) {
      console.error('#NLDJLAFJL87679SADFL: PlaceId is not defined');
      return;
    }

    this.router.navigate([placeId, 'basket', 'items', this.basketItem.BasketItemID]);
  }

  get productsPage() {
    return ['/', sessionStorage.getItem('placeId')] ?? ['/'];
  }

  private refreshItemPrice(possibleBasketItem: ProductViewModel | BasketItemViewModel | null): void {
    let itemPrice = null;

    if (possibleBasketItem) {
      // @ts-ignore
      const isBasketItem = possibleBasketItem.SelectedModifiers !== undefined;

      if (isBasketItem) {
        const basketItem = possibleBasketItem as BasketItemViewModel;
        const isDividedPizza: boolean = !!basketItem.product2;

        itemPrice = basketItem.totalPrice;

        if (isDividedPizza) {
          itemPrice += basketItem.product2.totalPrice;
        }
      }
    }
    this.basketItemPriceBackendCalucation = itemPrice;
  }
}

interface signatureIndexBoolean {
  [key: string]: boolean;
}

interface IDictionary {
  [index: string]: string;
}
