import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProductViewModel } from '@core/models/tapp-order/view-model/product/product.view.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-product-item',
  templateUrl: './product-item.component.html',
})
export class ProductItemComponent {
  /* Required inputs */
  @Input('data') product!: ProductViewModel;

  /* Optional inputs */
  @Input() buttonVisible: boolean = false;
  @Input() buttonLabel: string = '';

  @Input() priceVisible: boolean = true;

  @Output() onAdd: EventEmitter<void> = new EventEmitter<void>();

  constructor(private translateService: TranslateService) {}
}
