<div class="container d-flex flex-column">
  <div class="row order-0" *ngIf="isDesktop">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div *ngIf="place" [routerLink]="['/', place.publicId]" class="payment-content-return-button ui-clickable">
        <i class="ic ic-back"></i>
        <span>{{ 'tapp-order.payment.back-button' | translate }}</span>
      </div>
    </div>
  </div>
  <div class="row order-1">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-4 mt-4">
      <app-payment-header [place]="place"></app-payment-header>
    </div>
  </div>
  <div class="row order-2" *ngIf="!orderService.tableId">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <app-payment-delivery [isDeliveryZone]="_isDeliveryZone | async"></app-payment-delivery>
    </div>
  </div>
  <div class="row order-3">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <app-delivery-payment-address
        *ngIf="!isLoading"
        [user]="user"
        [tableId]="orderService.tableId"
        [isDeliveryZone]="_isDeliveryZone"
        id="paymentAddress"
      ></app-delivery-payment-address>
    </div>
  </div>
  <div class="row order-4">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <app-payment-message></app-payment-message>
    </div>
  </div>
  <div class="row order-5">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <app-payment-invoice></app-payment-invoice>
    </div>
  </div>
  <div class="row order-6">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <app-payment-method id="paymentMethod"></app-payment-method>
    </div>
  </div>
  <div class="row order-md-7 order-4">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <app-payment-approve id="paymentApprove"></app-payment-approve>
    </div>
  </div>

  <div class="row order-8 mt-1" *ngIf="place && place.company">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <ng-container>
        <div class="company-data w-100">
          <div class="section-title mb-2 pb-1">
            {{ 'tapp-order.place-component.company-section-title' | translate }}:
          </div>

          <div>{{ place.company.name }}</div>
          <div>
            {{ 'tapp-order.place-component.taxNumber' | translate }}:
            {{ place.company.taxIdNumber }}
          </div>
          <div>{{ place.company.getAddress() }}</div>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="row order-9 summary-btn-wrapper">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <app-payment-summary-button-group></app-payment-summary-button-group>
    </div>
  </div>
</div>
