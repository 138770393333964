import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AttributeViewModel } from '@core/models/tapp-order/view-model/product/attribute.view.model';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit, OnChanges {
  @Input() title: string = '';

  @Input() description: string = '';
  @Input() descriptionType: 'silver' | 'black' = 'silver';

  @Input() image: string = '';
  @Input() isImageShiftedLeft: boolean = false;

  @Input() price: number = 0;
  @Input() priceTextFromVisible: boolean = false;

  @Input() buttonVisible: boolean = false;
  @Input() buttonLabel: string = '';

  @Input() attributes: AttributeViewModel[] = [];

  @Input() priceVisible: boolean = true;

  @Output() onButtonClick: EventEmitter<void> = new EventEmitter<void>();

  public buttonType: 'icon' | 'icon-text' = 'icon-text';

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.buttonLabel) {
      this.detectButtonType(changes.buttonLabel.currentValue);
    }
  }

  ngOnInit(): void {
    this.detectButtonType(this.buttonLabel);
  }

  private detectButtonType(buttonLabel: string): void {
    if (buttonLabel) {
      this.buttonType = 'icon-text';
    } else {
      this.buttonType = 'icon';
    }
  }
}
