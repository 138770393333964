import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { environment } from '@env/environment';
import { BasketService } from '../../../../services/basket.service';
import { PlaceService } from '../../../../services/place.service';
import { PlaceViewModel } from '@core/models/tapp-order/view-model/place/place.view.model';
import { VersionService } from '@core/services/version.service';
import { LinkViewModel } from '@core/models/tapp-order/view-model/place/link.view.model';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { LoginModalComponent } from '@shared/login-modal/login-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PlaceDataProvider } from '@core/data-providers/place.data-provider';

@Component({
  selector: 'app-hamburger-button',
  templateUrl: './hamburger-button.component.html',
  styleUrls: ['./hamburger-button.component.scss'],
})
export class HamburgerButtonComponent implements OnInit {
  private modalRef: BsModalRef;
  environment: any = environment;
  private modalClasses: string;
  public hasPlacesList: boolean = false;
  public links: LinkViewModel[] = [];
  public currentYear: number = new Date().getFullYear();
  public termsVisible: boolean = false;
  public termsHtml: SafeHtml = this.domSanitizer.bypassSecurityTrustHtml('');

  constructor(
    private modalService: BsModalService,
    public basketService: BasketService,
    public placeService: PlaceService,
    public versionService: VersionService,
    private router: Router,
    public authService: AuthService,
    public dialog: MatDialog,
    private domSanitizer: DomSanitizer,
    private placeDataProvider: PlaceDataProvider,
  ) {
    this.placeService.getPlaces().subscribe((places: PlaceViewModel[]) => {
      if (places.filter((place: PlaceViewModel) => place.onlineOrderActive).length > 1) {
        this.hasPlacesList = true;
      }

      if (!this.placeService.getPlaceId()) {
        const place = places.find((place: PlaceViewModel) => place.links && place.links.length > 0);
        if (place) {
          this.links = place.links;
        }
      }
    });
  }

  ngOnInit(): void {
    this.modalClasses = 'hamburger-menu-modal';

    this.initPlace();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.initPlace();
      }
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      class: this.modalClasses,
    });
  }

  hideSidebar() {
    this.modalRef.hide();
  }

  get tableMode() {
    return sessionStorage.getItem('tableId') && sessionStorage.getItem('tableName');
  }

  initPlace(): void {
    this.placeService.getPlace().subscribe((place: PlaceViewModel) => {
      this.links = place.links;
    });
  }

  openLoginDialog(): void {
    this.hideSidebar();

    this.dialog.open(LoginModalComponent, {
      panelClass: 'login-form-container',
    });
  }

  public showTermsAndCoditions(): void {
    this.hideSidebar();
    this.termsVisible = true;

    this.placeDataProvider.getTermsAndPrivacyPolicy().subscribe({
      next: (terms) => {
        this.termsHtml = this.domSanitizer.bypassSecurityTrustHtml('');

        if (terms.length) {
          const term = terms.reduce((prev, current) => (prev.id < current.id ? prev : current));

          if (term) {
            this.termsHtml = this.domSanitizer.bypassSecurityTrustHtml(term.content || '');
          }
        }
      },
    });
  }

  public handleTermsHide(): void {
    this.termsVisible = false;
  }
}
