import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ThemeService } from '../../../services/theme.service';
import { LoginModalComponent } from '@shared/login-modal/login-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@core/services/auth.service';
import { NavigationService } from '../../../services/navigation.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mobile-navbar',
  templateUrl: './mobile-navbar.component.html',
  styleUrls: ['./mobile-navbar.component.scss'],
})
export class MobileNavbarComponent implements OnInit {
  @Input() customBackAction = false;
  @Output() onBackEvent: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    public themeService: ThemeService,
    public dialog: MatDialog,
    public authService: AuthService,
    public navigationService: NavigationService,
    private router: Router,
  ) {}

  ngOnInit(): void {}

  openDialog(): void {
    this.dialog.open(LoginModalComponent, {
      panelClass: 'login-form-container',
    });
  }

  public back(): void {
    if (this.customBackAction) {
      this.onBackEvent.emit();
    } else if (this.themeService.getBackArrowPath()) {
      this.router.navigate(this.themeService.getBackArrowPath());
      this.themeService.setbackArrowPath(null);
    } else {
      this.navigationService.back();
    }
  }
}
