import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { DeliveryAddressApiModel } from '@api/model/user/delivery-address.api.model';
import { UserDataApiModel } from '@api/model/user/user-data.api.model';
import { UserRestService } from '@api/rest/user/user.rest.service';
import { BaseDataProvider } from '@core/data-providers/base.data-provider';
import { DeliveryZonesHttpService } from '@core/http/tapp-order/delivery-zones/delivery-zones.http.service';
import { DeliveryZoneApiModel } from '@core/models/tapp-order/api-model/delivery-zones/delivery-zone.api.model';
import { GeoapiApiModel } from '@core/models/tapp-order/api-model/geoapi/geoapi.api.model';
import { DeliveryZoneViewModel } from '@core/models/tapp-order/view-model/delivery-zones/delivery-zone.view.model';
import { HolidayViewModel } from '@core/models/tapp-order/view-model/holiday/holiday.view.model';
import { PlaceViewModel } from '@core/models/tapp-order/view-model/place/place.view.model';
import { AddressFinderService } from '@core/services/address-finder.service';
import { AuthService } from '@core/services/auth.service';
import { OrderService } from '@core/services/order.service';
import { TranslateService } from '@ngx-translate/core';
import { LoadingStatus } from '@shared/loading/model/loading-status.enum';
import { AddressAutocompleteComponent } from '@ui/address-finder/address-autocomplete/address-autocomplete.component';
import { ShapeTypeEnum } from '@ui/address-finder/enum/shape-type.enum';
import { AddressAutocompleteService } from '@ui/address-finder/service/address-autocomplete.service';
import * as L from 'leaflet';
import { Subject, forkJoin } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { globalCacheBusterNotifier } from 'ts-cacheable';
import { PlaceService } from '../../../services/place.service';

@Component({
  selector: 'app-address-finder',
  templateUrl: './address-finder.component.html',
  styleUrls: ['./address-finder.component.scss'],
  providers: [AddressFinderService],
})
export class AddressFinderComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<void>();

  public currentAddress: GeoapiApiModel;
  public deliveryZones: DeliveryZoneApiModel[] = [];
  public localNo: string = '';
  public hasError: boolean = false;
  public errorMessage: string = null;
  public displayPosStatusDialog: boolean = false;
  public displayPosStatusDialogPlace: PlaceViewModel;
  public loadingStatusEnum = LoadingStatus;
  public loadingStatus: LoadingStatus = LoadingStatus.pending;

  @Input() public checkCurrentPlace: boolean = false;
  @Input() public redirectToPlace: boolean = false;
  @Input() public user: UserDataApiModel;
  @Input() public context: 'account' | 'other' = 'other';
  @Input() public withLabel: boolean = false;

  @Output() onFoundEvent: EventEmitter<void> = new EventEmitter<void>();

  @ContentChild(TemplateRef, { static: false }) buttons: TemplateRef<any>;

  @ViewChild('appAddressAutocomplete') appAddressAutocomplete: AddressAutocompleteComponent;

  constructor(
    private translateService: TranslateService,
    private orderService: OrderService,
    private deliveryZonesHttpService: DeliveryZonesHttpService,
    private baseDataProvider: BaseDataProvider,
    private router: Router,
    private placeService: PlaceService,
    private userRestService: UserRestService,
    private authService: AuthService,
    public addressFinderService: AddressFinderService,
    public addressAutocompleteService: AddressAutocompleteService,
  ) {}

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private init(): void {
    if (this.checkCurrentPlace) {
      this.deliveryZonesHttpService
        .getDeliveryZones(this.placeService.getPlaceId())
        .pipe(first(), takeUntil(this.destroyed$))
        .subscribe((zones: DeliveryZoneViewModel[]) => {
          this.deliveryZones = zones;
          this.loadingStatus = LoadingStatus.success;
        });
    } else {
      this.deliveryZonesHttpService
        .getDeliveryZoneList()
        .pipe(first(), takeUntil(this.destroyed$))
        .subscribe((zones: DeliveryZoneViewModel[]) => {
          this.deliveryZones = zones;
          this.loadingStatus = LoadingStatus.success;
        });
    }

    this.orderService.getDeliveryAddressLocalNo() && this.orderService.getDeliveryAddressLocalNo() != 'null'
      ? (this.localNo = this.orderService.getDeliveryAddressLocalNo())
      : null;
  }

  public findPlace(): void {
    if (this.currentAddress) {
      this.hasError = false;
    } else {
      if (!this.getAddressFromSearchResultsIfNotSelected()) {
        this.hasError = true;
        return;
      }
    }

    this.checkDeliveryZones();

    if (this.addressFinderService.getFoundDeliveryZones().length == 0) {
      if (this.checkCurrentPlace) {
        this.errorMessage = this.translateService.instant('tapp-order.select-adress-component.error.place-not-deliver');
      } else {
        this.errorMessage = this.translateService.instant('tapp-order.select-adress-component.error.address-not-found');
      }
    } else {
      const finish = () => {
        if (this.redirectToPlace) {
          this.redirectToProducts();
        } else {
          this.onFoundEvent.emit();
        }
      };

      switch (this.context) {
        case 'account':
          if (this.authService.getToken()) {
            const deliveryAddress = new DeliveryAddressApiModel();

            deliveryAddress.zipCode = this.currentAddress.postCode;
            deliveryAddress.localNo = this.localNo;
            deliveryAddress.city = this.currentAddress.city;
            deliveryAddress.street = this.currentAddress.street;
            deliveryAddress.buildingNo = this.currentAddress.houseNumber;

            this.userRestService
              .putUserDeliveryAddress(deliveryAddress)
              .pipe(first(), takeUntil(this.destroyed$))
              .subscribe({
                next: () => {
                  finish();
                },
                error: () => {},
              });
          }
          break;
        case 'other':
          this.addressFinderService.sortLocalByLowestDeliveryPrice();
          this.addressFinderService.setPlaceIdToSessionStorage();
          this.addressFinderService.setDeliveryZoneIdToLocalStorage();
          this.addressFinderService.setDeliveryZoneToLocalStorage();

          this.orderService.saveDeliveryAddress(this.currentAddress, this.localNo);

          this.addressAutocompleteService.initializedAddress = this.currentAddress;
          this.addressAutocompleteService.selectedAddress = this.currentAddress;
          this.addressAutocompleteService.addressInput = this.currentAddress;
          finish();
          break;
      }
    }
  }

  private checkDeliveryZones(): void {
    this.addressFinderService.unsetFoundDeliveryZones();

    if (this.currentAddress) {
      this.deliveryZones.forEach((deliveryZone) => {
        switch (deliveryZone.shapeType) {
          case ShapeTypeEnum.polygon:
            this.findPointInPolygonDeliveryZone(deliveryZone);
            break;

          case ShapeTypeEnum.circle:
            this.findPointInCircleDeliveryZone(deliveryZone);
            break;
        }
      });
    }
  }

  private findPointInPolygonDeliveryZone(deliveryZone): void {
    const polygon = L.polygon(deliveryZone.coordinates);

    if (this.isMarkerInsidePolygon(this.currentAddress.longitude, this.currentAddress.latitude, polygon)) {
      this.addressFinderService.setFoundDeliveryZones(deliveryZone);
    }
  }

  //Ray Casting algorithm
  private isMarkerInsidePolygon(x, y, poly) {
    let inside = false;
    for (let ii = 0; ii < poly.getLatLngs().length; ii++) {
      let polyPoints = poly.getLatLngs()[ii];
      for (let i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
        let xi = polyPoints[i].lat,
          yi = polyPoints[i].lng;
        let xj = polyPoints[j].lat,
          yj = polyPoints[j].lng;

        let intersect = yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
        if (intersect) {
          inside = !inside;
        }
      }
    }

    return inside;
  }

  private findPointInCircleDeliveryZone(deliveryZone): void {
    var fromLatLng = L.latLng(this.currentAddress.latitude, this.currentAddress.longitude);

    let latitude;
    let longitude;

    if (Array.isArray(deliveryZone.coordinates[0])) {
      latitude = deliveryZone.coordinates[0][1];
      longitude = deliveryZone.coordinates[0][0];
    } else {
      latitude = deliveryZone.coordinates[1];
      longitude = deliveryZone.coordinates[0];
    }

    var toLatLng = L.latLng(latitude, longitude);

    if (fromLatLng.distanceTo(toLatLng) <= deliveryZone.radius) {
      this.addressFinderService.setFoundDeliveryZones(deliveryZone);
    }
  }

  private redirectToProducts(): void {
    globalCacheBusterNotifier.next();
    this.displayPosStatusDialog = false;
    this.displayPosStatusDialogPlace = null;

    forkJoin([
      this.baseDataProvider.getPlace(sessionStorage.getItem('placeId')),
      this.baseDataProvider.getHolidaysList(),
    ])
      .pipe(first(), takeUntil(this.destroyed$))
      .subscribe(([placeViewModel, holidays]: [PlaceViewModel, HolidayViewModel[]]) => {
        let isOpen = this.orderService.isPlaceOpen(placeViewModel, holidays);

        if (placeViewModel.posStatusCheck === true) {
          if (placeViewModel.posStatus == 'PAUSED' && isOpen) {
            this.displayPosStatusDialog = true;
            this.displayPosStatusDialogPlace = placeViewModel;
          } else {
            this.onFoundEvent.emit();
            this.router.navigate([placeViewModel.publicId]);
          }
        } else {
          this.onFoundEvent.emit();
          this.router.navigate([placeViewModel.publicId]);
        }
      });
  }

  public closePosStatusDialog(): void {
    this.displayPosStatusDialog = false;
    this.displayPosStatusDialogPlace = null;
  }

  public onAddressSelected(value: GeoapiApiModel): void {
    this.errorMessage = null;
    this.currentAddress = value;
  }

  public onAddressInit(value: GeoapiApiModel): void {
    this.currentAddress = value;

    this.checkDeliveryZones();

    if (this.addressFinderService.getFoundDeliveryZones().length == 0) {
      this.orderService.removeDeliveryZoneId();
    }
  }

  public onCancel(): void {
    this.hasError = false;
    this.appAddressAutocomplete?.onCancel();
  }

  private getAddressFromSearchResultsIfNotSelected(): boolean {
    if (typeof this.addressAutocompleteService.addressInput === 'string') {
      this.addressAutocompleteService.addressInput = Object.assign(new GeoapiApiModel(), {
        fullAddress: this.addressAutocompleteService.addressInput,
      });
    }

    if (!this.currentAddress && this.addressAutocompleteService.searchResults.length > 0) {
      this.currentAddress =
        this.addressAutocompleteService.searchResults.find(
          (item) => item.fullAddress == this.addressAutocompleteService.addressInput.fullAddress,
        ) ?? null;
    }

    return !!this.currentAddress;
  }
}
