import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NewOrderService } from '@core/http/tapp-order/order/new-order.service';
import { OrderStatusEnum } from '@core/models/tapp-order/api-model/order/OrderStatusEnum';
import { OrderTypeEnum } from '@core/models/tapp-order/api-model/order/OrderTypeEnum';
import { PaymentMethodEnum } from '@core/models/tapp-order/api-model/order/PaymentMethodEnum';
import { PaymentStatusEnum } from '@core/models/tapp-order/api-model/order/PaymentStatusEnum';
import { NewOrderApiModel } from '@core/models/tapp-order/api-model/order/new-order.api.model';
import { BasketItemViewModel } from '@core/models/tapp-order/view-model/basketItem/basket-item.view.model';
import { PlaceViewModel } from '@core/models/tapp-order/view-model/place/place.view.model';
import { PaymentOrderStatusEnum } from '@core/pages/order/enum/payment-order-status.enum';
import { OrderService } from '@core/services/order.service';
import { LoadingService } from '@shared/loading/loading.service';
import { AbandonedBasketService } from '@ui/abandoned-basket/abandoned-basket.service';
import { Subscription, interval } from 'rxjs';
import { appUtils } from 'src/app/utils/app.utils';
import { BasketService } from '../../../../services/basket.service';
import { DeviceDetectorService } from '../../../../services/device-detector.service';
import { PlaceService } from '../../../../services/place.service';
import { ThemeService } from '../../../../services/theme.service';

@Component({
  selector: 'app-order-processing',
  templateUrl: './order-processing.component.html',
  styleUrls: ['./order-processing.component.scss'],
})
export class OrderProcessingComponent implements OnInit, OnDestroy {
  public sessionStorageNewOrder: NewOrderApiModel;
  public isSummaryMode: boolean = true;
  public localOrderIdentifier: string;
  public intervalSubscription: Subscription;
  public orderInterval = interval(5000);
  public restaurant: PlaceViewModel;
  public orderStatus: PaymentOrderStatusEnum;
  public orderTypeEnum = OrderTypeEnum;
  public PaymentOrderStatusEnum: typeof PaymentOrderStatusEnum = PaymentOrderStatusEnum;
  public showErrorPage: boolean = false;
  public _pickupTime: Date;
  public _deliveryTime: Date;
  public routerSubscription: Subscription = new Subscription();
  public showGoBackBtn: boolean = false;
  public timerDateTo: Date;
  public order: NewOrderApiModel = null;
  public orderItems: BasketItemViewModel[] = [];
  public firstLoad: boolean = true;

  constructor(
    public loadingService: LoadingService,
    public deviceService: DeviceDetectorService,
    public basketService: BasketService,
    public orderService: OrderService,
    private router: Router,
    private route: ActivatedRoute,
    private placeService: PlaceService,
    private newOrderService: NewOrderService,
    private abandonedBasketService: AbandonedBasketService,
    private themeService: ThemeService,
  ) {
    this.abandonedBasketService.disableTimer();
  }

  ngOnInit(): void {
    this.themeService.desktopHeaderAdditionalClass = 'sidebar-margin-3';

    this.loadingService.showLoader();
    this.basketService.setCurrentUrl(this.router.url.split('/')[1]);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('error');
    this.getRestaurant();
    const orderId = this.route.snapshot.params.id;
    if (orderId !== undefined) {
      if (code) {
        this.router.navigate(['payments'], { replaceUrl: true });
        return;
      }

      window.history.pushState({}, '', '/order/' + orderId);
      window.history.pushState({}, '', '/order/' + orderId);

      this.updateOrder(orderId);

      this.intervalSubscription = this.orderInterval.subscribe(() => {
        this.updateOrder(orderId);
      });
    } else {
      this.showErrorPage = true;
    }
  }

  ngOnDestroy() {
    this.themeService.desktopHeaderAdditionalClass = '';

    this.routerSubscription.unsubscribe();

    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }

    this.abandonedBasketService.reloadTimer();
    this.orderService.removeOrderId();
    this.basketService.removeBasket();
  }

  public returnBackToPayments(): void {
    window.history.pushState({}, null, '/');
    window.history.pushState({}, null, '/');

    this.router.navigate(['/', 'payments']);
  }

  public goToProducts(): void {
    window.history.pushState({}, null, '/');
    window.history.pushState({}, null, '/');

    const placeId = sessionStorage.getItem('placeId');

    if (!placeId) {
      console.error('#DF20MSXWGLEGJER: PlaceId not found in session storage');
      this.router.navigate(['/']);
      return;
    }

    this.router.navigate([placeId, 'products']);
  }

  private getRestaurant() {
    this.placeService.getPlace().subscribe((result) => {
      this.restaurant = result;
    });
  }

  private convertUTCDateToLocalDate(date): Date {
    const newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
    const offset = date.getTimezoneOffset() / 60;
    const hours = date.getHours();
    newDate.setHours(hours - offset);
    return newDate;
  }

  private updateOrder(id: string) {
    if (this.firstLoad) {
      this.loadingService.showLoader();
    }

    this.orderService.setOrderId(id);
    this.newOrderService.get(id).subscribe(
      (order: NewOrderApiModel) => {
        if (this.firstLoad) {
          this.placeService.use(order.place.publicId);
          this.firstLoad = false;
          this.loadingService.hideLoader();
        }
        this.setOrderStatus(order);

        this.basketService.setFoodTotalPriceBackendCalculation(order.orderPayment.foodTotalPrice);
        this.basketService.setDeliveryCostBackendCalculation(order.orderPayment.deliveryCost);
        this.basketService.setFinalPriceBackendCalculation(order.orderPayment.totalPrice);

        this.sessionStorageNewOrder = order;
        order.pickupTime ? (this._pickupTime = this.convertUTCDateToLocalDate(order.pickupTime)) : null;
        order.deliveryTime ? (this._deliveryTime = this.convertUTCDateToLocalDate(order.deliveryTime)) : null;
        if (order.localOrderIdentifier) {
          this.localOrderIdentifier = order.localOrderIdentifier;
          order.localOrderIdentifier = order.localOrderIdentifier.replace(/\(.*\)/, '').trim();
        }

        if (
          this.orderStatus == PaymentOrderStatusEnum.paymentPending &&
          order.orderPayment.paymentMethod == PaymentMethodEnum.ONLINE
        ) {
          const blickTimeout = 3 * 60 * 1000;
          const createdAt = this.convertUTCDateToLocalDate(order.createdAt);

          this.timerDateTo = createdAt;
          if (new Date().getTime() - createdAt.getTime() >= blickTimeout) {
            this.showGoBackBtn = true;
          }
        }

        if (order.status === OrderStatusEnum.canceled || order.status === OrderStatusEnum.finished) {
          this.intervalSubscription.unsubscribe();
        }

        if (
          this.orderStatus !== PaymentOrderStatusEnum.orderPending &&
          this.orderStatus !== PaymentOrderStatusEnum.paymentPending
        ) {
          this.orderService.removeOrderId();
        }

        if (this.orderStatus === PaymentOrderStatusEnum.orderAccepted) {
          window.history.pushState({}, '', sessionStorage.getItem('placeId') ?? '/');
          window.history.pushState({}, '', '/order/' + id);
          //nie mogę wprost wyczyścić koszyka*/
          // this.basketService.removeBasket();

          if (this.basketService.currentUrl !== null) {
            if (sessionStorage.getItem('clearBasket') == null) {
              sessionStorage.setItem('clearBasket', 'cleared');
            }
          }

          this.routerSubscription.add(
            this.router.events.subscribe((val) => {
              this.basketService.removeBasket();
            }),
          );
        }

        if (
          order.orderPayment.paymentStatus == PaymentStatusEnum.completed &&
          order.status === OrderStatusEnum.canceled
        ) {
          window.history.pushState({}, '', sessionStorage.getItem('placeId') ?? '/');
          window.history.pushState({}, '', '/order/' + id);

          if (this.basketService.currentUrl !== null) {
            if (sessionStorage.getItem('clearBasket') == null) {
              sessionStorage.setItem('clearBasket', 'cleared');
            }
          }
        }

        if (order.orderPayment.paymentStatus == PaymentStatusEnum.canceled) {
          window.history.pushState({}, '', '/payments');
          window.history.pushState({}, '', '/order/' + id);
        }

        this.order = order;

        //TODO: refactor me. Dodać brakujące zmienne do responsa :)
        this.orderItems = appUtils.transformOrderItemApiModelsToBasketItemViewModels(order.orderItems);
      },
      () => {
        if (this.firstLoad) {
          this.loadingService.hideLoader();
          this.firstLoad = false;
        }

        this.intervalSubscription.unsubscribe();
        this.router.navigate(['/']);
      },
    );
  }

  private setOrderStatus(order: NewOrderApiModel): void {
    switch (order.orderPayment.paymentStatus) {
      case PaymentStatusEnum.pending:
        this.orderStatus = PaymentOrderStatusEnum.paymentPending;
        break;

      case PaymentStatusEnum.completed:
        switch (order.status) {
          case OrderStatusEnum.new:
            this.orderStatus = PaymentOrderStatusEnum.orderPending;
            break;

          case OrderStatusEnum.accepted:
            this.orderStatus = PaymentOrderStatusEnum.orderAccepted;
            break;

          case OrderStatusEnum.in_delivery:
            this.orderStatus = PaymentOrderStatusEnum.orderInDelivery;
            break;

          case OrderStatusEnum.finished:
            this.orderStatus = PaymentOrderStatusEnum.orderFinished;
            break;

          case OrderStatusEnum.canceled:
            this.orderStatus = PaymentOrderStatusEnum.orderRefused;
            break;
        }
        break;

      case PaymentStatusEnum.canceled:
        this.orderStatus = PaymentOrderStatusEnum.paymentRefused;
        break;

      case PaymentStatusEnum.refunded:
        if (order.status === OrderStatusEnum.canceled) {
          this.orderStatus = PaymentOrderStatusEnum.paymentRefunded;
        }
        break;

      case PaymentStatusEnum.refunded_owh:
        if (order.status === OrderStatusEnum.canceled) {
          this.orderStatus = PaymentOrderStatusEnum.paymentRefundedOwh;
        }
        break;
    }
  }

  get orderType(): string {
    return this.orderService.getOrderType();
  }

  get deliveryTime(): string {
    return this.orderService.getDeliveryHours();
  }
}
