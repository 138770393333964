import { OrderItemApiModel } from '@core/models/tapp-order/api-model/order/order-item.api.model';
import { BasketItemViewModel } from '@core/models/tapp-order/view-model/basketItem/basket-item.view.model';
import { ProductViewModel } from '@core/models/tapp-order/view-model/product/product.view.model';
import { ModifierTypeEnum } from '@shared/enum/modifier-type.enum';
import { v4 as uuidv4 } from 'uuid';

export const _transformOrderItemApiModelsToBasketItemViewModels = (
  orderItems: OrderItemApiModel[],
): BasketItemViewModel[] => {
  const notDividedPizzaItems: BasketItemViewModel[] = [];
  const dividedPizzaItems: BasketItemViewModel[] = [];

  // Set up not divided pizza items
  orderItems.forEach((orderItem) => {
    if (!orderItem.pizzaGroupId) {
      const basketItem: BasketItemViewModel = new BasketItemViewModel();

      basketItem.BasketItemID = uuidv4();
      basketItem.CurrentQuantity = orderItem.quantity;
      basketItem.name = orderItem.name;
      basketItem.totalPrice = +orderItem.totalPrice;

      // Used to force display this basket item
      basketItem.SelectedModifiers = orderItem.selectedModifiers.map((modifier) => {
        const product = new ProductViewModel();
        product.modifierType = modifier.modifierType;
        product.name = modifier.name;
        product.totalPrice = +modifier.totalPrice;
        product.price = +modifier.totalPrice; // used to force display price in basket
        product.CurrentQuantity = modifier.quantity;

        basketItem.totalPrice += +modifier.totalPrice;
        return product;
      });

      // To show selected modifiers
      const modifiersToShow: ProductViewModel[] = [];

      orderItem.selectedModifiers.forEach((modifier) => {
        switch (modifier.modifierType) {
          case ModifierTypeEnum.PIZZA_PAID_INGREDIENT:
            const product = new ProductViewModel();
            product.modifierType = modifier.modifierType;
            product.name = modifier.name;
            product.totalPrice = +modifier.totalPrice;
            product.CurrentQuantity = modifier.quantity;

            modifiersToShow.push(product);
            break;
          case ModifierTypeEnum.MODIFIER: // Additions
            const product2 = new ProductViewModel();
            product2.modifierType = modifier.modifierType;
            product2.name = modifier.name;
            product2.totalPrice = +modifier.totalPrice;
            product2.CurrentQuantity = modifier.quantity;

            modifiersToShow.push(product2);
            break;
        }
      });

      basketItem.modifiersToShow = modifiersToShow;

      // To show unselected free ingredients
      const unselectedModifiers: ProductViewModel[] = [];

      orderItem.unselectedModifiers?.forEach((modifier) => {
        if (modifier.modifierType === ModifierTypeEnum.PIZZA_FREE_INGREDIENT) {
          const product = new ProductViewModel();
          product.modifierType = modifier.modifierType;
          product.name = modifier.name;
          product.totalPrice = +modifier.totalPrice;
          product.CurrentQuantity = modifier.quantity;
          unselectedModifiers.push(product);
        }
      });

      basketItem.unselectedModifiers = unselectedModifiers;

      notDividedPizzaItems.push(basketItem);
    }
  });

  // Set up divided pizza items
  let pizzaGroupId = 1; // 1 - init; -1 - end;

  do {
    const pizzaGroup = orderItems.filter((orderItem) => orderItem.pizzaGroupId === pizzaGroupId);

    if (pizzaGroup.length > 0) {
      pizzaGroupId++;

      const firstHalfOfPizza = pizzaGroup.find((orderItem) => orderItem.parentPizzaPublicId === null);

      if (!firstHalfOfPizza) {
        console.error('firstHalfOfPizza not found');
        continue;
      }

      const basketItem: BasketItemViewModel = new BasketItemViewModel();

      basketItem.BasketItemID = uuidv4();
      basketItem.name = firstHalfOfPizza.name;
      basketItem.totalPrice = +firstHalfOfPizza.totalPrice;
      basketItem.CurrentQuantity = firstHalfOfPizza.quantity;
      basketItem.notes = firstHalfOfPizza.notes;

      // Used to force display this basket item
      basketItem.SelectedModifiers = firstHalfOfPizza.selectedModifiers.map((modifier) => {
        const product = new ProductViewModel();
        product.modifierType = modifier.modifierType;
        product.name = modifier.name;
        product.totalPrice = +modifier.totalPrice;
        product.price = +modifier.totalPrice; // used to force display price in basket
        product.CurrentQuantity = modifier.quantity / 0.5;

        basketItem.totalPrice += +modifier.totalPrice;

        return product;
      });

      // To show selected paid ingredients
      const modifiersToShow: ProductViewModel[] = [];

      firstHalfOfPizza.selectedModifiers.forEach((modifier) => {
        switch (modifier.modifierType) {
          case ModifierTypeEnum.PIZZA_PAID_INGREDIENT:
            const product = new ProductViewModel();
            product.modifierType = modifier.modifierType;
            product.name = modifier.name;
            product.totalPrice = +modifier.totalPrice;
            product.CurrentQuantity = modifier.quantity / 0.5;

            modifiersToShow.push(product);
            break;
          case ModifierTypeEnum.MODIFIER: // Additions
            const product2 = new ProductViewModel();
            product2.modifierType = modifier.modifierType;
            product2.name = modifier.name;
            product2.totalPrice = +modifier.totalPrice;
            product2.CurrentQuantity = modifier.quantity;

            modifiersToShow.push(product2);
            break;
        }
      });
      basketItem.modifiersToShow = modifiersToShow;

      // To show unselected free ingredients
      const unselectedModifiers: ProductViewModel[] = [];

      firstHalfOfPizza.unselectedModifiers?.forEach((modifier) => {
        if (modifier.modifierType === ModifierTypeEnum.PIZZA_FREE_INGREDIENT) {
          const product = new ProductViewModel();
          product.modifierType = modifier.modifierType;
          product.name = modifier.name;
          product.totalPrice = +modifier.totalPrice;
          product.CurrentQuantity = 0.5;
          unselectedModifiers.push(product);
        }
      });
      basketItem.unselectedModifiers = unselectedModifiers;

      const secondHalfOfPizza = pizzaGroup.find(
        (orderItem) => orderItem.parentPizzaPublicId === firstHalfOfPizza.productPublicId,
      );

      if (!secondHalfOfPizza) {
        console.error('secondHalfOfPizza not found');
        continue;
      }

      basketItem.product2 = new ProductViewModel();
      basketItem.product2.name = secondHalfOfPizza.name;
      basketItem.product2.totalPrice = +secondHalfOfPizza.totalPrice;
      basketItem.product2.CurrentQuantity = secondHalfOfPizza.quantity;
      basketItem.product2.notes = secondHalfOfPizza.notes;

      // Used to force display this basket item
      basketItem.SelectedModifiers2 = secondHalfOfPizza.selectedModifiers.map((modifier) => {
        const product = new ProductViewModel();
        product.modifierType = modifier.modifierType;
        product.name = modifier.name;
        product.totalPrice = +modifier.totalPrice;
        product.price = +modifier.totalPrice; // used to force display price in basket
        product.CurrentQuantity = modifier.quantity / 0.5;

        basketItem.product2.totalPrice += +modifier.totalPrice;

        return product;
      });

      // To show selected paid ingredients
      const modifiersToShow2: ProductViewModel[] = [];

      secondHalfOfPizza.selectedModifiers.forEach((modifier) => {
        if (modifier.modifierType === ModifierTypeEnum.PIZZA_PAID_INGREDIENT) {
          const product = new ProductViewModel();
          product.modifierType = modifier.modifierType;
          product.name = modifier.name;
          product.totalPrice = +modifier.totalPrice;
          product.CurrentQuantity = modifier.quantity / 0.5;
          modifiersToShow2.push(product);
        }
        if (modifier.modifierType === ModifierTypeEnum.MODIFIER) {
          const product2 = new ProductViewModel();
          product2.modifierType = modifier.modifierType;
          product2.name = modifier.name;
          product2.totalPrice = +modifier.totalPrice;
          product2.CurrentQuantity = modifier.quantity;

          modifiersToShow2.push(product2);
        }
      });
      basketItem.modifiersToShow2 = modifiersToShow2;

      // to show unselected free ingredients
      const unselectedModifiers2: ProductViewModel[] = [];

      secondHalfOfPizza.unselectedModifiers?.forEach((modifier) => {
        if (modifier.modifierType === ModifierTypeEnum.PIZZA_FREE_INGREDIENT) {
          const product = new ProductViewModel();
          product.modifierType = modifier.modifierType;
          product.name = modifier.name;
          product.totalPrice = +modifier.totalPrice;
          product.CurrentQuantity = 0.5;
          unselectedModifiers2.push(product);
        }
      });
      basketItem.unselectedModifiers2 = unselectedModifiers2;

      dividedPizzaItems.push(basketItem);
    } else {
      pizzaGroupId = -1;
    }
  } while (pizzaGroupId !== -1);

  return [...notDividedPizzaItems, ...dividedPizzaItems];
};
