<div class="ui-clickable py-2 px-3" (click)="openModal(hamburgerMenu)">
  <i class="ic ic-menu"></i>
</div>

<ng-template #hamburgerMenu>
  <div class="mobile-menu-wrapper">
    <div>
      <div class="close-menu" (click)="hideSidebar()">
        <i class="ic ic-delete"></i>
      </div>

      <div class="invisible-button" (click)="versionService.handleVersionCallClick()"></div>
    </div>
    <div class="logo-wrapper">
      <app-logo></app-logo>
    </div>

    <div class="links-wrapper">
      <div class="link-item" *ngIf="!tableMode && hasPlacesList">
        <a [routerLink]="['places']" (click)="hideSidebar()">{{ 'tapp-order.menu.lokale' | translate }}</a>
      </div>

      <div class="link-item" *ngFor="let link of links">
        <a href="{{ link.url }}" (click)="hideSidebar()">{{ link.name }}</a>
      </div>
    </div>

    <div class="user-wrapper">
      <ng-container *ngIf="!authService.isAuthenticated(); else loggedIn">
        <button type="button" class="btn btn-primary" (click)="openLoginDialog()">
          {{ 'tapp-order.shared.nav-bar.login' | translate }}
        </button>

        <div class="register-info">
          <span
            >{{ 'tapp-order.menu.register-text' | translate }}
            <a [routerLink]="['register']" (click)="hideSidebar()" class="primary-color">{{
              'tapp-order.pages.logged-in-user.components.login-modal.register' | translate
            }}</a></span
          >
        </div>
      </ng-container>
      <ng-template #loggedIn>
        <app-account-icon [showUser]="true" (click)="hideSidebar()"></app-account-icon>
      </ng-template>
    </div>

    <div class="menu-footer-wrapper">
      <div class="top-wrapper">
        <div class="socials-wrapper"></div>
        <div class="locale-switcher-wrapper">
          <app-select-lang></app-select-lang>
        </div>
      </div>

      <div class="menu-footer-menu">
        <div class="item" (click)="showTermsAndCoditions()">
          {{ 'tapp-order.pages.register.form.privacy-policy' | translate }}
        </div>
      </div>
      <div class="copyright"> Copyright © {{ currentYear }} LSI Software S.A. {{ 'app.footer' | translate }} </div>
    </div>
  </div>
</ng-template>

<app-dialog [(visible)]="termsVisible" [closable]="true" (onHide)="handleTermsHide()">
  <ng-template #header>
    <div class="dialog-terms__header">
      <span>{{ 'tapp-order.pages.register.form.privacy-policy' | translate }}</span>
    </div>
  </ng-template>

  <div class="dialog-terms__content">
    <div [innerHTML]="termsHtml"></div>
  </div>
</app-dialog>
