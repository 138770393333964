<app-mobile-navbar [customBackAction]="true" (onBackEvent)="cancelChanges()"></app-mobile-navbar>

<div class="modal-group">
  <div class="close-modal ui-clickable" (click)="cancelChanges()">
    <i class="ic ic-delete"></i>
  </div>

  <div class="modal-group-scroll-container">
    <div class="col-12 modal-header">
      {{ 'tapp-order.pages.product.common.change-ingredients' | translate }}
    </div>
    <app-product-modifiers #modifiersComponent [modifierGroups]="modifierGroups"> </app-product-modifiers>
  </div>

  <div class="modal-group-footer d-flex flex-column justify-content-end align-items-center">
    <div class="col-12 d-flex justify-content-end flex-column position-relative align-items-center buttons-wrapper">
      <button (click)="saveChanges()" type="button" class="button ui-clickable">
        {{ 'tapp-order.pages.product.common.save' | translate }}
      </button>

      <button (click)="cancelChanges()" type="button" class="button-outline cancel-button ui-clickable">
        {{ 'tapp-order.pages.product.common.cancel' | translate }}
      </button>
    </div>
  </div>
</div>
