<p-dialog
  #pDialog
  [visible]="display"
  [styleClass]="'discount-dialog-configurator'"
  [draggable]="false"
  [modal]="true"
  [resizable]="false"
  [showHeader]="true"
  (visibleChange)="tryToCloseDialog()"
  [position]="dialogPosition"
  [transitionOptions]="dialogTransitionOptions"
>
  <ng-template pTemplate="header">
    <app-mobile-navbar
      *ngIf="isMobile"
      [customBackAction]="true"
      (onBackEvent)="tryToCloseDialog()"
    ></app-mobile-navbar>

    <div class="dialog-header-content">
      <button
        pButton
        pRipple
        class="dialog-header-content__button-back p-button p-button-text {{
          appDiscountConfigurator?.activeIndex ? 'with-icon' : ''
        }}"
        type="button"
        [label]="'tapp-order.pages.discount.discount-dialog-configurator.title' | translate"
        [disabled]="!appDiscountConfigurator?.activeIndex"
        (click)="appDiscountConfigurator?.backToPreviousStep()"
      ></button>
      <h3 class="dialog-header-content__title">
        <span class="dialog-header-content__title__name">{{ discount.name }}</span>
        <app-tooltip-button
          *ngIf="appDiscountConfigurator?.activeIndex > 0"
          [visible]="appDiscountConfigurator?.activeIndex > 0"
          [hint]="'tapp-order.pages.discount.discount-dialog-configurator.hint' | translate"
          (onShow)="handleHintShow()"
          (onHide)="handleHintHide()"
        ></app-tooltip-button>
      </h3>

      <p class="dialog-header-content__description">
        {{ discount.description }}
      </p>

      <!-- Disalbed -->
      <p *ngIf="false" class="dialog-header-content__price">
        {{ '37.66' | currency: 'PLN':'zł':'1.2-2':'pl-PL' }}
      </p>

      <div *ngIf="loadingStatus == 'success'" class="dialog-header-content__stepper">
        <img
          class="dialog-header-content__stepper__image"
          src="{{ discount.imageUrl || '../../../../../assets/images/placeholder/discount/discount_placeholder.png' }}"
          alt=""
        />

        <p-overlayPanel #overplayPanel [styleClass]="'ingredients'">
          <ng-template pTemplate>
            <div *ngIf="selectedProduct" class="product-card">
              <h3 class="product-card__title">
                {{ 'tapp-order.pages.discount.discount-configurator.selected-product' | translate }}:
              </h3>
              <h3 class="product-card__name">{{ selectedProduct.name }}</h3>
              <p class="product-card__size">
                <ng-container *ngIf="getPizzaSize(selectedProduct)">
                  {{ 'tapp-order.pages.discount.discount-dialog-configurator.pizza-size' | translate }}:
                  {{ getPizzaSize(selectedProduct) }} |
                </ng-container>
                <ng-container *ngIf="getPizzaDough(selectedProduct)">
                  {{ 'tapp-order.pages.discount.discount-dialog-configurator.pizza-dough' | translate }}:
                  {{ getPizzaDough(selectedProduct) }}
                </ng-container>
              </p>
              <p class="product-card__ingredients">
                {{ selectedProduct.description | removeDots }}
              </p>
              <div class="product-card__additions">
                <span *ngFor="let addedModifier of selectedModifiers" class="addition addition--add"
                  >+ {{ addedModifier.name | removeDots }}</span
                >
                <span *ngFor="let removedModifier of removedModifiers" class="addition addition--remove"
                  >- {{ removedModifier.name | removeDots }}</span
                >
              </div>
            </div>
          </ng-template>
        </p-overlayPanel>
        <p-steps
          *ngIf="stepperItems && stepperItems.length > 0"
          #stepperRef
          [model]="stepperItems"
          [activeIndex]="appDiscountConfigurator?.activeIndex"
          [readonly]="true"
          [styleClass]="'dialog-header-steps' + (hintVisible ? ' dialog-header-steps--pulsing' : '')"
        ></p-steps>
      </div>
    </div>
  </ng-template>

  <ng-container *ngIf="isActive; else isDeactive">
    <app-discount-configurator
      #appDiscountConfigurator
      *ngIf="display && loadingStatus == 'success'"
      [discount]="discount"
      [display]="display"
      (onNextStep)="handleNextStep()"
      (onAddToBasket)="handleAddToBasket()"
      (onClose)="tryToCloseDialog()"
    ></app-discount-configurator>
  </ng-container>

  <div *ngIf="loadingStatus == 'pending'" class="loader"></div>

  <ng-template #isDeactive>
    <div
      style="
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: center;
        min-height: 40vh;
      "
    >
      <img src="/assets/icons/ic-promotions.svg" style="width: 46px; width: 46px; margin-bottom: 2rem" />
      <p style="font-style: normal; font-weight: 500; font-size: 18px">
        {{ 'tapp-order.pages.discount.discount-dialog-configurator.not-active-yet' | translate }}
      </p>

      <p style="font-style: normal; font-weight: 400; font-size: 14px">
        {{ 'tapp-order.pages.discount.discount-dialog-configurator.try-again-later' | translate }}
      </p>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <div class="footer"></div>
  </ng-template>
</p-dialog>
