import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ArticleCardExpandableComponent } from '@core/components/article-card-expandable/article-card-expandable.component';
import { ProductSizeButtonComponent } from '@core/pages/products/component/product-size-button/product-size-button.component';
import { HamburgerButtonComponent } from '@core/pages/products/hamburger-button/hamburger-button.component';
import { QueryParameterService } from '@core/services/query-parameter.service';
import { TranslateModule } from '@ngx-translate/core';
import {
  ControlErrorComponent,
  ControlErrorsContainerDirective,
  ControlErrorsDirective,
  FormSubmitDirective,
  InputDisableDirective,
} from '@shared/form-errors';
import { MobileNavbarComponent } from '@shared/nav-bar/mobile-navbar/mobile-navbar.component';
import { InvoicePreviewComponent } from '@shared/payment/invoice/invoice-preview/invoice-preview.component';
import { SummaryButtonGroupComponent } from '@shared/payment/summary-button-group/summary-button-group.component';
import { ButtonModule, CalendarModule, HintModule, IconModule } from '@shared/ui';
import { VerifyEmailModalComponent } from '@shared/verify-email-modal/verify-email-modal.component';
import { AddressAutocompleteComponent } from '@ui/address-finder/address-autocomplete/address-autocomplete.component';
import { AddressFinderComponent } from '@ui/address-finder/address-finder/address-finder.component';
import { DialogAddressFinderComponent } from '@ui/address-finder/dialog-address-finder/dialog-address-finder.component';
import { InputModule } from '@ui/input/input.module';
import { SidebarComponent } from '@ui/sidebar/sidebar.component';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { ConfirmationService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule as PrimeNgButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
import { StepsModule } from 'primeng/steps';
import { ToastModule } from 'primeng/toast';
import { MaterialModule } from '../modules/material/material.module';
import { AccountIconComponent } from './account-icon/account-icon.component';
import { ActivationSuccessfulModalComponent } from './activation-successful-modal/activation-successful-modal.component';
import { CardComponent } from './card/card.component';
import { CircleIconComponent } from './circle-icon/circle-icon.component';
import { DialogComponent } from './dialog/dialog.component';
import { DigitDirective } from './directives/digit-only.directive';
import { IframeTrackerDirective } from './directives/iframe-tracker.directive';
import { ImgDirective } from './directives/img.directive';
import { FooterComponent } from './footer/footer.component';
import { InputSwitchComponent } from './input-switch/input-switch.component';
import { IosInstallComponent } from './ios-install/ios-install.component';
import { LoadingComponent } from './loading/loading.component';
import { LoadingService } from './loading/loading.service';
import { LoginModalComponent } from './login-modal/login-modal.component';
import { AppleLoginButtonComponent } from './login-modal/social-media-buttons/apple-login-button/apple-login-button.component';
import { FacebookLoginButtonComponent } from './login-modal/social-media-buttons/facebook-login-button/facebook-login-button.component';
import { GoogleLoginButtonComponent } from './login-modal/social-media-buttons/google-login-button/google-login-button.component';
import { InstagramLoginButtonComponent } from './login-modal/social-media-buttons/instagram-login-button/instagram-login-button.component';
import { LogoComponent } from './logo/logo.component';
import { MessageComponent } from './message/message.component';
import { MessageService } from './message/service/message.service';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { ScannerNoAccessComponent } from './notification-components/scanner-no-access/scanner-no-access.component';
import { PasswordChangeModalComponent } from './password-change-modal/password-change-modal.component';
import { ApproveComponent } from './payment/approve/approve.component';
import { CreditCardComponent } from './payment/credit-card/credit-card.component';
import { DeliveryAddressComponent } from './payment/delivery-address/delivery-address.component';
import { HeaderComponent } from './payment/header/header.component';
import { InvoiceFormComponent } from './payment/invoice/invoice-form/invoice-form.component';
import { InvoiceComponent } from './payment/invoice/invoice.component';
import { PaymentDeliveryComponent } from './payment/payment-delivery/payment-delivery.component';
import { PaymentMethodComponent } from './payment/payment-method/payment-method.component';
import { DateTimePipe } from './pipes/date-time.pipe';
import { FormatTimePipe } from './pipes/format-time.pipe';
import { MoneyPipe } from './pipes/money.pipe';
import { QuantityPipe } from './pipes/quantity.pipe';
import { RemoveDotsPipe } from './pipes/remove-dots.pipe';
import { ReplacePipe } from './pipes/replace.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { TranslateSelectorPipe } from './pipes/translate-selector.pipe';
import { RedirectToParentRouteComponent } from './redirect-to-parent-route/redirect-to-parent-route.component';
import { RegisterMessageNavBarComponent } from './register-message-nav-bar/register-message-nav-bar.component';
import { ResetPasswordModalComponent } from './reset-password-modal/reset-password-modal.component';
import { SurveyModalComponent } from './survey-modal/survey-modal.component';
import { TooltipButtonComponent } from './tooltip-button/tooltip-button.component';
import { SurveySuccessModalComponent } from '@shared/survey-modal/component/survey-success-modal.component';
import { UnexpectedIssueDialogComponent } from './unexpected-issue-dialog/unexpected-issue-dialog.component';
import { AppSelectLangComponent } from '@ui/select-lang/select-lang.component';

const PAYMENT_COMPONENTS = [PaymentDeliveryComponent];

const PRIMENG_MODULES = [
  DropdownModule,
  DialogModule,
  DividerModule,
  StepsModule,
  ToastModule,
  PrimeNgButtonModule,
  AutoCompleteModule,
];

const UI_MODULES = [CalendarModule, ButtonModule, IconModule, HintModule];

@NgModule({
  declarations: [
    ControlErrorComponent,
    ControlErrorsContainerDirective,
    ControlErrorsDirective,
    FormSubmitDirective,
    InputDisableDirective,
    IosInstallComponent,
    LoadingComponent,
    LogoComponent,
    MoneyPipe,
    QuantityPipe,
    ImgDirective,
    DateTimePipe,
    FormatTimePipe,
    ScannerNoAccessComponent,
    TranslateSelectorPipe,
    SafePipe,
    IframeTrackerDirective,
    MessageComponent,
    DigitDirective,
    ReplacePipe,
    NavBarComponent,
    DeliveryAddressComponent,
    ApproveComponent,
    InvoiceComponent,
    PaymentMethodComponent,
    HeaderComponent,
    SummaryButtonGroupComponent,
    CircleIconComponent,
    SidebarComponent,
    ArticleCardExpandableComponent,
    FooterComponent,
    PAYMENT_COMPONENTS,
    ProductSizeButtonComponent,
    CreditCardComponent,
    InvoiceFormComponent,
    LoginModalComponent,
    VerifyEmailModalComponent,
    ActivationSuccessfulModalComponent,
    AccountIconComponent,
    InvoicePreviewComponent,
    RemoveDotsPipe,
    TooltipButtonComponent,
    ResetPasswordModalComponent,
    PasswordChangeModalComponent,
    FacebookLoginButtonComponent,
    GoogleLoginButtonComponent,
    AppleLoginButtonComponent,
    InstagramLoginButtonComponent,
    RegisterMessageNavBarComponent,
    MobileNavbarComponent,
    HamburgerButtonComponent,
    InputSwitchComponent,
    RedirectToParentRouteComponent,
    CardComponent,
    AddressAutocompleteComponent,
    AddressFinderComponent,
    DialogAddressFinderComponent,
    DialogComponent,
    SurveyModalComponent,
    SurveySuccessModalComponent,
    UnexpectedIssueDialogComponent,
    AppSelectLangComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    TranslateModule.forRoot(),
    RouterModule,
    RadioButtonModule,
    InputSwitchModule,
    InputNumberModule,
    InputTextModule,
    InputTextareaModule,
    NgxTrimDirectiveModule,
    CardModule,
    CheckboxModule,
    UI_MODULES,
    PRIMENG_MODULES,
    InputModule,
    RippleModule,
    OverlayPanelModule,
    ConfirmDialogModule,
  ],
  exports: [
    ArticleCardExpandableComponent,
    ControlErrorComponent,
    ControlErrorsContainerDirective,
    ControlErrorsDirective,
    FormSubmitDirective,
    InputDisableDirective,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    IosInstallComponent,
    LoadingComponent,
    LogoComponent,
    MoneyPipe,
    QuantityPipe,
    ImgDirective,
    DateTimePipe,
    FormatTimePipe,
    TranslateSelectorPipe,
    SafePipe,
    IframeTrackerDirective,
    MessageComponent,
    DigitDirective,
    ReplacePipe,
    HeaderComponent,
    DeliveryAddressComponent,
    ApproveComponent,
    InvoiceComponent,
    PaymentMethodComponent,
    CircleIconComponent,
    SummaryButtonGroupComponent,
    PRIMENG_MODULES,
    SidebarComponent,
    NavBarComponent,
    FooterComponent,
    PaymentDeliveryComponent,
    PAYMENT_COMPONENTS,
    ProductSizeButtonComponent,
    AccountIconComponent,
    InvoiceFormComponent,
    InvoicePreviewComponent,
    RemoveDotsPipe,
    RippleModule,
    OverlayPanelModule,
    TooltipButtonComponent,
    MobileNavbarComponent,
    HamburgerButtonComponent,
    ConfirmDialogModule,
    InputSwitchComponent,
    RedirectToParentRouteComponent,
    CardComponent,
    AddressAutocompleteComponent,
    AddressFinderComponent,
    DialogAddressFinderComponent,
    DialogComponent,
    UnexpectedIssueDialogComponent,
    AppSelectLangComponent,
  ],
  providers: [LoadingService, MessageService, QueryParameterService, ConfirmationService],
  entryComponents: [IosInstallComponent],
})
export class SharedModule {}
