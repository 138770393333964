<div class="register-container">
  <div class="register-header row text-center">
    <div class="col">
      <span>{{ 'tapp-order.pages.register.header' | translate }}</span>
    </div>
  </div>
  <form (submit)="onSubmit()" [formGroup]="registerForm" class="register-form">
    <div class="row">
      <div class="col">
        <div class="form-group">
          <input
            type="text"
            formControlName="name"
            pInputText
            appInput
            id="name"
            required="true"
            [placeholder]="'tapp-order.pages.register.form.name-placeholder' | translate"
          />
          <label for="name"
            >{{ 'tapp-order.pages.register.form.name' | translate }} <span class="required">*</span></label
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="form-group">
          <input
            type="text"
            formControlName="email"
            appInput
            pInputText
            required="true"
            id="email"
            placeholder="{{ 'tapp-order.pages.register.form.email-placeholder' | translate }}"
          />
          <label for="email"
            >{{ 'tapp-order.pages.register.form.email' | translate }} <span class="required">*</span></label
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="form-group phone">
          <input
            formControlName="phoneNumber"
            type="text"
            appInput
            pInputText
            required="true"
            id="phone"
            placeholder="{{ 'tapp-order.pages.register.form.phone-placeholder' | translate }}"
          />
          <label for="phone"
            >{{ 'tapp-order.pages.register.form.phone' | translate }} <span class="required">*</span></label
          >
          <p class="input-label-info">{{ 'tapp-order.pages.register.form.phone-number-information' | translate }}</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="form-group password">
          <input
            formControlName="password"
            type="password"
            appInput
            pInputText
            id="password"
            required="true"
            #password
            placeholder="{{ 'tapp-order.pages.register.form.password-placeholder' | translate }}"
          />
          <label for="password"
            >{{ 'tapp-order.pages.register.form.password' | translate }} <span class="required">*</span></label
          >
          <img
            class="show-password ui-clickable"
            [src]="
              password.type == 'password'
                ? '../../../../../../assets/icons/show-password.png'
                : '../../../../../../assets/icons/show-password-1.png'
            "
            (click)="password.type = password.type == 'password' ? 'text' : 'password'"
          />
          <p
            *ngIf="registerForm.controls['password'].errors && registerForm.controls['password'].touched"
            class="input-label-error"
            >{{ 'tapp-order.pages.register.form.password-information' | translate }} <span class="required">*</span></p
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <ng-container
          *ngFor="let child of termsAndConditionsControl.controls; let i = index"
          formArrayName="termsAndConditions"
        >
          <div class="form-group terms-and-conditions" *ngIf="child.value.required" formGroupName="{{ i }}">
            <div class="d-flex align-items-start">
              <p-checkbox
                [binary]="true"
                formControlName="selected"
                [ngClass]="'checkbox'"
                [required]="true"
                [inputId]="'checkbox' + child.value.id"
              ></p-checkbox>
              <label
                for="{{ 'checkbox' + child.value.id }}"
                class="p-checkbox-label"
                [ngClass]="{ 'required-checkbox-label': child.value.required }"
                [innerHTML]="child.value.content"
              ></label>
            </div>
          </div>
        </ng-container>

        <div class="row mt-1">
          <div class="col-12">
            <small class="clickable-text" (click)="showTermsAndCoditions()">
              {{ 'tapp-order.pages.register.form.privacy-policy' | translate }}
            </small>
          </div>
        </div>

        <div class="divider divider-terms"></div>

        <ng-container
          *ngFor="let child of termsAndConditionsControl.controls; let i = index"
          formArrayName="termsAndConditions"
        >
          <div class="form-group terms-and-conditions" *ngIf="!child.value.required" formGroupName="{{ i }}">
            <div class="d-flex align-items-start">
              <p-checkbox
                [binary]="true"
                formControlName="selected"
                [required]="true"
                [ngClass]="'checkbox'"
                [inputId]="'checkbox' + child.value.id"
              ></p-checkbox>
              <label
                for="{{ 'checkbox' + child.value.id }}"
                class="p-checkbox-label"
                [ngClass]="{ 'required-checkbox-label': child.value.required }"
                [innerHTML]="child.value.content"
              ></label>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="row mt-3" *ngIf="showError">
      <div class="error col text-center">
        <div
          ><span>{{ errorMessage }}</span></div
        >
      </div>
    </div>
    <div class="d-flex justify-content-center mt-5">
      <button type="submit" class="btn btn-primary">
        <span *ngIf="!registerLoading; else spinner">{{ 'tapp-order.pages.register.form.button' | translate }}</span>
        <ng-template #spinner>
          <span class="loader"></span>
        </ng-template>
      </button>
    </div>
    <div class="divider divider-footer"></div>
  </form>
  <div class="register-footer row">
    <div class="col d-flex justify-content-center">
      <span class="mr-1">{{ 'tapp-order.pages.register.message1' | translate }}</span>
      <a (click)="openDialog()" href="" class="primary-color">{{ 'tapp-order.pages.register.message2' | translate }}</a>
    </div>
  </div>
</div>

<app-dialog [(visible)]="termsVisible" [closable]="true" (onHide)="handleTermsHide()">
  <ng-template #header>
    <div class="dialog-terms__header">
      <span>{{ 'tapp-order.pages.register.form.privacy-policy' | translate }}</span>
    </div>
  </ng-template>

  <div class="dialog-terms__content">
    <div [innerHTML]="html"></div>
  </div>
</app-dialog>
