<div class="payment-component payment-invoice-component">
  <div class="row">
    <div class="col-12 col-md-6">
      <div class="row">
        <div class="col">
          <span class="section-title">
            {{ 'tapp-order.payment.invoice.mainTitle' | translate }}
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="invoice-switch-wrapper" [ngClass]="paymentFormService.paymentInvoiceRejected ? 'border-red' : ''">
            <span class="label">{{ 'tapp-order.payment.invoice.title' | translate }}</span>
            <app-input-switch
              [ngClass]="'switch'"
              [(checked)]="checked"
              (checkedChange)="changeInvoiceSwitch($event)"
            ></app-input-switch>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col" *ngIf="checked && displayInvoiceForm === false">
          <app-invoice-preview (openInvoiceFormEmitter)="showInvoiceForm()"></app-invoice-preview>
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog
  [(visible)]="displayInvoiceForm"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  [showHeader]="false"
  (onHide)="onCloseDialog()"
>
  <app-invoice-form #invoiceForm (closeDialogEmmiter)="onCloseDialog()"></app-invoice-form>
</p-dialog>
