import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product-pizza-card',
  templateUrl: './product-pizza-card.component.html',
  styleUrls: ['./product-pizza-card.component.scss'],
})
export class ProductPizzaCardComponent implements OnInit {
  public displayDialog: boolean = false;

  @Input() product;
  @Input() isOpen: boolean = true;
  @Input() discountMode: boolean = false;
  @Input() summaryMode: boolean = false;
  @Input() conditionSizeIds = [];
  @Input() conditionDoughIds: (number | string)[] = [];

  @Output() selectProductEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(private router: Router) {}

  ngOnInit(): void {}

  public redirectToModify() {
    if (this.discountMode) {
      if (this.product.productType == 'pizza' || this.product.productType == 'composed') {
        this.displayDialog = true;
      } else {
        this.selectProduct(this.product);
      }
    } else {
      const placeId = sessionStorage.getItem('placeId');

      if (!placeId) {
        console.error('#NSDFOJ82FHJHD: placeId is not defined');
        this.router.navigate(['/']);
        return;
      }

      this.router.navigate([placeId, 'products', this.product.publicId]);
    }
  }

  selectProduct($event) {
    this.selectProductEvent.emit($event);
  }

  getPizzaSize(item: any): string {
    let toReturn = null;
    item.SelectedModifiers.forEach((selectedModifier) => {
      if (selectedModifier.modifierType === 'PIZZA_SIZE' && !item.hidePizzaSize) {
        toReturn = selectedModifier.name;
      }
    });
    return toReturn;
  }

  getPizzaDough(item: any): string {
    let toReturn = null;
    item.SelectedModifiers.forEach((selectedModifier) => {
      if (selectedModifier.modifierType === 'PIZZA_DOUGH' && !item.hidePizzaDough) {
        toReturn = selectedModifier.name;
      }
    });
    return toReturn;
  }

  getSelectedModifiers(item: any): any {
    const filteredTypesArray = ['PIZZA_DOUGH', 'PIZZA_SIZE', 'PIZZA_FREE_INGREDIENT'];
    const toReturn = item.SelectedModifiers.filter((selecteModifier) => {
      return filteredTypesArray.indexOf(selecteModifier.modifierType) === -1 ? true : false;
    });
    return toReturn;
  }

  getRemovedModifiers(item: any): any {
    return item.unselectedModifiers;
  }
}
