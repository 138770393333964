<div class="payment-summary-button-group-component">
  <button
    *ngIf="getOrderType() !== orderTypeEnum.delivery || basketService.allowDisplaySubmitBtn(); else minOrderInfo"
    [ngClass]="{
      disabled: !isOrderButtonActive
    }"
    class="d-flex justify-content-between align-items-center btn-reset btn-basket"
    (click)="submit()"
  >
    <i class="ic ic-basket basket"></i>
    <span class="label font-weight-bold mr-auto">{{
      'tapp-order.shared.payment.summary-button-group.label' | translate
    }}</span>
    <span class="amount"> {{ basketService.finalPrice$ | async | currency: 'PLN':'zł':'1.2-2':'pl-PL' }}</span>
  </button>

  <ng-template #minOrderInfo>
    <div *ngIf="calculateMinOrderPrice() > 0" class="min-order-info">
      <span>{{ 'tapp-order.shared.payment.summary-button-group.minorderinfo' | translate }}</span>
      <span class="min-order-inof-price"> {{ calculateMinOrderPrice() | currency: 'PLN':'zł':'1.2-2':'pl-PL' }}</span>
    </div>
  </ng-template>
</div>
