<div class="modal-group">
  <div class="modal-group-scroll-container">
    <div class="modal-group-header">
      <div class="modal-close-btn ui-clickable" (click)="closeModal()">
        <i class="ic ic-close"></i>
      </div>
      <div class="modal-group-header-title"> {{ 'tapp-order.allergensAndNutritionalValues.filter' | translate }} </div>
    </div>

    <div class="filters-wrapper" *ngIf="attributes.length > 0">
      <div class="filters-header">
        <div class="filters-title">{{ 'tapp-order.allergensAndNutritionalValues.attributes' | translate }}</div>
      </div>

      <div class="filters-container">
        <ng-container *ngFor="let attribute of attributes">
          <div class="filter-item">
            <app-input-switch [(checked)]="attribute.checked"></app-input-switch>

            <span class="switch-label ui-clickable" (click)="attribute.checked = !attribute.checked">
              <img class="icon" *ngIf="attribute.icon?.url" src="{{ attribute.icon?.url }}" alt="" />
              <img
                class="icon"
                *ngIf="!attribute.icon?.url && attribute.iconName"
                src="/assets/icons/attr-icon-{{ attribute.iconName }}.svg"
                alt=""
              />
              {{ attribute.name }}
            </span>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="filters-wrapper" *ngIf="allergens.length > 0">
      <div class="filters-header">
        <div class="filters-title">{{ 'tapp-order.allergensAndNutritionalValues.allergens' | translate }}</div>
        <div class="filters-description">{{
          'tapp-order.allergensAndNutritionalValues.allergensFilterDesc' | translate
        }}</div>
      </div>

      <div class="filters-container">
        <ng-container *ngFor="let allergen of allergens">
          <div class="filter-item">
            <mat-checkbox [(ngModel)]="allergen.checked">{{ allergen.name }}</mat-checkbox>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="filters-wrapper" *ngIf="calories">
      <div class="filters-header">
        <div class="filters-title">{{ 'tapp-order.allergensAndNutritionalValues.calories' | translate }}</div>
        <div class="filters-description">{{ 'tapp-order.allergensAndNutritionalValues.calories' | translate }}</div>
      </div>

      <div class="range-slider-container">
        <div class="value-unit-label">
          {{ calories.unitName }}
        </div>

        <p-slider
          [(ngModel)]="calories.valuesRange"
          [min]="calories.minValue"
          [max]="calories.maxValue"
          [step]="1"
          [range]="true"
        ></p-slider>
        <div class="thumb-labels">
          <div class="thumb-label" [style.left.%]="calculateRangeLabelLeftPosition(calories.valuesRange[0])">{{
            calories.valuesRange[0]
          }}</div>
          <div class="thumb-label" [style.left.%]="calculateRangeLabelLeftPosition(calories.valuesRange[1])">{{
            calories.valuesRange[1]
          }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-group-footer d-flex flex-column justify-content-end">
    <div class="col-12 d-flex justify-content-end flex-column position-relative">
      <button (click)="resetFilters()" type="button" class="reset-button ui-clickable" *ngIf="showResetBtn">
        {{ 'tapp-order.allergensAndNutritionalValues.reset' | translate }}
      </button>

      <button (click)="submit()" type="button" class="button ui-clickable">
        {{ 'tapp-order.allergensAndNutritionalValues.filter' | translate }}
      </button>

      <button (click)="cancel()" type="button" class="button-outline ui-clickable">
        {{ 'tapp-order.allergensAndNutritionalValues.cancel' | translate }}
      </button>
    </div>
  </div>
</div>
