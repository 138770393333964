import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderDataProvider } from '@core/data-providers/order.data-provider';
import { paymentStatusTypes } from '@core/pages/payment/payment-status-type.model';
import { ButtonBackService } from '@core/services/button-back.service';
import { OrderService } from '@core/services/order.service';
import { OrderStateService } from '@core/services/order.state.service';
import { PaymentService } from '@core/services/payment.service';
import { LoadingService } from '@shared/loading/loading.service';
import { Subscription } from 'rxjs';
import { BasketService } from 'src/app/services/basket.service';
import { ThemeService } from '../../../services/theme.service';

@Component({
  selector: 'app-payment-page',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentPageComponent implements OnInit, OnDestroy {
  public status: string = '_';
  private subscription: Subscription;
  public paymentStatusTypes = paymentStatusTypes;
  public deliveryAddressTrigger: boolean;

  public paymentMethodType: string;
  public paymentMethodValue: string;

  public customerName: string;
  public customerEmail: string;
  public customerPhone: string;
  public customerNotes: string;
  public deliveryAddressCity: string;
  public deliveryAddressZipCode: string;
  public deliveryAddressStreet: string;
  public deliveryAddressBuildingNo: string;
  public deliveryAddressLocalNo: string;
  public isTermsAndConditionsAccepted: boolean = false;
  public formRejected: boolean = false;

  constructor(
    private router: Router,
    public basketService: BasketService,
    private orderStateService: OrderStateService,
    private orderDataProvider: OrderDataProvider,
    private route: ActivatedRoute,
    private paymentService: PaymentService,
    public orderService: OrderService,
    private loadingService: LoadingService,
    private themeService: ThemeService,
    private buttonBackService: ButtonBackService,
  ) {
    this.deliveryAddressTrigger = this.paymentService.getDeliveryAddressStatus();
  }

  ngOnInit(): void {
    this.themeService.desktopHeaderAdditionalClass = 'sidebar-margin-2';

    if (this.basketService.basketItems.length === 0) {
      this.router.navigate([sessionStorage.getItem('placeId') ?? '/']);
    }
    this.themeService.mobileNavbarShowBackButton = true;
    this.buttonBackService.redirectUrl = ['order'];
    this.loadingService.showLoader();
    this.status = this.route.snapshot.params.status;
    localStorage.removeItem('paymentMethod');
    localStorage.removeItem('paymentMethodValue');
    localStorage.removeItem('paymentMethodType');
    this.subscription = this.orderStateService.state$.subscribe((state) => {
      if (state && state.readyForUse) {
        if (this.status === paymentStatusTypes.cancel) {
          if (state.place && state.order) {
            this.orderDataProvider.delete(state.place.publicId, state.order.id).subscribe(() => {
              this.orderStateService.cleanState();
            });
          }
        }
        this.orderStateService.cleanState();
      }
    });
    this.loadingService.hideLoader();
  }

  ngOnDestroy(): void {
    this.themeService.desktopHeaderAdditionalClass = '';
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  get deliveryAddressStatus(): boolean {
    return this.paymentService.getDeliveryAddressStatus();
  }

  get invoiceStatus(): boolean {
    return this.paymentService.getInvoiceStatus();
  }

  get deliveryAddressLabel(): string {
    return this.paymentService.getDeliveryAddressLabel();
  }

  setPaymentMethodType(paymentType: string): void {
    this.paymentMethodType = paymentType;
  }

  setPaymentMethodValue(paymentValue: string): void {
    this.paymentMethodValue = paymentValue;
  }
}
