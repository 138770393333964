import { AfterContentInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BottomNotificationComponent } from '@core/components/bottom-notification/bottom-notification.component';
import { BaseDataProvider } from '@core/data-providers/base.data-provider';
import { MenuDataProvider } from '@core/data-providers/menu.data-provider';
import { CanComponentDeactivate } from '@core/interfaces/interfaces';
import { WorkingHoursApiModel } from '@core/models/tapp-order/api-model/working-hours/working-hours.api.model';
import { HolidayViewModel } from '@core/models/tapp-order/view-model/holiday/holiday.view.model';
import { MenuViewModel } from '@core/models/tapp-order/view-model/menu/menu.view.model';
import { PlaceViewModel } from '@core/models/tapp-order/view-model/place/place.view.model';
import { ProductViewModel } from '@core/models/tapp-order/view-model/product/product.view.model';
import { OrderService } from '@core/services/order.service';
import { TranslateService } from '@ngx-translate/core';
import { MenuTypeEnum } from '@shared/enum/menu-type.enum';
import { ParamKeys } from '@shared/enum/param-kyes.enum';
import { LoadingService } from '@shared/loading/loading.service';
import { SidebarComponent } from '@ui/sidebar/sidebar.component';
import * as _ from 'lodash';
import { orderBy } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BasketService } from 'src/app/services/basket.service';
import { PlaceService } from 'src/app/services/place.service';
import { ThemeService } from 'src/app/services/theme.service';
import { DiscountDialogConfiguratorComponent } from './component/discount-dialog-configurator/discount-dialog-configurator.component';
import { DiscountDataProvider } from './data-provider/discount.data-provider';
import { ConditionTypeEnum } from './enum/condition/condition-type.enum';
import { DiscountViewModel } from './model/discount.view.model';

@Component({
  selector: 'app-discounts',
  templateUrl: './discounts.component.html',
  styleUrls: ['./discounts.component.scss'],
})
export class DiscountsComponent implements OnInit, AfterContentInit, OnDestroy, CanComponentDeactivate {
  private isOrderPage: boolean = false;

  private destroyed$ = new Subject<void>();

  public isOpen = true;
  public place: PlaceViewModel;
  public nextWorkingHours: WorkingHoursApiModel = null;
  public menu: MenuViewModel | null = null;
  public discounts: DiscountViewModel[] = [];
  public productsCrossSell: ProductViewModel[] = [];
  public displayCrossSellDialog: boolean = false;
  public bottomButtonCenterText: string = '';
  public bottomButtonRightText: string = '';
  public placeString: string;
  public workingHours: WorkingHoursApiModel[];
  public placeIsPreviewOnly: boolean;
  public hasMenu: boolean = null;
  public filteredMenu: MenuViewModel = null;
  public isLoaded: boolean = false;
  public qrMenuMode: boolean = false;
  public displayDialog: boolean = false;

  @ViewChild('appDiscountDialogConfigurator')
  private readonly appDiscountDialogConfigurator?: DiscountDialogConfiguratorComponent;

  @ViewChild(SidebarComponent) public sidebarComponent;

  constructor(
    private baseDataProvider: BaseDataProvider,
    private menuDataProvider: MenuDataProvider,
    private discountDataProvider: DiscountDataProvider,
    private loadingService: LoadingService,
    private placeService: PlaceService,
    private router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private themeService: ThemeService,
    public bottomNotificationComponent: BottomNotificationComponent,
    public orderService: OrderService,
    public basketService: BasketService,
  ) {}

  ngOnInit(): void {
    this.themeService.desktopHeaderAdditionalClass = 'sidebar-margin-1';
    this.route.data.pipe(takeUntil(this.destroyed$)).subscribe((data) => {
      if (data?.qrmenu === true) {
        this.qrMenuMode = true;
      } else {
        this.qrMenuMode = false;
      }

      sessionStorage.setItem('qrMenuMode', this.qrMenuMode.toString());

      this.setLocalPublicId();
    });
  }

  ngAfterContentInit() {
    this.loadingService.hideLoader();
  }

  ngOnDestroy(): void {
    this.themeService.desktopHeaderAdditionalClass = '';
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  canDeactivate(): boolean | Promise<boolean> {
    return this.appDiscountDialogConfigurator?.canDeactivate() ?? false;
  }

  private fetchPlace(placeId: string) {
    this.loadingService.showLoader();

    this.placeService
      .useAsObservable(placeId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (place: PlaceViewModel) => {
          this.place = place;

          this.baseDataProvider
            .getHolidaysList()
            .pipe(takeUntil(this.destroyed$))
            .subscribe((holidays: HolidayViewModel[]) => {
              this.getDiscountList();
              this.isOpen = this.orderService.isPlaceOpen(place, holidays);
              this.nextWorkingHours = this.orderService.getNextWorkingHoursData(place, holidays);

              if (place.menus.length > 0 && place.getMenuByType(this.orderService.getMenuType())) {
                const menuId = place.getMenuByType(
                  this.qrMenuMode ? MenuTypeEnum.ONSITE : this.orderService.getMenuType(),
                ).publicId;

                this.menuDataProvider
                  .getMenu(menuId)
                  .pipe(takeUntil(this.destroyed$))
                  .subscribe((menu) => {
                    this.menu = _.cloneDeep(menu);

                    if (this.menu) {
                      this.getProductsCrossSell(this.menu.publicId);
                    }
                  });
              } else {
                this.menu = null;
              }
            });
        },
        error: () => {
          this.loadingService.hideLoader();
          this.router.navigate(['/']);
        },
      });
  }

  public handleAddDiscount(discount: DiscountViewModel): void {
    this.appDiscountDialogConfigurator?.openDialog(discount);
  }

  public generateButtonText(): string[] {
    this.bottomButtonCenterText =
      this.translateService.instant('tapp-order.order-button-text') +
      ' ' +
      this.basketService.basketItems.length +
      ' ' +
      this.translateService.instant('tapp-order.order-button-text-items');
    this.bottomButtonRightText = this.basketService.calculatePrice().toFixed(2);
    this.bottomButtonCenterText = this.translateService.instant('tapp-order.order-button-constant-text');
    return [this.bottomButtonCenterText, this.bottomButtonRightText];
  }

  public navigateToPayment() {
    if (this.productsCrossSell.length > 0) {
      this.showCrossSellProducts(true);
    } else {
      this.router.navigate(['/order']);
    }
  }

  private setLocalPublicId() {
    this.route.params.pipe(takeUntil(this.destroyed$)).subscribe((params) => {
      const placeId = params[ParamKeys.PlaceId];

      if (sessionStorage.getItem('placeId') && placeId != sessionStorage.getItem('placeId')) {
        this.basketService.removeBasket();

        this.orderService.removeDeliveryZoneId();
        this.orderService.removeDeliveryAddressCity();
        this.orderService.removeDeliveryAddressZipCode();
        this.orderService.removeDeliveryAddressStreet();
        this.orderService.removeDeliveryAddressBuildingNo();
        this.orderService.removeDeliveryAddressLocalNo();
      }

      this.init(placeId);
      this.fetchPlace(placeId);
    });
  }

  private init(placeId: string): void {
    this.loadingService.showLoader();
    this.themeService.mobileNavbarShowBackButton = false;

    this.placeService
      .useAsObservable(placeId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (place: PlaceViewModel) => {
          this.place = place;

          this.baseDataProvider
            .getHolidaysList()
            .pipe(takeUntil(this.destroyed$))
            .subscribe(
              (holidays: HolidayViewModel[]) => {
                this.placeString = `${place.city}, ${place.street} ${place.buildingNo}`;
                place.localNo ? (this.placeString += `/${place.localNo}`) : null;

                this.workingHours = place.workingHours;
                this.isOpen = this.orderService.isPlaceOpen(place, holidays, this.qrMenuMode);
                this.nextWorkingHours = this.orderService.getNextWorkingHoursData(place, holidays, this.qrMenuMode);
                this.managePreviewOnly();

                if (place.menus.length > 0) {
                  const menuId = place.getMenuByType(
                    this.qrMenuMode ? MenuTypeEnum.ONSITE : this.orderService.getMenuType(),
                  ).publicId;

                  this.hasMenu = true;
                  this.placeIsPreviewOnly = place.isPreviewOnly;
                  this.managePreviewOnly();
                  this.menuDataProvider
                    .getMenu(menuId)
                    .pipe(takeUntil(this.destroyed$))
                    .subscribe((menu) => {
                      this.menu = _.cloneDeep(menu);
                      this.filteredMenu = _.cloneDeep(menu);
                      this.isLoaded = true;

                      if (this.menu) {
                        this.getProductsCrossSell(this.menu.publicId);
                      }
                    });
                } else {
                  this.hasMenu = false;
                }

                this.loadingService.hideLoader();
              },
              () => {
                this.loadingService.hideLoader();
              },
            );
        },
        error: () => {
          this.loadingService.hideLoader();
          this.router.navigate(['/']);
        },
      });
  }

  public managePreviewOnly() {
    if (!this.isOpen) {
      this.placeIsPreviewOnly = false;
    }
  }

  private getDiscountList() {
    this.discountDataProvider
      .findAll(this.qrMenuMode ? MenuTypeEnum.ONSITE : this.orderService.getMenuType())
      .pipe(takeUntil(this.destroyed$))
      .subscribe((result) => {
        result.forEach((discount) => {
          if (this.checkIsProductOrGroupProduct(discount)) {
            this.discounts.push(discount);
          }
        });

        this.sortDiscountsByName();
        this.sortProductsByName();
      });
  }

  private sortDiscountsByName() {
    this.discounts = orderBy(this.discounts, ['name'], ['asc']);
  }

  private sortProductsByName() {
    this.discounts.forEach((discount) => {
      discount.conditions.forEach((condition) => {
        if (condition.products.length > 0) {
          condition.products = orderBy(condition.products, ['name'], ['asc']);
        }
        if (condition.groups.length > 0) {
          condition.groups.forEach((group) => {
            if (group.products.length > 0) {
              group.products = orderBy(group.products, ['name'], ['asc']);
            }
          });
        }
      });
    });
  }

  private checkIsProductOrGroupProduct(discount) {
    let result = false;
    discount.conditions.forEach((condition) => {
      if (condition.type === ConditionTypeEnum.product) {
        result = true;
      }
      if (condition.type === ConditionTypeEnum.productGroup) {
        result = true;
      }
    });
    return result;
  }

  private getProductsCrossSell(menuPublicId: string) {
    this.baseDataProvider
      .getProductsCrossSell(menuPublicId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((products) => {
        this.productsCrossSell = products;
      });
  }

  public showCrossSellProducts(isOrderPage: boolean = false) {
    this.isOrderPage = isOrderPage;
    this.displayCrossSellDialog = true;
  }

  public handleAddProductToBasket(product: ProductViewModel) {
    if (product.hasModifiers) {
      this.redirectToNewProduct(product);
    } else {
      this.addProductToBasket(product);
    }
  }

  public closeCrossSellProducts() {
    this.isOrderPage ? this.router.navigate(['/order']) : this.sidebarComponent.createOrder();
  }

  private redirectToNewProduct(product: ProductViewModel) {
    const placeId = this.route.snapshot.params[ParamKeys.PlaceId];

    if (!placeId) {
      console.error('#NSDFOJ82FHJH2: placeId is not defined');
      this.router.navigate(['/']);
      return;
    }

    this.router.navigate([placeId, 'products', product.publicId]);
  }

  private addProductToBasket(product: ProductViewModel) {
    product.CurrentQuantity = 1;
    this.basketService.add(product, []);

    this.bottomNotificationComponent.show(
      this.translateService.instant('tapp-order.article-card-success-notification-text-constant'),
      1500,
    );
  }

  public changeLocal() {
    this.displayDialog = true;
  }

  public closeDialog() {
    this.displayDialog = false;
  }
}
