<div class="account-container" *ngIf="showUser && userName; else iconOnly">
  <div class="account-icon-container ui-clickable" [routerLink]="['user']">
    {{ userInitials }}
  </div>

  {{ userName }}
</div>

<ng-template #iconOnly>
  <div class="account-icon-container ui-clickable" [routerLink]="['user']">
    <i class="ic ic-user"></i>
  </div>
</ng-template>
