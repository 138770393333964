<div class="modal-group">
  <div class="modal-group-scroll-container">
    <div class="modal-group-header">
      <div class="modal-close-btn ui-clickable" (click)="close()">
        <i class="ic ic-close"></i>
      </div>
      <div class="modal-group-header-title"> {{ 'tapp-order.allergensAndNutritionalValues.title' | translate }} </div>
    </div>

    <div class="attributes" *ngIf="attributes.length > 0">
      <div class="attribute" *ngFor="let attribute of attributes">
        <div class="attribute-wrapper">
          <img class="icon" *ngIf="attribute.icon?.url" src="{{ attribute.icon?.url }}" alt="" />
          <img
            class="icon"
            *ngIf="!attribute.icon?.url && attribute.iconName"
            src="/assets/icons/attr-icon-{{ attribute.iconName }}.svg"
            alt=""
          />
          <span>- {{ attribute.name }}</span></div
        >
      </div>
    </div>

    <div class="nutritions" *ngIf="nutritionValues">
      <div class="items-title">{{ 'tapp-order.allergensAndNutritionalValues.nutritionalValues' | translate }}</div>
      <div class="item" *ngFor="let nutritionValue of nutritionValues">
        <div class="item-name">{{ nutritionValue.nutrition.name }}</div>
        <div class="item-value">{{ nutritionValue.value }} {{ nutritionValue.nutrition.unitName }}</div>
      </div>
    </div>

    <div class="allergens" *ngIf="allergens">
      <div class="items-title">{{ 'tapp-order.allergensAndNutritionalValues.allergens' | translate }}</div>
      <div class="item" *ngFor="let allergen of allergens">
        <div class="item-name">{{ allergen.name }}</div>
        <div class="item-value">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d="M9.00089 18.25C8.90018 18.2466 8.80123 18.2227 8.71009 18.1797C8.61895 18.1367 8.53756 18.0756 8.47089 18L3.47089 13C3.37556 12.9382 3.29552 12.8555 3.23681 12.7583C3.17809 12.661 3.14225 12.5517 3.13198 12.4385C3.12171 12.3253 3.13728 12.2113 3.17751 12.1051C3.21775 11.9988 3.2816 11.9031 3.36424 11.8252C3.44688 11.7472 3.54616 11.689 3.65458 11.655C3.76299 11.621 3.87771 11.6121 3.99007 11.629C4.10243 11.6458 4.2095 11.688 4.30319 11.7522C4.39687 11.8165 4.47474 11.9012 4.53089 12L9.00089 16.44L19.4709 6C19.6118 5.90861 19.7794 5.86719 19.9467 5.88238C20.114 5.89756 20.2714 5.96848 20.3936 6.08376C20.5158 6.19904 20.5957 6.352 20.6206 6.51814C20.6455 6.68428 20.6139 6.85396 20.5309 7L9.53089 18C9.46422 18.0756 9.38283 18.1367 9.29169 18.1797C9.20056 18.2227 9.1016 18.2466 9.00089 18.25Z"
              fill="#007EC2"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-group-footer d-flex flex-column justify-content-end">
    <div class="col-12 d-flex justify-content-end flex-column position-relative border-top">
      <button (click)="close()" type="button" class="button-outline ui-clickable">
        {{ 'tapp-order.allergensAndNutritionalValues.close' | translate }}
      </button>
    </div>
  </div>
</div>
