import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UserRestService } from '@api/rest/user/user.rest.service';
import { first, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UserDataApiModel } from '@api/model/user/user-data.api.model';

@Component({
  selector: 'app-account-icon',
  templateUrl: './account-icon.component.html',
  styleUrls: ['./account-icon.component.scss'],
})
export class AccountIconComponent implements OnInit, OnDestroy {
  @Input() set showUser(value: boolean) {
    if (value === true) {
      this.initUserData();
    }

    this._showUser = value;
  }

  private destroyed$ = new Subject<void>();
  private _showUser: boolean = false;

  public userName: string = null;
  public userInitials: string = null;

  constructor(private userRestService: UserRestService) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  get showUser(): boolean {
    return this._showUser;
  }

  initUserData() {
    this.userRestService
      .getUser()
      .pipe(first(), takeUntil(this.destroyed$))
      .subscribe({
        next: (value) => {
          this.userName = value.name;
          this.userInitials = this.getInitials(value.name);
        },
      });
  }

  private getInitials(string) {
    var names = string.split(' '),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }
}
