import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonBackService } from '@core/services/button-back.service';

@Component({
  selector: 'app-button-back',
  templateUrl: './button-back.component.html',
  styleUrls: ['./button-back.component.scss'],
})
export class ButtonBackComponent {
  @Input() customLabel: string = null;

  constructor(private location: Location, private buttonBackService: ButtonBackService, private router: Router) {}

  public onClick(): void {
    if (this.buttonBackService.redirectUrl) {
      this.router.navigate(this.buttonBackService.redirectUrl);
      this.buttonBackService.redirectUrl = undefined;
    } else {
      this.location.back();
    }
  }
}
