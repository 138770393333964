import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TermsAndConditionsItemViewModel } from '@core/models/tapp-order/view-model/terms-and-conditions/terms-and-conditions-item.view.model';

@Component({
  selector: 'app-term-and-condition',
  templateUrl: './term-and-condition.component.html',
  styleUrls: ['./term-and-condition.component.scss'],
})
export class TermAndConditionComponent {
  @Input() termAndCondition: TermsAndConditionsItemViewModel;
  @Input() borderRed: boolean = false;
  @Output() public termAndConditionChangeEvent: EventEmitter<TermsAndConditionsItemViewModel> =
    new EventEmitter<TermsAndConditionsItemViewModel>();

  constructor() {}

  public onTermAndConditionChange(): void {
    this.termAndConditionChangeEvent.emit(this.termAndCondition);
  }
}
