<div class="row mt-3">
  <div class="col-12">
    <div class="invoice-details-wrapper">
      <div class="row">
        <div class="col company-name">
          <span class="name">{{
            orderService.getInvoiceCompanyName() != 'null' ? orderService.getInvoiceCompanyName() : ''
          }}</span>
          <span class="edit ui-clickable" (click)="openInvoiceFormEmitter.emit()">{{
            'tapp-order.payment.invoice.change' | translate
          }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <span>
            {{ orderService.getInvoiceCompanyStreet() }}
            {{ orderService.getInvoiceCompanyStreetNo() != 'null' ? orderService.getInvoiceCompanyStreetNo() : '' }}
            {{
              orderService.getInvoiceCompanyLocalNo()
                ? '/' + orderService.getInvoiceCompanyLocalNo() + ','
                : orderService.getInvoicePostalCode()
                ? ', '
                : ''
            }}
            {{ orderService.getInvoicePostalCode() != 'null' ? orderService.getInvoicePostalCode() : '' }}
            {{ orderService.getInvoiceCompanyCity() ? orderService.getInvoiceCompanyCity() + ',' : '' }}
            {{ orderService.getInvoiceCountry() != 'null' ? orderService.getInvoiceCountry() : '' }}</span
          >
        </div>
      </div>
      <div class="row">
        <div *ngIf="orderService.getInvoiceNip()" class="col">
          <span
            >{{ 'tapp-order.payment.invoice.form.nip.label' | translate }}
            {{ orderService.getInvoiceNip() != 'null' ? orderService.getInvoiceNip() : '' }}</span
          >
        </div>
      </div>
    </div>
  </div>
</div>
