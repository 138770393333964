<div class="app-register-page-container">
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <app-register-form></app-register-form>
      <span class="background-title">{{ 'tapp-order.pages.register.header' | translate }}</span>
    </div>
    <div class="col-lg-6 col-md-12">
      <div class="register-info-wrapper">
        <div class="row">
          <div class="col d-flex align-items-center title-sign">
            <span class="title-sign-inner"
              ><span class="primary-color">{{ 'tapp-order.pages.register.profit-title-1' | translate }}</span>
              {{ 'tapp-order.pages.register.profit-title-2' | translate }}</span
            >
            <app-logo></app-logo>
          </div>
        </div>
        <div class="row">
          <div class="col profit d-flex align-items-center">
            <div class="profit-img-container">
              <i class="ic ic-promo primary-color"></i>
            </div>
            <span class="profit-text">{{ 'tapp-order.pages.register.profits.profit1' | translate }}</span>
          </div>
        </div>

        <div class="row">
          <div class="col profit d-flex align-items-center">
            <div class="profit-img-container">
              <i class="ic ic-list primary-color"></i>
            </div>
            <span class="profit-text">{{ 'tapp-order.pages.register.profits.profit2' | translate }}</span>
          </div>
        </div>

        <div class="row">
          <div class="col profit d-flex align-items-center">
            <div class="profit-img-container">
              <i class="ic ic-address primary-color"></i>
            </div>
            <span class="profit-text">{{ 'tapp-order.pages.register.profits.profit3' | translate }}</span>
          </div>
        </div>

        <div class="row">
          <div class="col profit d-flex align-items-center">
            <div class="profit-img-container">
              <i class="ic ic-app primary-color"></i>
            </div>
            <span class="profit-text">{{ 'tapp-order.pages.register.profits.profit4' | translate }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
