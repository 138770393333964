<div class="item-container">
  <div class="item-container-top">
    <div class="item-top-inner-wrapper">
      <div class="item-created-at">
        <span class="mr-2">
          {{ order.createdAt | date: 'dd.MM yyyy':timezone }}
        </span>
        <span> g. {{ order.createdAt | date: 'HH:mm':timezone }} </span>
      </div>

      <div class="item-type">
        <ng-container [ngSwitch]="order.type">
          <div *ngSwitchCase="OrderTypeEnum.pick_up">
            <i class="ic ic-take-away"></i>
            <span class="name">{{ 'tapp-order.pages.logged-in-user.components.order-item.pick-up' | translate }}</span
            ><span *ngIf="order.pickupTime">: {{ order.pickupTime | date: 'HH:mm':timezone }}</span>
          </div>
          <div *ngSwitchCase="OrderTypeEnum.delivery">
            <i class="ic ic-delivery"></i>
            <span class="name">{{ 'tapp-order.pages.logged-in-user.components.order-item.delivery' | translate }}</span
            ><span *ngIf="order.deliveryTime">: {{ order.deliveryTime | date: 'HH:mm':timezone }}</span>
          </div>
          <div *ngSwitchDefault></div>
        </ng-container>
      </div>
    </div>

    <div class="item-top-inner-wrapper">
      <div class="item-status">
        <ng-container [ngSwitch]="order.status">
          <div *ngSwitchCase="OrderStatusEnum.finished">
            <span [class]="OrderStatusEnum.finished">{{
              'tapp-order.pages.logged-in-user.components.order-item.finished' | translate
            }}</span>
          </div>
          <div *ngSwitchCase="OrderStatusEnum.accepted">
            <span [class]="OrderStatusEnum.accepted">{{
              'tapp-order.pages.logged-in-user.components.order-item.accepted' | translate
            }}</span>
          </div>
          <div *ngSwitchCase="OrderStatusEnum.canceled">
            <span [class]="OrderStatusEnum.canceled">{{
              'tapp-order.pages.logged-in-user.components.order-item.canceled' | translate
            }}</span>
          </div>
          <div *ngSwitchCase="OrderStatusEnum.new">
            <span [class]="OrderStatusEnum.new">{{
              'tapp-order.pages.logged-in-user.components.order-item.new' | translate
            }}</span>
          </div>
          <div *ngSwitchCase="OrderStatusEnum.in_delivery">
            <span [class]="OrderStatusEnum.new">{{
              'tapp-order.pages.logged-in-user.components.order-item.delivery' | translate
            }}</span>
          </div>
        </ng-container>
      </div>

      <div class="item-address">
        <span class="place-address-prefix"
          >{{ 'tapp-order.pages.logged-in-user.components.order-item.local' | translate }}: </span
        ><span
          >{{ order.place.city + ' ' + order.place.street + ' ' + order.place.buildingNo
          }}{{ order.place.localNo ? '/' + order.place.localNo : '' }}</span
        >
      </div>
    </div>
  </div>

  <div class="item-container-bottom">
    <div class="item-summary">
      <div class="item-amount">
        <span
          >{{ order.orderItems.length }}
          {{ 'tapp-order.pages.logged-in-user.components.order-item.positions' | translate }}</span
        >
      </div>

      <div class="order-price"
        >{{ 'tapp-order.pages.logged-in-user.components.order-item.summary' | translate }}:
        <span class="price">{{ order?.orderPayment.totalPrice | currency: 'PLN':'zł':'1.2-2':'pl-PL' }}</span>
      </div>
    </div>

    <div class="item-buttons">
      <div
        *ngIf="order.status === OrderStatusEnum.finished && order.hasSurveyToComplete"
        class="ui-clickable btn-rate"
        (click)="openSurveyModal(order)"
        >{{ 'tapp-order.pages.logged-in-user.components.order-item.rate-order' | translate }}</div
      >

      <div (click)="openDialog()" class="show-order-btn ui-clickable">
        <span>{{ 'tapp-order.pages.logged-in-user.components.order-item.view-order' | translate }}</span>
      </div>

      <div (click)="repeatOrder()" class="repeat-order-btn ui-clickable">
        <span>{{ 'tapp-order.pages.logged-in-user.components.order-item.repeat' | translate }}</span>
      </div>
    </div>
  </div>
</div>
