import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { DeliveryZonesHttpService } from '@core/http/tapp-order/delivery-zones/delivery-zones.http.service';
import { DeliveryZoneApiModel } from '@core/models/tapp-order/api-model/delivery-zones/delivery-zone.api.model';
import { BasketItemViewModel } from '@core/models/tapp-order/view-model/basketItem/basket-item.view.model';
import { PlaceViewModel } from '@core/models/tapp-order/view-model/place/place.view.model';
import { OrderService } from '@core/services/order.service';
import { environment } from '@env/environment';
import { DiscountService } from '@core/pages/discounts/service/discount.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BasketService } from 'src/app/services/basket.service';
import { PlaceService } from 'src/app/services/place.service';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-order-page',
  templateUrl: './order-page.component.html',
  styleUrls: ['./order-page.component.scss'],
})
export class OrderPageComponent implements OnInit {
  public orderType: String;
  public promoCode: string = '';
  @Input() public basketItems: BasketItemViewModel[];
  public deliveryZone: DeliveryZoneApiModel;
  modalRef?: BsModalRef;
  public place: PlaceViewModel;
  public placeString: string;
  public displayDialog: boolean = false;

  constructor(
    public basketService: BasketService,
    public discountService: DiscountService,
    private placeService: PlaceService,
    private router: Router,
    private themeService: ThemeService,
    private deliveryZoneService: DeliveryZonesHttpService,
    public orderService: OrderService,
  ) {
    if (!this.tableId) {
      this.setDeliveryZone();
    }

    this.orderType = this.orderService.getOrderType();
    this.orderService.orderTypeSubject$.subscribe((res: string) => {
      if (res !== null) {
        this.orderType = res;
      }
    });
  }

  ngOnInit(): void {
    this.themeService.mobileNavbarShowBackButton = true;
    this.themeService.setbackArrowPath([sessionStorage.getItem('placeId')]);
    this.promoCode = this.discountService.promoCode;

    this.placeService.getPlace().subscribe((value) => {
      this.place = value;
      this.placeString = `${value.city}, ${value.street} ${value.buildingNo}`;
      value.localNo ? (this.placeString += `/${value.localNo}`) : null;

      if (value.isPreviewOnly) {
        this.router.navigate(['/']);
        return;
      }
      this.basketItems = this.basketService.basketItems;
      /* Obserwowanie zmian w koszyku wymagających odświeżenia */
      this.basketService.refreshBasketEvent.subscribe(() => {
        this.basketItems = this.basketService.basketItems;
      });
    });

    this.discountService.calculate();
  }

  public activateDiscountCode() {
    this.discountService.calculate(this.promoCode);
  }

  public hanldePromoCodeChange(promoCode: string): void {
    this.promoCode = promoCode;

    if (!promoCode && this.discountService.promoCode) {
      this.discountService.calculate('');
    }
  }

  public redirectToPaymentsPage() {
    this.router.navigate(['/payments']);
  }

  private setDeliveryZone(): void {
    if (this.orderService.getDeliveryZoneId()) {
      this.deliveryZoneService
        .getDeliveryZoneById(sessionStorage.getItem('placeId'), this.orderService.getDeliveryZoneId().toString())
        .subscribe((result) => {
          this.deliveryZone = result;
        });
    }
  }

  hideModal() {
    this.modalRef.hide();
    this.basketItems = this.basketService.basketItems;
  }

  public calculateMinOrderPrice() {
    if (!this.deliveryZone) {
      return 0;
    }

    return this.deliveryZone.minOrderPrice - this.basketService.getFoodTotalPrice();
  }

  get roomName() {
    return sessionStorage.getItem('roomName');
  }

  get tableName() {
    return sessionStorage.getItem('tableName');
  }

  get tableId() {
    return sessionStorage.getItem('tableId');
  }

  public changeLocal() {
    this.displayDialog = true;
  }

  public closeDialog() {
    this.displayDialog = false;
  }
}
