import { AbstractViewModel } from '../../../abstract.view.model';
import { ThemeSettingsApiModel } from '@core/models/tapp-order/api-model/place/theme-settings.api.model';

export class ThemeSettingsViewModel extends AbstractViewModel<ThemeSettingsApiModel> {
  public themeMode: string;
  public primaryBorderRadius: number;
  public primaryColor: string;

  constructor(protected apiModel: ThemeSettingsApiModel = new ThemeSettingsApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.themeMode = this.apiModel.themeMode;
    this.primaryBorderRadius = this.apiModel.primaryBorderRadius;
    this.primaryColor = this.apiModel.primaryColor;
  }

  toApiModel(): ThemeSettingsApiModel {
    return undefined;
  }
}
