<div class="row body" *ngIf="!isLoading">
  <div class="col">
    <div class="row">
      <div class="col order-history-title">
        {{ 'tapp-order.pages.logged-in-user.order-history.order-history' | translate }}
      </div>
    </div>
    <div class="row content">
      <div class="col">
        <app-order-history-list [orders]="orders" (scrollEvent)="onScrollEvent($event)"></app-order-history-list>
      </div>
    </div>
  </div>
</div>
