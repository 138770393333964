<div class="payment-component payment-approve-component">
  <div class="row mb-3" *ngIf="termsAndConditions.length > 0">
    <div class="col">
      <h2 class="header">
        {{ 'tapp-order.payment.approve.title' | translate }}
      </h2>
    </div>
  </div>
  <div class="row">
    <div *ngIf="termsAndConditions.length > 0" class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <ng-container *ngFor="let item of termsAndConditions">
        <div
          class="checkbox-wrapper"
          [ngClass]="item.required && paymentFormService.paymentApproveRejected ? 'border-red' : ''"
        >
          <p-checkbox
            [binary]="true"
            (onChange)="sendSelectApprove()"
            [(ngModel)]="item.selected"
            [ngClass]="'checkbox'"
            [inputId]="'checkbox' + item.id"
          ></p-checkbox>
          <label
            for="{{ 'checkbox' + item.id }}"
            [class.required]="item.required"
            class="p-checkbox-label"
            [innerHTML]="item.content"
          ></label>
        </div>
      </ng-container>
    </div>

    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="privacy-policy">
        {{ 'tapp-order.payment.approve.privacy-policy-1' | translate }}
        <span class="privacy-policy-link" (click)="showTermsAndCoditions()"
          >{{ 'tapp-order.payment.approve.privacy-policy-2' | translate }}
        </span>
      </div>
    </div>
  </div>
</div>

<app-dialog [(visible)]="termsVisible" [closable]="true" (onHide)="handleTermsHide()">
  <ng-template #header>
    <div class="dialog-terms__header">
      <span>{{ 'tapp-order.pages.register.form.privacy-policy' | translate }}</span>
    </div>
  </ng-template>

  <div class="dialog-terms__content">
    <div [innerHTML]="html"></div>
  </div>
</app-dialog>
