import { AbstractViewModel } from '../../../abstract.view.model';
import { CompanyApiModel } from '@core/models/tapp-order/api-model/place/company.api.model';

export class CompanyViewModel extends AbstractViewModel<CompanyApiModel> {
  public street: string;
  public buildingNo: string;
  public localNo: string;
  public zipCode: string;
  public city: string;
  public country: string;
  public name: string;
  public registrationNumber: string;
  public taxIdNumber: string;

  constructor(protected apiModel: CompanyApiModel = new CompanyApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.street = this.apiModel.street;
    this.buildingNo = this.apiModel.buildingNo;
    this.localNo = this.apiModel.localNo;
    this.zipCode = this.apiModel.zipCode;
    this.city = this.apiModel.city;
    this.country = this.apiModel.country;
    this.name = this.apiModel.name;
    this.registrationNumber = this.apiModel.registrationNumber;
    this.taxIdNumber = this.apiModel.taxIdNumber;
  }

  toApiModel(): CompanyApiModel {
    return undefined;
  }

  toString() {
    return this.name;
  }

  public getAddress() {
    return (
      this.city +
      ', ' +
      this.street +
      ' ' +
      this.buildingNo +
      (this.localNo ? '/' + this.localNo : '') +
      ', ' +
      this.zipCode +
      ' ' +
      (this.country ? this.country : '')
    );
  }
}
