<div class="checkbox-wrapper" [ngClass]="borderRed ? 'border-red' : ''">
  <p-checkbox
    ngDefaultControl
    [binary]="true"
    (onChange)="onTermAndConditionChange()"
    [(ngModel)]="termAndCondition.selected"
    [ngClass]="'checkbox'"
    [inputId]="'checkbox' + termAndCondition.id"
    [disabled]="termAndCondition.required && termAndCondition.selected"
  ></p-checkbox>
  <label
    for="{{ 'checkbox' + termAndCondition.id }}"
    [class.required]="termAndCondition.required"
    class="p-checkbox-label"
    [innerHTML]="termAndCondition.content"
  ></label>
</div>
