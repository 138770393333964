<div class="select-adress-background-image" *ngIf="isLoaded">
  <div class="select-adress-container h-100">
    <div class="row h-100 d-flex align-items-center">
      <div class="col-12 d-flex justify-content-center">
        <div class="col-12 p-0 d-flex align-items-center justify-content-center">
          <div class="address-finder-container">
            <div class="select-adress-header py-4">
              {{ 'tapp-order.select-adress-component.header-text' | translate }}
            </div>

            <div class="row m-0 d-flex align-items-end select-pickup-method-container">
              <div
                (click)="selectPickupOption(pickupOption)"
                *ngFor="let pickupOption of pickupOptions; index as i"
                [ngClass]="{
                  'pickup-method-selector-selected': pickupOption.type == selectedOrderType
                }"
                class="ui-clickable col d-flex justify-content-center align-items-center pickup-method-selector"
              >
                <div class="row d-flex align-items-center">
                  <div class="col-12">{{ pickupOption.i18key | translate }}</div>
                  <div class="col-12 pickup-option-time" *ngIf="pickupOption.time">
                    {{ pickupOption.time }} <small>min</small>
                    <img src="../../../../assets/icons/stoper.svg" />
                  </div>
                </div>
              </div>
            </div>

            <app-address-finder [redirectToPlace]="true" [withLabel]="true"></app-address-finder>

            <div class="button-container">
              <h3>{{ 'tapp-order.select-adress-component.check-offer-title' | translate }}</h3>

              <div
                [routerLink]="'/places'"
                class="col-lg-6 col-12 d-flex align-items-center justify-content-center select-adress-bottom-button ui-clickable"
              >
                {{ 'tapp-order.select-adress-component.card-to-menu-button' | translate }}

                <i class="ic ic-arrow-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
