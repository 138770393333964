import { Exclude, Expose, Transform, Type } from 'class-transformer';
import { RoomApiModel } from './room.api.model';
import { MenuApiModel } from '../menu/menu.api.model';
import { ImageApiModel } from '../product/image.api.model';
import { WorkingHoursApiModel } from '@core/models/tapp-order/api-model/working-hours/working-hours.api.model';
import { PaymentProviderApiModel } from '@core/models/tapp-order/api-model/place/payment-provider.api.model';
import { CompanyApiModel } from '@core/models/tapp-order/api-model/place/company.api.model';
import { LinkApiModel } from '@core/models/tapp-order/api-model/place/link.api.model';
import { ThemeSettingsApiModel } from '@core/models/tapp-order/api-model/place/theme-settings.api.model';

@Exclude()
export class PlaceApiModel {
  @Expose()
  public street: string;

  @Expose()
  public buildingNo: string;

  @Expose()
  public localNo: string;

  @Expose()
  public zipCode: string;

  @Expose()
  public city: string;

  @Expose()
  public country: string;

  @Expose()
  public image: ImageApiModel;

  @Expose()
  public isPreviewOnly: boolean;

  @Expose()
  public publicId: string;

  @Expose()
  public closedUntil: string;

  @Expose()
  public name: string;

  @Expose()
  public status: string;

  @Expose()
  public email: string;

  @Expose()
  public phone: string;

  @Expose()
  public active: string;

  @Expose()
  public type: string;

  @Expose()
  public posStatus: string;

  @Expose()
  public posStatusCheck: boolean;

  @Expose()
  public tableOrderActive: boolean;

  @Expose()
  public onlineOrderActive: boolean;

  @Expose()
  @Type(() => RoomApiModel)
  public rooms: RoomApiModel[];

  @Expose()
  @Type(() => MenuApiModel)
  public menus: MenuApiModel[];

  @Expose()
  @Type(() => WorkingHoursApiModel)
  public workingHours: WorkingHoursApiModel[];

  @Expose()
  @Type(() => WorkingHoursApiModel)
  public deliveryWorkingHours: WorkingHoursApiModel[];

  @Expose()
  @Type(() => WorkingHoursApiModel)
  public pickupWorkingHours: WorkingHoursApiModel[];

  @Expose()
  @Type(() => PaymentProviderApiModel)
  public paymentProviders: PaymentProviderApiModel[];

  @Expose()
  public company: CompanyApiModel;

  @Expose()
  public maxOrderPrice: number;

  @Expose()
  public paymentByCardActive: boolean;

  @Expose()
  public paymentByCashActive: boolean;

  @Expose()
  @Type(() => LinkApiModel)
  public links: LinkApiModel[];

  @Expose()
  @Type(() => Date)
  public qrMenuExpiredAt: Date;

  @Expose()
  @Type(() => Date)
  public tableOrdersExpiredAt: Date;

  @Expose()
  @Type(() => Date)
  public onlineOrdersExpiredAt: Date;

  @Expose()
  public autohidePizzaOptions: boolean;

  @Expose()
  @Type(() => ThemeSettingsApiModel)
  public themeSettings: ThemeSettingsApiModel;
}
