import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { PlaceViewModel } from '@core/models/tapp-order/view-model/place/place.view.model';
import { PlaceService } from './place.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  public themes = ['light-theme', 'dark-theme'];
  public backArrowPath: string[] = null;
  public mobileNavbarShowBackButton: boolean = false;
  public desktopHeaderAdditionalClass: string = null;

  constructor(@Inject(DOCUMENT) private document: Document, private placeService: PlaceService) {
    this.placeService.updatePlaceSubject$.subscribe(() => {
      this.placeService.getPlace().subscribe((place: PlaceViewModel) => {
        this.initTheme(place);
      });
    });

    if (!this.placeService.getPlaceId()) {
      this.placeService.getPlaces().subscribe((places: PlaceViewModel[]) => {
        this.initTheme(places[0]);
      });
    }
  }

  initTheme(place: PlaceViewModel): void {
    if (place.themeSettings) {
      if (place.themeSettings.themeMode) {
        this.switchTheme(place.themeSettings.themeMode);
      } else {
        this.switchTheme('light-theme');
      }

      if (place.themeSettings.primaryBorderRadius) {
        this.document.body.style.setProperty('--border-radius-primary', place.themeSettings.primaryBorderRadius + 'px');
      } else {
        this.document.body.style.removeProperty('--border-radius-primary');
      }

      if (place.themeSettings.primaryColor) {
        this.document.body.style.setProperty('--primary', place.themeSettings.primaryColor);
      } else {
        this.document.body.style.removeProperty('--primary');
      }
    } else {
      this.switchTheme('light-theme');
      this.document.body.style.removeProperty('--border-radius-primary');
      this.document.body.style.removeProperty('--primary');
    }
  }

  switchTheme(theme: string) {
    let themeLink = this.document.getElementById('app-theme') as HTMLLinkElement;

    if (themeLink) {
      themeLink.href = theme + '.css?v=' + environment.appVersion;
    }
  }

  public getBackArrowPath(): string[] {
    return this.backArrowPath;
  }

  public setbackArrowPath(value: string[]): void {
    this.backArrowPath = value;
  }
}
