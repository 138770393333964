<p-confirmDialog #cd [key]="'confirm-dialog-live-order'" [styleClass]="'confirm-dialog-live-order'" [closable]="false">
  <ng-template pTemplate="footer">
    <button
      pButton
      class="p-button p-button-danger"
      type="button"
      [label]="cd.confirmation.acceptLabel"
      (click)="cd.accept()"
    ></button>
    <div pRipple class="btn-cancel btn-cancel--short" (click)="cd.reject()" *ngIf="cd.confirmation.rejectLabel">
      {{ cd.confirmation.rejectLabel }}
    </div>
  </ng-template>
</p-confirmDialog>
