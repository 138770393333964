<p-dropdown
  [options]="locales"
  [(ngModel)]="currentLocale"
  optionLabel="name"
  [showClear]="false"
  class="locale-switcher"
  (onChange)="onLocaleChange()"
>
  <ng-template pTemplate="selectedItem">
    <div class="country-item country-item-value" *ngIf="currentLocale">
      <img src="{{ '/assets/icons/flags/' + currentLocale.code.toLowerCase() + '.png' }}" class="flag-icon" />
      <div>{{ currentLocale.name }}</div>
    </div>
  </ng-template>
  <ng-template let-country pTemplate="item">
    <div class="country-item">
      <img src="{{ '/assets/icons/flags/' + country.code.toLowerCase() + '.png' }}" class="flag-icon" />
      <div>{{ country.name }}</div>
    </div>
  </ng-template>
</p-dropdown>
