<div *ngIf="order" class="mb-0 mb-md-3">
  <div class="col-12">
    <header class="title-container d-flex text-center flex-wrap">
      <h1 class="title w-100">
        {{ 'tapp-order.pages.order.order-processing.order-in-delivery.title-container.title' | translate }}
      </h1>
      <h2 class="subtitle w-100">
        {{ 'tapp-order.pages.order.order-processing.order-in-delivery.title-container.subtitle.pre' | translate }}
        {{ localOrderIdentifier ? 'ID: ' + localOrderIdentifier : '' }}
        {{ 'tapp-order.pages.order.order-processing.order-in-delivery.title-container.subtitle.suf' | translate }}
      </h2>
    </header>
  </div>
  <div class="col-12">
    <div class="info-container">
      <div class="box align-self-stretch" *ngIf="order.type != orderTypeEnum.at_location">
        <i class="ic ic-pizzeria"></i>

        <div class="title">
          {{ 'tapp-order.pages.order.order-processing.order-in-delivery.info-container.title' | translate }}:
        </div>
        <div *ngIf="restaurant" class="place">
          <p class="city">{{ restaurant.city }}</p>
          <p class="street">
            ul. {{ restaurant.street }}
            {{ restaurant.buildingNo }}
          </p>
        </div>
      </div>
      <div class="box align-self-stretch" *ngIf="order.type == orderTypeEnum.at_location && orderService.tableName">
        <div class="place">
          <p class="city">
            {{ 'tapp-order.order-summary-room' | translate }}:
            {{ orderService.roomName }}
          </p>
          <p class="street">
            {{ 'tapp-order.order-summary-table' | translate }}:
            {{ orderService.tableName }}
          </p>
        </div>
      </div>

      <div
        class="box align-self-stretch"
        *ngIf="(pickupTime || deliveryTime) && order.type != orderTypeEnum.at_location"
      >
        <i class="ic ic-pizza-time"></i>

        <ng-container *ngIf="orderType === orderTypeEnum.delivery; else pickup">
          <div class="title">
            {{
              'tapp-order.pages.order.order-processing.order-in-delivery.info-container.estimated-delivery-time'
                | translate
            }}:
          </div>
          <div class="delivery-time" *ngIf="pickupTime; else deliveryTimeDiv">
            {{ pickupTime | date: 'HH:mm' }}
          </div>
          <ng-template #deliveryTimeDiv>
            <div class="delivery-time" #deliveryTimeDiv>
              {{ deliveryTime | date: 'HH:mm' }}
            </div>
          </ng-template>
        </ng-container>
        <ng-template #pickup>
          <div class="title">
            {{
              'tapp-order.pages.order.order-processing.order-in-delivery.info-container.estimated-realization-time'
                | translate
            }}:
          </div>
          <div class="delivery-time" *ngIf="pickupTime; else deliveryTimeDiv2">
            {{ pickupTime | date: 'HH:mm' }}
          </div>
          <ng-template #deliveryTimeDiv2>
            <div class="delivery-time">
              {{ deliveryTime | date: 'HH:mm' }}
            </div>
          </ng-template>
        </ng-template>
      </div>
    </div>
  </div>
</div>
