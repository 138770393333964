<div class="address-autocomplete">
  <label class="form-label" *ngIf="isMobile && withLabel">{{
    'tapp-order.select-adress-component.address-input-label' | translate
  }}</label>
  <div class="form-group my-1">
    <p-autoComplete
      [(ngModel)]="addressAutocompleteService.addressInput"
      [suggestions]="addressAutocompleteService.searchResults"
      [minLength]="1"
      [showEmptyMessage]="true"
      [emptyMessage]="'tapp-order.select-adress-component.error.address-not-found-try-again' | translate"
      [placeholder]="'tapp-order.select-adress-component.adress-input-placeholder' | translate"
      [ngClass]="{
        'invalid-input': hasError
      }"
      [field]="'fullAddress'"
      (completeMethod)="search($event)"
      (onClear)="clear()"
      (onSelect)="selectLocation($event)"
    >
    </p-autoComplete>

    <div class="select-adress-find-me-button ui-clickable" (click)="getUserLocation()">
      <ng-container *ngIf="!addressAutocompleteService.addressInput">{{
        'tapp-order.select-adress-component.card-localize-me-button' | translate
      }}</ng-container>

      <i class="ic ic-location primary-color"></i>
    </div>
  </div>

  <label class="form-label mt-3" *ngIf="isMobile && withLabel">{{
    'tapp-order.select-adress-component.local-no-input-placeholder' | translate
  }}</label>
  <div class="form-group my-1">
    <input
      pInputText
      class="col-12 address-input"
      placeholder="{{
        'tapp-order.select-adress-component.' +
          (isMobile && withLabel ? 'local-no-input-placeholder-1' : 'local-no-input-placeholder') | translate
      }}"
      [(ngModel)]="localNo"
      (change)="onLocalNoChange()"
      type="text"
      maxlength="10"
    />
  </div>

  <div class="place-input-error-container mt-1 mb-2">
    <ng-template [ngIf]="errorMessage">
      <div class="col-12 d-flex align-items-center justify-content-center place-input-error">
        {{ errorMessage }}
      </div>
    </ng-template>
  </div>
</div>
