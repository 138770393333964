import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GeoapiApiModel } from '@core/models/tapp-order/api-model/geoapi/geoapi.api.model';
import { PlaceHttpService } from '@core/http/tapp-order/place/place.http.service';
import { PlaceViewModel } from '@core/models/tapp-order/view-model/place/place.view.model';
import { QueryParamsApiModel } from '@core/models/query-params-api.model';
import { forkJoin, Subject } from 'rxjs';
import { DeviceDetectorService } from '../../../services/device-detector.service';
import { PlaceService } from '../../../services/place.service';
import { DeliveryZonesHttpService } from '@core/http/tapp-order/delivery-zones/delivery-zones.http.service';
import { DeliveryZoneViewModel } from '@core/models/tapp-order/view-model/delivery-zones/delivery-zone.view.model';
import { BaseDataProvider } from '@core/data-providers/base.data-provider';
import { ThemeService } from 'src/app/services/theme.service';
import { HolidayViewModel } from '@core/models/tapp-order/view-model/holiday/holiday.view.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-places',
  templateUrl: './places.component.html',
  styleUrls: ['./places.component.scss'],
})
export class PlacesComponent implements OnInit, OnDestroy {
  @ViewChild('productListDesktop')
  private productListDesktopHTMLElement: ElementRef<HTMLElement>;
  @ViewChild('placesWrapper') private placesWrapper: ElementRef<HTMLElement>;

  isLoaded: boolean = false;
  isDesktop: boolean = false;
  _placesList: PlaceViewModel[] = [];
  _places: PlaceViewModel[] = [];
  _cities: string[] = [];
  _city: string = null;
  listSubject$: Subject<QueryParamsApiModel> = new Subject();
  _deliveryZoneList: DeliveryZoneViewModel[] = [];
  _holidays: HolidayViewModel[] = [];

  /*szukajka*/
  public placeInputValue = '';
  public results: GeoapiApiModel[] = [];
  public placeInputError = '';

  constructor(
    private placeHttpService: PlaceHttpService,
    private deviceService: DeviceDetectorService,
    private placeService: PlaceService,
    private deliveryZonesHttpService: DeliveryZonesHttpService,
    private themeService: ThemeService,
    private baseDataProvider: BaseDataProvider,
    private router: Router,
  ) {
    this.deviceService.isDesktop.subscribe((isDesktop) => {
      this.isDesktop = isDesktop;
    });

    if (sessionStorage.getItem('tableId') && sessionStorage.getItem('placeId')) {
      this.router.navigate(['/', sessionStorage.getItem('placeId')]);
    }

    this.placeService.getPlaces().subscribe((places: PlaceViewModel[]) => {
      let placesWithOnlineOrder = places.filter((place: PlaceViewModel) => place.apiModel.onlineOrderActive);

      if (placesWithOnlineOrder.length == 0) {
        this.router.navigate(['/', 'start']);
      } else if (placesWithOnlineOrder.length == 1) {
        this.router.navigate(['/', placesWithOnlineOrder[0].publicId]);
      }

      this.isLoaded = true;
    });
  }

  ngOnInit(): void {
    this.themeService.mobileNavbarShowBackButton = true;
    this.getPlacesList();
  }

  ngOnDestroy(): void {
    this.themeService.mobileNavbarShowBackButton = false;
    this.listSubject$.unsubscribe();
  }

  setCity(city: string): void {
    this.scrollListToTop();
    this._city = city;
    this.filterPlaces(city);
    this.scrollToPlacesListOnMobile();
  }

  private scrollToPlacesListOnMobile(): void {
    setTimeout(() => {
      if (window.innerWidth <= 767) {
        this.placesWrapper?.nativeElement.scrollIntoView();
      }
    }, 0);
  }

  private scrollListToTop(): void {
    this.productListDesktopHTMLElement?.nativeElement?.scrollTo(0, 0);
  }

  private filterPlaces(city: string): void {
    this._places = this._placesList.filter((place) => place.city.toUpperCase() == city.toUpperCase());
  }

  get places(): PlaceViewModel[] {
    return this._places;
  }

  get deliveryZoneList(): DeliveryZoneViewModel[] {
    return this._deliveryZoneList;
  }

  get holidays(): HolidayViewModel[] {
    return this._holidays;
  }

  get cities(): string[] {
    return this._cities;
  }

  get city(): string {
    return this._city;
  }

  getPlacesList(): void {
    forkJoin([
      this.baseDataProvider.getHolidaysList(),
      this.deliveryZonesHttpService.getDeliveryZoneList(),
      this.placeService.getPlaces(),
    ]).subscribe(
      ([holidays, deliveryZoneList, places]: [HolidayViewModel[], DeliveryZoneViewModel[], PlaceViewModel[]]) => {
        this._placesList = places.filter((place) => place.menus.length > 0 && place.onlineOrderActive);
        this._deliveryZoneList = deliveryZoneList;
        this._holidays = holidays;

        this._cities = this._placesList.map((place) => place.city.toUpperCase());
        this._cities = [...new Set(this._cities)].sort((a, b) => a.localeCompare(b));
        this.setCity(this._cities[0]);

        this.getUserLocation();
      },
    );
  }

  public getUserLocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const nearestDeliveryZone = this.findNearestZone(position.coords, this._deliveryZoneList);
          if (nearestDeliveryZone) {
            const nearestCity = this._placesList.find(
              (place) => place.publicId == nearestDeliveryZone.placePublicId,
            )?.city;
            if (nearestCity) {
              this.setCity(nearestCity.toUpperCase());
            }
          }
        },
        () => {},
        { enableHighAccuracy: true },
      );
    }
  }

  haversineDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
    const R = 6371; // Radius of the Earth in km
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c; // Distance in km
  }

  findNearestZone(currentLocation: { latitude: number; longitude: number }, zones: any[]): any {
    let nearestZone = null;
    let shortestDistance = Infinity;

    zones.forEach((zone) => {
      // Calculate centroid
      const coordinates = zone.coordinates;
      const latSum = coordinates.reduce((sum, coord) => sum + coord[1], 0);
      const lonSum = coordinates.reduce((sum, coord) => sum + coord[0], 0);
      const centroid = {
        latitude: latSum / coordinates.length,
        longitude: lonSum / coordinates.length,
      };

      // Calculate distance to centroid
      const distance = this.haversineDistance(
        currentLocation.latitude,
        currentLocation.longitude,
        centroid.latitude,
        centroid.longitude,
      );

      // Check if this is the nearest zone
      if (distance < shortestDistance) {
        shortestDistance = distance;
        nearestZone = zone;
      }
    });

    return nearestZone;
  }
}
